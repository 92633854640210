// @ts-nocheck
import { Button, Card, Form, message, Select } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { SessionContext } from "../../App";
import { HQ_BRANCH } from "../../constants";
import {
	getCompanyAndBranchList,
	logout,
	getRolesByUsername
} from "../../services/api";
import { setBranchId, getUnverifiedAccount, removeUserRole, setCompanyId, setLoggedInUsernaame } from "../../services/local";
import { useForm } from 'antd/lib/form/Form'

export default function SelectBranchPage() {
	const { user, company } = useContext(SessionContext);
	const [isLoading, setIsLoading] = useState(false);
	const [branches, setBranches] = useState<
		{
			branchName: string;
			id: number;
			is_active?: boolean;
		}[]
	>([]);
	const [companies, setCompanies] = useState();
	const [coId, setCoId] = useState();
	const [selectBranchForm] = useForm()

	/**
	 * RULE:
	 * 1. Only get the branches which current logged-in user is assigned to.
	 * 2. IF returned list of branches is empty, implies user belongs to HQ branch
	 * 3. Otherwise, display all user assigned branches.
	 */
	// useEffect(() => {
	// 	if (Object.keys(company).length > 0) {
	// 		getBranchListByCoRegNo(company.coRegNo)
	// 			.then((res) => {

	// 				if (Array.isArray(res.branchDetails)) {

	// 					if (res.branchDetails.length < 1) logoutUser()
	// 					else setBranches(res.branchDetails)

	// 				} else logoutUser()

	// 			})
	// 			.catch((err) => {
	// 				logoutUser()
	// 				message.error(err)
	// 			});
	// 	}
	// }, [company]);
	useEffect(() => {
		getCompanyAndBranchList()
			.then(res => {
				if (res.status != '00') throw res.message
				setCompanies(res.shortCompanyList)
			}).catch(err => {
				err && message.error(err?.toString())
			})
	}, []);

	useEffect(() => {
		selectBranchForm.resetFields(['branchId'])
		companies?.filter(company => company.id == coId).map(company => {
			setBranches(company.shortBranchList)
		})
	}, [coId]);

	const logoutUser = () => {
		message.info('No active branch or HQ branch available for this user.')
		setTimeout(() => {
			logout()
				.then(() => {
					message.loading(`Redirect to Login...`)
					removeUserRole()
					window.location.pathname = '/login'
				})
		}, 5 * 1000)
	}

	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexDirection: "column",
			}}
		>
			<Card
				style={{
					width: 400,
					textAlign: "left",
					boxShadow: "0px 3px 6px #00000029",
					borderRadius: 8,
					marginTop: 72,
				}}
			>
				<p
					style={{
						font: "normal normal 600 32px Raleway",
						textAlign: "center",
					}}
				>
					Select a branch
				</p>
				<p style={{ font: "normal normal normal 15px Open Sans" }}>
					Welcome back,{" "}
					<b style={{ font: "normal normal 600 15px Open Sans" }}>
						{user.username}
					</b>
				</p>

				<Form
					form={selectBranchForm}
					layout={"vertical"}
					onFinish={(values) => {

						setIsLoading(true)

						/**
						 * 23/2/2022 daniel.kwok
						 * delay for dramatic effect
						 * 
						 * Store user selected branch in local storage
						 */
						setTimeout(async () => {

							try {

								let getRolesByUsernameRes = await getRolesByUsername(user.nric)

								if (!getRolesByUsernameRes.status) throw getRolesByUsernameRes.message

								setLoggedInUsernaame(user.username)
								setBranchId(values.branchId)
								setCompanyId(values.companyId)

								//check for remote user activation
								const unverifiedAccount = getUnverifiedAccount()

								message.loading(`Redirecting...`)
								
								if (unverifiedAccount === 'false') {
									window.location.pathname = '/'
								} else {
									window.location.pathname = '/remoteUserActivation'
								}
							} catch (error) {
								error && message.error(error)
							} finally{
								setIsLoading(false)
							}

						}, 1 * 1000)
					}}
				>
					<Form.Item
						className="label-italic"
						label="Company"
						name="companyId"
						rules={[
							{
								required: true,
								message: `Required`,
							},
						]}
					>
						<Select
							className="custom-select"
							optionFilterProp="children"
							filterOption={(input, option) =>
								option?.children
									.toLowerCase()
									.indexOf(input.toLowerCase()) >= 0
							}
							placeholder="Select a company"
							onChange={setCoId}
						>
							{companies?.map((company) => {
								return (
									<Select.Option
										key={company.id}
										value={company.id}
									>
										{company.coName}
									</Select.Option>
								);
							})}
						</Select>
					</Form.Item>

					{
						branches.length > 0 &&

						<Form.Item
							className="label-italic"
							label="Branch"
							name="branchId"
							rules={[
								{
									required: true,
									message: `Required`,
								},
							]}
						>
							<Select
								className="custom-select"
								optionFilterProp="children"
								filterOption={(input, option) =>
									option?.children
										.toLowerCase()
										.indexOf(input.toLowerCase()) >= 0
								}
								placeholder="Select a branch"
							>
								{
									branches.map((branch) => {
										return (
											<Select.Option
												key={branch.id}
												value={branch.id}
											>
												{branch.branchName}
											</Select.Option>
										);
									})
								}
							</Select>
						</Form.Item>
					}


					<Form.Item>
						<Button
							size="large"
							style={{
								width: "100%",
								background:
									" #2B61C4 0% 0% no-repeat padding-box",
								boxShadow: "0px 4px 0px #1E4489",
								borderRadius: "8px",
								borderColor: "transparent",
							}}
							loading={isLoading}
							htmlType="submit"
							type="primary"
						>
							Continue
						</Button>
					</Form.Item>
				</Form>
				<p style={{ font: "normal normal normal 15px Open Sans" }}>
					Please email <a><u>twocar@myeg.com.my</u></a> if you find any issues with your designated company
				</p>
			</Card>
		</div>
	);
}
