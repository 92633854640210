// @ts-nocheck
import { Button, Card, Col, Input, message, Row, Typography } from 'antd'
import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { PropsFromChildren } from '.'
import {
    getCompanyDetails,
    getDistrictList
} from '../../services/api'
import {
    parseAddress,
    getDistrictName
} from '../../util'
import { CompanyResponse } from './types'
import { getCompanyId } from '../../services/local'

type SelectCompanyProps = {
    next: (data?: PropsFromChildren) => void
}

export default function SelectCompany(props: SelectCompanyProps) {
    const { Text } = Typography
    const history = useHistory()
    const inputRef = useRef<any>(null)
    const [companyRegNum, setCompanyRegNum] = useState("")
    const [company, setCompany] = useState<CompanyResponse | null>(null)
    const [btnLoading, setBtnLoading] = useState(false)

    const searchCompany = async () => {

        setBtnLoading(true)

        if (!companyRegNum) {
            message.error("Please key in company registration number")
            inputRef.current!.focus()
            setBtnLoading(false)
            return
        }

        const companyRes = await getCompanyDetails(companyRegNum)
        if (companyRes.status !== '00') {
            message.error("Company not found")
            setCompany(null)
        } else {
            if (companyRes.companyDetails.id === getCompanyId()) {
                message.error(" Transfer not permitted within same company and branch.")
                setBtnLoading(false)
                setCompany(null)
                return
            }
            setCompany(companyRes)
           /*  getDistrictList(companyRes?.companyDetails?.companyAddress?.stateCode)
                .then((res) => {
                    res
                        .filter(district => district.districtName.toUpperCase() === (!companyRes?.companyDetails?.companyAddress?.district ? companyRes?.companyDetails?.companyAddress?.city.toUpperCase() : companyRes?.companyDetails?.companyAddress?.district.toUpperCase()))
                        .map(district => {

                            let districtCode = district.districtCode

                            let companyAddress = {
                                ...companyRes.companyDetails.companyAddress,
                                city: districtCode,
                                district: districtCode,
                            }

                            let companyDetails = {
                                ...companyRes.companyDetails,
                                companyAddress: companyAddress
                            }

                            let company = {
                                companyDetails: companyDetails
                            }

                            setCompany(company)
                        })
                })
                .catch(err => {
                    message.error(err)
                }) */
        }

        setBtnLoading(false)

    }

    const companyDetails = (company: CompanyResponse) => {
        const { companyDetails } = company
        const address = companyDetails.companyAddress
        const formattedAddress = parseAddress(address)

        return (
            <Card className='section-card section-card-left'>
                <h1 className='section-header'>Buyer's company details</h1>
                <Row>
                    <Col span={8}>
                        <Text className='detail-label'>Company Name</Text>
                        <div className='detail-value'>{companyDetails.coName}</div>
                    </Col>
                    <Col span={4}>
                        <Text className='detail-label'>Company ROC</Text>
                        <div className='detail-value'>{companyDetails.coRegNo}</div>
                    </Col>
                    <Col span={12}>
                        <Text className='detail-label'>Company Address</Text>
                        <div className='detail-value'>{formattedAddress}</div>
                    </Col>
                </Row>
            </Card>
        )
    }

    return (
        <>
            <Card className='section-card section-card-left'>
                <h1 className='section-header'>Select Buyer Company</h1>
                <Row>
                    <Col span={8}>
                        <Text className='detail-label'>Company registration number</Text>
                        <Input
                            className='input-field'
                            placeholder='Registration number'
                            value={companyRegNum}
                            onChange={(e) => setCompanyRegNum(e.target.value)}
                            ref={inputRef}
                        />
                        <Button
                            loading={btnLoading}
                            type='primary'
                            onClick={searchCompany} block>Search</Button>
                    </Col>
                </Row>
            </Card>

            {company
                ? companyDetails(company)
                : null
            }

            <div className='section-bottom-button'>
                <Button className='rounded-button-primary' onClick={history.goBack}>BACK</Button>
                {company && <Button type='primary' className='rectangle-button to-the-right' onClick={() => props.next({ companyProps: company })}>Continue</Button>}
            </div>
        </>
    )
}
