import React, { useContext, useEffect, useState } from 'react'
import {
    Form,
    Input,
    Button,
    PageHeader,
    Card,
    message,
    Select
} from 'antd'
import { useHistory } from 'react-router-dom'
import './index.css'
import { SessionContext } from '../../App'
import { STATES } from '../../constants'
import { updateCompany, getCompanyOfLoggedInUser } from '../../services/api'

export default function EditCompanyPage() {

    const { user, branch: _branch, company } = useContext(SessionContext)
    const [form] = Form.useForm()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {

        if (Object.keys(company).length > 0) {
            getCompanyOfLoggedInUser(company.id)
                .then(res => {
                    if (res.status == '00') {
                        form.setFieldsValue({
                            ...res?.companyDetails,
                            ...res?.companyDetails?.companyAddress,
                            state: Object.values(STATES).find(s => s.label === res.companyDetails?.companyAddress?.state)?.code
                        })
                    } else {
                        throw res
                    }
                })
                .catch(err => err && message.error(err.message))
        }

    }, [company])

    return (
        <div>
            <PageHeader
                className="site-page-header"
                title="Edit Company"
                onBack={() => history.goBack()}
            />
            <Card
                style={{ ...styles.card, textAlign: 'left' }}
            >

                <Form
                    form={form}
                    layout={'vertical'}
                    onFinish={values => {
                        setIsLoading(true)
                        updateCompany(
                            values.line1,
                            values.line2,
                            values.line3,
                            values.city,
                            company.id,
                            values.contactNo,
                            values.email,
                            values.faxNo,
                            values.postcode,
                            values.state,
                            user.username,
                        )
                            .then(res => {
                                if (res.status !== true) throw res.message
                                message.success(res.message)
                                history.goBack()
                            })
                            .catch(err => {
                                alert(err)
                            })
                            .finally(() => setIsLoading(false))


                    }}
                    onFinishFailed={values => {
                    }}
                >
                    <Form.Item
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        name={'coRegNo'}
                        label={<p style={styles.label}>Registration Number</p>}>
                        <Input
                            disabled={true}
                            style={{ maxWidth: 500, width: '40%' }}
                        />
                    </Form.Item>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        name={'coName'}
                        label={<p style={styles.label}>Name</p>}>
                        <Input
                            disabled={true}
                            style={{ maxWidth: 500, width: '40%' }}
                        />
                    </Form.Item>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        name={'line1'}
                        label={<p style={styles.label}>Address 1</p>}>
                        <Input.TextArea
                            style={{ maxWidth: 500, width: '40%' }}
                        />
                    </Form.Item>
                    <Form.Item
                        rules={[
                        ]}
                        name={'line2'}
                        label={<p style={styles.label}>Address 2</p>}>
                        <Input.TextArea
                            style={{ maxWidth: 500, width: '40%' }}
                        />
                    </Form.Item>

                    <Form.Item
                        rules={[
                        ]}
                        name={'line3'}
                        label={<p style={styles.label}>Address 3</p>}>
                        <Input.TextArea
                            style={{ maxWidth: 500, width: '40%' }}
                        />
                    </Form.Item>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        name={'state'}
                        label={<p style={styles.label}>State</p>}>

                        <Select
                            showSearch
                            style={{ maxWidth: 500, width: '40%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {
                                Object.values(STATES)
                                    .map(state => {
                                        return <Select.Option
                                            key={state.code}
                                            value={state.code}
                                        >
                                            {state.label}
                                        </Select.Option>
                                    })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        name={'city'}
                        label={<p style={styles.label}>City</p>}>
                        <Input.TextArea
                            style={{ maxWidth: 500, width: '40%' }}
                        />
                    </Form.Item>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        name={'postcode'}
                        label={<p style={styles.label}>Postcode</p>}>
                        <Input
                            style={{ maxWidth: 500, width: '40%' }}
                        />
                    </Form.Item>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        name={'contactNo'}
                        label={<p style={styles.label}>Contact Number</p>}>
                        <Input
                            style={{ maxWidth: 500, width: '40%' }}
                        />
                    </Form.Item>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        name={'email'}
                        label={<p style={styles.label}>Email</p>}>
                        <Input
                            style={{ maxWidth: 500, width: '40%' }}
                        />
                    </Form.Item>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        name={'faxNo'}
                        label={<p style={styles.label}>Fax Number</p>}>
                        <Input
                            style={{ maxWidth: 500, width: '40%' }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: 20,
                            }}
                        >
                            <Button
                                shape='round'
                                onClick={() => {
                                    history.goBack()
                                }}
                            >
                                Back
                            </Button>
                            <Button
                                loading={isLoading}
                                shape='round'
                                type='primary'
                                htmlType='submit'
                                style={{ margin: 5 }} >Save</Button>
                        </div>
                    </Form.Item>
                </Form>

            </Card>
        </div >
    )
}

const styles = {
    card: {
        marginBottom: 20
    },
    label: {
        color: 'grey', fontStyle: 'italic', marginBottom: 0
    },
}
