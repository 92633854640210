import React, { useContext, useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import {
    Select,
    Modal,
    Input,
    Button,
    Row,
    Col,
    message,
    Radio
} from 'antd'
import './index.less'
import propTypes from 'prop-types'
import {
    APP_CONST,
    OUT_TRANSACTION_TYPES_IDS
} from '../../constants'
import { SessionContext } from '../../App'
import {
    sendSmsOtp,
    getAdminUsersForCompanyForOut,
    getAccountDetails,
    getAdminUsersForCompany,
    sendNotification,
    checkTxnApprovalStatus
} from '../../services/api'
import { getAuthorizedNric, getUserRole } from '../../services/local'

function JPJSubmissionModal(props) {

    const { user, company } = useContext(SessionContext)

    const [authCode, setAuthCode] = useState('')
    const [approverIc, setApproverIc] = useState(null)
    const [userMobileNo, setUserMobileNo] = useState(null)
    const [role, setRole] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [adminUsers, setAdminUsers] = useState([])
    const [showApprovalSection, setShowApprovalSection] = useState(true)
    const [smsProvider, setSmsProvider] = useState(1)
    const [isDisabled, setIsDisabled] = useState(false)

    //start timer section
    const [minutes, setMinutes] = useState('00')
    const [seconds, setSeconds] = useState('00')
    const [otpTimeout, setOtpTimeout] = useState(0)

    const Ref = useRef(null);

    const params = useParams();
    const { transferId } = params;
    const [approverEmail, setApproverEmail] = useState('')
    const [showOtpSection, setShowOtpSection] = useState(true)
    const [otpSend, setOtpSend] = useState(false)
    const [pushNotificationSend, setPushNotificationSend] = useState(false)

    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        return {
            total, minutes, seconds
        };
    }

    const startTimer = (e) => {
        let { total, minutes, seconds } = getTimeRemaining(e);
        if (total >= 0) {
            setMinutes('' + (minutes > 9 ? minutes : '0' + minutes));
            setSeconds('' + (seconds > 9 ? seconds : '0' + seconds));
        } else {
            if (otpTimeout !== 0) {
                setOtpTimeout(0);
            }
            // Set OtpSend to false when countdown ends
            if (otpSend === true) {

                setOtpSend(false);
                setAuthCode('');
            }

            if (pushNotificationSend === true) {

                setPushNotificationSend(false)
                clearInterval(Ref.current)
            }
        }
    }

    const clearTimer = (e) => {

        setMinutes('0' + otpTimeout)
        setSeconds('00')

        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }

    const getDeadTime = () => {
        let deadline = new Date();
        // deadline.setSeconds(deadline.getSeconds() + otpTimeout)
        deadline.setMinutes(deadline.getMinutes() + otpTimeout)
        return deadline;
    }

    const onClickReset = () => {
        clearTimer(getDeadTime());
    }

    useEffect(() => {
        if (isDisabled && props.isIncorrectOtp) {
            setIsDisabled(false)
        }
    }, [props.isIncorrectOtp, props.isModalVisible])

    const sendOtp = () => {
        sendSmsOtp(approverIc, smsProvider).then(res => {
            if (!res.status) throw res.message
            // if (!res.status === true) throw res.message

            // setOtpTimeout(3)
            if (res.status === true) message.success(res.message)

        }).catch(err => err && message.error(err.toString()))
    }

    const resetOtpModal = () => {
        setOtpTimeout(0)
        setApproverIc(null)
        setUserMobileNo('')
        setAuthCode('')
        setApproverEmail('')
    }

    useEffect(() => {
        onClickReset()
    }, [otpTimeout])
    //end timer section

    function getAdminUsersFromApi() {

        setIsLoading(true)
        /**
         * 22/1/2022 daniel.kwok
         * Instructed by BE to get admin users where branchid = 0 (i.e. HQ)
         * at this moment. 
         */

        if (props?.transactionType == OUT_TRANSACTION_TYPES_IDS.D2I) {

            getAdminUsersForCompanyForOut(company?.coRegNo)
                .then(res => {

                    if (!res.status) throw res.message

                    let userList = res.users.sort((a, b) => a.name.localeCompare(b.name))
                    if (!showOtpSection) userList = userList.filter(item => item.isDeviceRegistered === 'Y');
                    userList = userList.filter(item => item.nric_no !== getAuthorizedNric());
                    setAdminUsers(userList)

                })
                .catch(err => err && message.error(err.toString()))
                .finally(() => setIsLoading(false))

        } else {

            getAdminUsersForCompany(company?.coRegNo)
                .then(res => {

                    if (!res.status) throw res.message

                    let userList = res.users.sort((a, b) => a.name.localeCompare(b.name))
                    if (!showOtpSection) userList = userList.filter(item => item.isDeviceRegistered === 'Y');
                    userList = userList.filter(item => item.nric_no !== getAuthorizedNric());
                    setAdminUsers(userList)

                })
                .catch(err => err && message.error(err.toString()))
                .finally(() => setIsLoading(false))
        }

    }

    useEffect(async () => {

        if (!props?.transactionType) return

        if (company.id) getAdminUsersFromApi()

        await getUserRole().then(async localRole => {

            setRole(localRole)

            // if (props?.transactionType == OUT_TRANSACTION_TYPES_IDS.D2I) {

            //     if (localRole == 'OWNER' || localRole == 'GENERAL_MANAGER') {
            //         setApproverIc(user.nric)
            //         setUserMobileNo(user?.mobileno)
            //     } else {
            //         setApproverIc(null)
            //         setUserMobileNo(null)
            //     }

            // } else {

            //     // if (localRole == 'VALUER' || localRole == 'SALES' || localRole == 'ADMIN') {
            //     //     setApproverIc(null)
            //     //     setUserMobileNo(null)
            //     // } else {
            //     //     setApproverIc(user.nric)
            //     //     setUserMobileNo(user?.mobileno)
            //     // }

            //     setApproverIc(null)
            //     setUserMobileNo(null)

            // }

        })

    }, [company.id, props.transactionType, showOtpSection])

    const setApproverInfo = (approverInfo) => {
        setApproverIc(approverInfo.approverIc)
        setApproverEmail(approverInfo.approverEmail)
        getAccountDetails(approverInfo.approverIc).then(res => {
            setUserMobileNo(res?.mobileno)
        }).catch(err => err && message.error(err))
    }

    // useEffect(() => {

    //     if (props?.transactionType && role) {

    //         if (props?.transactionType == OUT_TRANSACTION_TYPES_IDS.D2I) {

    //             if (role == 'OWNER' || role == 'GENERAL_MANAGER') setShowApprovalSection(false)
    //             else setShowApprovalSection(true)

    //         } else {

    //             // if (role == 'VALUER' || role == 'SALES' || role == 'ADMIN') setShowApprovalSection(true)
    //             // else setShowApprovalSection(false)

    //             setShowApprovalSection(true)

    //         }

    //     }

    // }, [props.transactionType, role])

    const handleRadioChange = (e) => {
        setShowOtpSection(e.target.value === 'sms'); // Set to true for SMS, false for app
        resetOtpModal()
    };

    const intervalId = useRef(null);

    useEffect(() => {
        if (pushNotificationSend) {
            // Call sendNotification here and handle the response
            sendNotification(approverEmail, 'I2I', transferId, [],null, false)
                .then(res => {
                    if (!res.status) {
                        message.warning(res.message)
                        setPushNotificationSend(false)
                    }
                    else {
                        setOtpTimeout(3);
                        message.success(res.message)
                        setPushNotificationSend(true)
                        intervalId.current = setInterval(() => {
                            // Call checkTxnApprovalStatus here and handle the response
                            checkTxnApprovalStatus(transferId, 'I2I')
                                .then(res => {
                                    if (res.status || res.isRejected) {
                                        res.isRejected ? message.error(res.message) : message.success(res.message)
                                        // onClickReset();
                                        // clearInterval(intervalId.current);
                                        setOtpTimeout(0);
                                        setPushNotificationSend(false);
                                        props.onOk(approverIc, '', true, res);
                                    }
                                    else if (res.errorMessage !== "") {
                                        message.error(res.errorMessage)
                                        // onClickReset();
                                        // clearInterval(intervalId.current);
                                        setOtpTimeout(0);
                                        setPushNotificationSend(false);
                                    }
                                });
                        }, 3000);
                    }
                });
        } else if (intervalId.current) {
            clearInterval(intervalId.current);
        }
        //prevent interval from continuing when user navigates away from the page
        return () => clearInterval(intervalId.current);
    }, [pushNotificationSend]);

    return (
        <>
            {APP_CONST.USE_OTP === true ?
                <>
                    <Modal
                        className='i2i-buyer-info-modal'
                        visible={props.isModalVisible}
                        okButtonProps={{
                            disabled: !authCode,
                        }}
                        onCancel={props.onCancel}
                        width={400}
                        footer={null}
                        afterClose={() => setSmsProvider(1)}
                        maskClosable={false}
                    >
                        <div style={{ marginTop: 24, width: '100%' }}>
                            <p style={{ textAlign: 'center', font: 'normal normal 600 24px Raleway' }}>Proceed Payment</p>

                            <div style={{ display: 'flex', flexDirection: 'column', gap: 20, marginBottom: 16 }}>
                                <div>
                                    <p style={{ fontStyle: 'italic' }}>Select Approval Method</p>
                                    <Radio.Group onChange={handleRadioChange} value={showOtpSection ? 'sms' : 'app'}>
                                        <Radio value='sms' disabled={pushNotificationSend}>
                                            SMS OTP
                                        </Radio>
                                        <Radio value='app' disabled={otpSend}>
                                            Mobile App
                                        </Radio>
                                    </Radio.Group>
                                </div>

                                <div>
                                    <p style={{ fontStyle: 'italic' }}>Approval Name</p>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                        <Select
                                            disabled={pushNotificationSend}
                                            className='textbox-form-style modal-dropdown'
                                            loading={isLoading}
                                            style={{ width: '100%' }}
                                            placeholder='Select Approval Name'
                                            value={approverIc}
                                            onChange={(approverIc) => {
                                                // Find the selected user
                                                const selectedUser = adminUsers.find(u => u.nric_no === approverIc);
                                                // Set the approverId and login_name
                                                if (selectedUser) {
                                                    setApproverInfo({
                                                        approverIc: selectedUser.nric_no,
                                                        approverEmail: selectedUser.login_name
                                                    });
                                                }
                                            }}
                                        >
                                            {adminUsers.map((u) => (
                                                <Select.Option value={u.nric_no} key={u.nric_no}>
                                                    {u.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </div>
                                </div>

                                {showOtpSection ? (
                                    <div>
                                        <p style={{ fontStyle: 'italic' }}>Verification Code</p>
                                        <p>
                                            Please enter the verification code sent to <br />
                                            <b>{userMobileNo}</b>
                                        </p>

                                        <Row gutter={[8, 8]}>
                                            <Col span={16}>
                                                <Input.Password
                                                    className='textbox-form-style'
                                                    disabled={!approverIc || !otpSend}
                                                    placeholder='e.g. 000000'
                                                    value={authCode}
                                                    onChange={(e) => {
                                                        setAuthCode(e.target.value);
                                                    }}
                                                    visibilityToggle
                                                />
                                            </Col>
                                            <Col span={8}>
                                                {minutes === '00' && seconds === '00' && (
                                                    <Button
                                                        disabled={!approverIc}
                                                        type='text'
                                                        style={{
                                                            width: '100%',
                                                            background: 'white',
                                                            border: 'none',
                                                            color: '#2B61C4',
                                                            textTransform: 'uppercase',
                                                        }}
                                                        onClick={() => {
                                                            setSmsProvider(1);
                                                            setOtpTimeout(3);
                                                            sendOtp();
                                                            setOtpSend(true);
                                                        }}
                                                    >
                                                        SEND CODE
                                                    </Button>
                                                )}
                                            </Col>
                                        </Row>
                                        {minutes === '00' && seconds === '00' ? (
                                            <p style={{ color: 'grey', fontStyle: 'italic', marginTop: '1em' }}></p>
                                        ) : (
                                            <p style={{ color: 'grey', fontStyle: 'italic', marginTop: '1em' }}>
                                                Code will expire in {minutes} min {seconds} sec
                                            </p>
                                        )}
                                    </div>
                                ) : (
                                    <div>
                                        {minutes === '00' && seconds === '00' ? (
                                            <p style={{ color: 'grey', fontStyle: 'italic', marginTop: '1em' }}></p>
                                        ) : (
                                            <p style={{ color: 'grey', fontStyle: 'italic', marginTop: '1em' }}>
                                                App approval will expire in {minutes} min {seconds} sec
                                            </p>
                                        )}
                                        <Button
                                            type='primary'
                                            shape={'round'}
                                            disabled={!approverEmail}
                                            onClick={() => {
                                                setPushNotificationSend(true)
                                            }}
                                            size='large'
                                            loading={pushNotificationSend} // This line makes the button show a loading indicator
                                            style={{
                                                width: '100%',
                                                background: '#2B61C4 0% 0% no-repeat padding-box',
                                                boxShadow: '0px 4px 0px #1E4489',
                                                borderRadius: '8px',
                                                borderColor: 'transparent',
                                                color: 'white',
                                            }}
                                        >
                                            {pushNotificationSend ? "Waiting for approval" : "Send Push Notification Approval"}
                                        </Button>
                                    </div>
                                )}

                                {showOtpSection && (
                                    <Button
                                        type='primary'
                                        shape={'round'}
                                        onClick={() => {
                                            setIsDisabled(true)
                                            props.onOk(approverIc, authCode, false);
                                        }}
                                        disabled={!authCode || !approverIc || isDisabled}
                                        size='large'
                                        loading={props.paymentLoading}
                                        style={{
                                            width: '100%',
                                            background: '#2B61C4 0% 0% no-repeat padding-box',
                                            boxShadow: '0px 4px 0px #1E4489',
                                            borderRadius: '8px',
                                            borderColor: 'transparent',
                                            color: 'white',
                                        }}
                                    >
                                        Submit
                                    </Button>
                                )}
                            </div>
                        </div>
                    </Modal>

                </>
                :
                <>
                    <Modal
                        visible={props.isModalVisible}
                        onCancel={props.onCancel}
                        width={330}
                        footer={null}
                        className='i2i-buyer-info-modal'


                    >
                        <div direction='vertical' style={{ marginTop: 24, width: '100%' }}>
                            <p style={{ textAlign: 'center', font: 'normal normal 600 24px Raleway' }}>Proceed Payment</p>
                            <p style={styles.label}>Authentication Code</p>
                            <Input
                                className='textbox-form-style'
                                disabled={!approverIc}
                                placeholder='e.g. 000000'
                                value={authCode}
                                onChange={e => {
                                    setAuthCode(e.target.value)
                                }}
                            />
                            <Button
                                type='primary'
                                shape={'round'}
                                onClick={() => {
                                    setIsDisabled(true)
                                    props.onOk(approverIc, authCode, false)
                                }}
                                disabled={!authCode || isDisabled}
                                size='large'
                                loading={props.paymentLoading}
                                style={{
                                    width: '100%',
                                    background: "#2B61C4 0% 0% no-repeat padding-box",
                                    boxShadow: "0px 4px 0px #1E4489",
                                    borderRadius: "8px",
                                    borderColor: 'transparent',
                                    color: 'white',
                                    marginTop: 24
                                }}
                            >
                                Submit
                            </Button>
                        </div>
                    </Modal >
                </>
            }
        </>

    )
}

JPJSubmissionModal.propTypes = {
    onCancel: propTypes.func,
    onOk: propTypes.func,
    isModalVisible: propTypes.bool,
    paymentLoading: propTypes.bool,
    transactionType: propTypes.string,
    isIncorrectOtp: propTypes.bool,
}

const styles = {
    label: {
        color: 'grey', font: 'italic normal normal 16px Open Sans', marginBottom: 8,
        textAlign: 'initial'
    },
    value: {
        font: 'normal normal 600 16px Open Sans'
    }
}

export default JPJSubmissionModal