import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Col, Form, Image, Input, message, PageHeader, Row, Select, Table, Tooltip } from "antd";
import { useForm } from "antd/lib/form/Form";
import arrow_back from "../../../assets/arrow_back.svg";
import { useHistory, useParams } from "react-router-dom";
import export_xls from "../../../assets/export_xls_filled.svg";
import moment from "moment";
import { I2I_TRANSACTION_TYPES, OUT_TRANSACTION_TYPES_IDS, SEARCH_QUERY_TOOLTIP } from "../../../constants";
import {
	getCountForFilterTransferId,
	getCountForFilterVehicleNumber,
	getListForFilterTransferId,
	getListForFilterVehicleNumber,
} from "../../../services/api";
import {
	getCompanyId,
} from "../../../services/local";

export default function AuditTrailLogPage() {
	// useParams is a hook that lets you extract the dynamic pieces of the URL.
	// coming from Transaction Report page
	let { serviceId: externalServiceId, transferId } = useParams();
	const TRANSACTION_TYPE_LABEL = {
		IN: "IN",
		OUT: "OUT",
		I2I: "I2I",
		M2M: "M2M",
	};

	const [auditForm] = useForm();
	const history = useHistory();
	const [dataSource, setDataSource] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [disableTxType, setDisableTxType] = useState(true);
	const [selectedType, setSelectedType] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [totalLogCount, setTotalLogCount] = useState(0);
	const [searchTerm, setSearchTerm] = useState("");

	const totalLogPerPage = 10;

	function validateAndTrimSearchQuery(searchTerm) {
		if (typeof searchTerm === 'undefined' || searchTerm === undefined) {
			searchTerm = ""; // Assign empty string if searchTerm is undefined
		}
        return searchTerm.replace(/\s/g, "");
	}

	const onGenerateAuditLog = () => {
		const { carRegistrationNo, module, transactionType } = auditForm.getFieldsValue();
		let trimmedCarRegistrationNo = validateAndTrimSearchQuery(carRegistrationNo);
		setSearchTerm(trimmedCarRegistrationNo);
		let serviceId;
		let coId = getCompanyId();

		if (!selectedType || selectedType === "") {
			serviceId = "ALL"
		}
		else {
			serviceId = selectedType
		}

		// this if statement is added to prevent calling api in useEffect during the initial mount
		// implements "proper" pagination, calling 10 logs per page
		if (trimmedCarRegistrationNo) {
			setIsLoading(true);
			Promise.all([
				getListForFilterVehicleNumber(totalLogPerPage, currentPage, serviceId, trimmedCarRegistrationNo, coId),
				getCountForFilterVehicleNumber(serviceId, trimmedCarRegistrationNo, coId),
			])
				.then((results) => {

					let [listOfLogs, countOfLogs] = results;
					if(listOfLogs.status !== "00") {
						setIsLoading(false);
						setDataSource(null)
						setTotalLogCount(0);
						return
					}

					message.info(`There is a total of ${countOfLogs.count} logs for this vehicle number`);

					// set unique key for each log
					listOfLogs = listOfLogs.auditTrailData.map((log, index) => {
						log.key = index + 1;
						return log;
					});

					setDataSource(listOfLogs);
					setTotalLogCount(countOfLogs.count);
					setIsLoading(false);
				})
				.catch((error) => {
					setIsLoading(false);
					message.error(error)
				});
		} else if (transferId && externalServiceId) {
			setIsLoading(true);
			Promise.all([
				getListForFilterTransferId(totalLogPerPage, currentPage, externalServiceId, transferId, coId),
				getCountForFilterTransferId(externalServiceId, transferId, coId),
			])
				.then((results) => {
					let [listOfLogs, countOfLogs] = results;
					if(listOfLogs.message) {
						setIsLoading(false);
					}

					listOfLogs = listOfLogs.map((log, index) => {
						log.key = index + 1;
						return log;
					});

					message.info(`There is a total of ${countOfLogs.count} logs for this vehicle number`);

					setDataSource(listOfLogs);
					setTotalLogCount(countOfLogs.count);
					setIsLoading(false);
				})
				.catch((error) => {
					setIsLoading(false);
					message.error(error)
				});
		}
	};

	const columns = [
		{
			title: "No",
			render: (value, item, index) => (currentPage - 1) * 10 + index + 1,
		},
		{
			title: "Date & Time",
			dataIndex: "activityTime",
			width: 150,
			render: (date) => {
				let formattedDate = new Date(date);
				return moment(formattedDate).format("DD-MMM-YYYY hh:mm:ss");
			},
			sorter: (a, b) => {
				let formattedA = moment(new Date(a.activityTime));
				let formattedB = moment(new Date(b.activityTime));

				return formattedA.diff(formattedB);
			},
			defaultSortOrder: "descend",
		},
		{
			title: "Branch Name",
			dataIndex: "branchName",
			render: (branchName) => branchName ?? "-",
		},
		{
			title: "User Name & MyKad Number",
			// dataIndex: ["loginName"], // Array for multiple data sources
			render: (result) => {
				return (
				<>
				  {result.name && result.name !== "" ? result.name : "-"} <br />
				  {result.identificationNo && result.identificationNo !== "" ? result.identificationNo : "-"}
				</>
				)
			},
		},
		{
			title: "Vehicle Number",
			dataIndex: "vehicleNo",
			render: (vehicleNo) => vehicleNo ?? "-",
		},
		{
			title: "Activities",
			dataIndex: "activityDesc",
		},
	];

	const exportXLSButton = () => {
		return (
			<Button shape="round" className="add-new-user-button export-report" type="primary" disabled>
				<Image src={export_xls} preview={false} width={35} height={35} style={{ paddingRight: 10 }} />
				Export report
			</Button>
		);
	};

	// get total 10 logs per page
	useEffect(() => {
		onGenerateAuditLog();
	}, [currentPage]);

	const handleOnChange = (value) => {
		setSelectedType(value);
	};

	return (
		<>
			<div className="page-content">
				<Breadcrumb separator=">" style={{ marginTop: "60px" }}>
					<Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
					<Breadcrumb.Item href="/reporting">Reporting</Breadcrumb.Item>
					<Breadcrumb.Item href="/reporting/audittraillog">APT</Breadcrumb.Item>
				</Breadcrumb>
				<PageHeader className="site-page-header" title={"APT"} />
				<Card style={{ borderRadius: 8 }}>
					<Form form={auditForm} requiredMark={false} layout="vertical" onFinish={onGenerateAuditLog}>
						<Row className="filter-form-row" gutter={[20, 0]}>
							<Col span={6}>
								<Form.Item
									label="Vehicle Number"
									name={"carRegistrationNo"}
									rules={[
										{
											required: true,
											message: "Please insert vehicle number",
										},
									]}
								>
									<Tooltip title={SEARCH_QUERY_TOOLTIP} placement="bottom">
										<Input
											className="form-input-field"
											placeholder="Vehicle Number"
											allowClear
											value={searchTerm}
											onChange={e => {
												setSearchTerm(e.target.value);
												auditForm.setFieldsValue({
													carRegistrationNo: e.target.value.toUpperCase()
												})
											}} />
									</Tooltip>
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item
									label="Transaction Type"
									name="transactionType"
									rules={[
										{
											required: !disableTxType,
											message: "Please pick a transaction type",
										},
									]}
								>
									<Select
										onChange={handleOnChange}
										placeholder="Transaction Type"
										allowClear
									>
										 {Object.keys(TRANSACTION_TYPE_LABEL).map((key) => (
											<option key={key} value={TRANSACTION_TYPE_LABEL[key]}>
												{TRANSACTION_TYPE_LABEL[key]}
											</option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item>
									<Button className="add-new-user-button" type="primary" htmlType="submit">
										Generate Now
									</Button>
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</Card>

				<Row style={{ display: "flex", justifyContent: "end" }}>{exportXLSButton()}</Row>

				<Table
					className="report-table"
					dataSource={dataSource}
					columns={columns}
					onChange={(pagination) => {
						setCurrentPage(pagination.current);
					}}
					pagination={{ position: ["topRight", "bottomRight"], total: totalLogCount, showSizeChanger: false }}
					loading={isLoading}
				/>

				<Row style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
					<Button
						className="back-button buttons"
						shape="round"
						onClick={() => {
							history.goBack();
						}}
					>
						<Image src={arrow_back} preview={false} />
						BACK
					</Button>
					{exportXLSButton()}
				</Row>
			</div>
			<style>
				{`
                    .site-page-header { 
                        padding-left: 0px;
                        padding-bottom: 15px;
                    }
                    .ant-select-arrow {
                        color: #2B61C4;
                    }
                    .back-button {
                        margin: 20px 0px;
                    }
					.export-report.add-new-user-button {
						display: flex;
						align-items: center;
						justify-content: center;
						border-radius: 127px !important;	
						padding: 0px 30px;
						box-shadow: none;
					}
					.ant-card { 
						margin-bottom: 25px;
					}
					.filter-form-row {
						display: flex; 
						align-items: flex-end;
					}
					.filter-form-row .ant-col {
						// display: flex; 
						// justify-content: left; 
						text-align: left;
					}
					.form-input-field {  
						height: 50px;
						padding: 0px
					}
					.form-input-field .ant-input { 
						height: 23px;
					}
					.filter-form-row label { 
						font: italic 16px / 22px "Open Sans";
					}
					.ant-select-selector { 
						border: 1px solid #2B61C4 !important;
						box-shadow: 0px 2px 4px #00000033 !important;
						background: #FFFFFF 0% 0% no-repeat padding-box !important;
						border-radius: 4px !important;
						height: 48px !important;
						display: flex;
						align-items: center;
					}
					.ant-select-arrow {
						color: #2B61C4;
					}
					.report-table { 
						margin-top: 20px;
					}
                `}
			</style>
		</>
	);
}
