import React, { useContext, useEffect, useState } from "react";
import {
	Breadcrumb,
	Button,
	Card,
	Col,
	Form,
	Image,
	Input,
	message,
	Modal,
	PageHeader,
	Row,
	Select,
	Table,
	Pagination,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import "./index.less";
import arrow_back from "../../../assets/arrow_back.svg";
import { useHistory, useParams } from "react-router-dom";
import export_xls from "../../../assets/export_xls_filled.svg";
import transfer from "../../../assets/transfer.svg";
import { SessionContext } from "../../../App";
import { getInventoryList, getInventoryListCount, transferVehicleInterBranch } from "../../../services/api";
import { HQ_BRANCH, VEHICLE_TYPES } from "../../../constants";
import { vehicleType } from "../../../components/VehicleInformation";

function BranchVehicles() {
	const { Search } = Input;

	const pageLimit = 10;

	let { branchId } = useParams();
	const { company, branchs } = useContext(SessionContext);
	const history = useHistory();

	const [branchFilterForm] = useForm();
	const [transferVehicleForm] = useForm();
	const [dataSource, setDataSource] = useState([]);
	const [initialDataSource, setInitialDataSource] = useState([]);
	const [totalInventoryCount, setTotalInventoryCount] = useState(0);
	const [successTransferCount, setSuccessTransferCount] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [loading, setLoading] = useState(false);
	const [selectedKeys, setSelectedKeys] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [branchList, setBranchList] = useState([])
	const [pageSize, setPageSize] = useState(10); // Default page size
	let allBranches = [...branchs, HQ_BRANCH];

	const columns = [
		{
			title: "No",
			dataIndex: "key",
			key: "key",
		},
		{
			title: "Vehicle Type",
			// dataIndex: "vehicleType",
			key: "vehicleType",
			// render: (vehicleType) => VEHICLE_TYPES[vehicleType].vehicleType,
			render: (rowData) => {
				let vehicleTypeValue;
				if (rowData.vehicleType !== null) {
					vehicleTypeValue = VEHICLE_TYPES[rowData.vehicleType].vehicleType
				}
				else if (rowData.vehicleTypeNew !== null) {
					switch (rowData.vehicleTypeNew) {
						case 1:
							vehicleTypeValue = "CAR"
							break;
						case 2:
							vehicleTypeValue = "MOTOR"
							break;
						case 5:
							vehicleTypeValue = "COMMERCIAL CAR"
							break;
						case 13:
							vehicleTypeValue = "COMMERCIAL MOTO"
							break;
						default: vehicleTypeValue = ""
							break;
					}
				}
				return vehicleTypeValue
			},
		},
		{
			title: "Vehicle Number",
			dataIndex: "vehicleNo",
			key: "vehicleNo",
			sorter: (a, b) => a.vehicleNo.localeCompare(b.vehicleNo),
		},
		{
			title: "Make",
			dataIndex: "make",
			key: "make",
		},
		{
			title: "Model",
			dataIndex: "model",
			key: "model",
		},
		{
			title: "Manufacture Year",
			dataIndex: "manufacturerYear",
			key: "manufacturerYear",
		},
		{
			title: "Chassis Number",
			dataIndex: "chassisno",
			key: "chassisno",
		},
		{
			title: "Engine Number",
			dataIndex: "engineno",
			key: "engineno",
		},
	];

	const handleTransferVehicle = () => {
		setLoading(true);
		const { newBranchId } = transferVehicleForm.getFieldsValue();
		// get the id of vehicle to be transferred using the row key
		const selectedVehicles = selectedKeys.map((key) => {
			return dataSource.find((vehicle) => vehicle.key == key).id;
		});

		// prepare to update state and re-render, by removing the selected vehicles from the dataSource
		const newDataSource = dataSource.filter((vehicle) => !selectedVehicles.includes(vehicle.id));

		if (newBranchId == branchId) {
			message.error("You cannot transfer vehicle to same branch");
			setSelectedKeys([]);
			setShowModal(false);
			setLoading(false);
			return;
		} else {
			// transfer vehicle, set new datasource and get the latest inventory count
			transferVehicleInterBranch(selectedVehicles, newBranchId)
				.then((res) => {
					setSelectedKeys([]);
					setDataSource(newDataSource);
					setInitialDataSource(newDataSource);
					setSuccessTransferCount(res.successTransferCount);
					message.success(`${res.statusMSG}`);
					getInventoryListCount(branchId, company.id)
						.then((res) => {
							setTotalInventoryCount(res.count);
							setLoading(false);
							setShowModal(false);
						})
						.catch((err) => err && message.error(err));
				})
				.catch((err) => err && message.error(err));
		}
	};

	const handleInventoryFilter = () => {
		// NOTE: month as of now does not have any filter. Backend does not have any return value in regard of "month"
		const { month, year, vehicleNumber } = branchFilterForm.getFieldsValue();
		// default case: display all inventory
		if (month == "all" && year == "all" && vehicleNumber == "") {
			setDataSource(initialDataSource);
		// when vehicle entered, but year and month is not changed
		} else if (month == "all" && year == "all" && vehicleNumber !== "") {
			setDataSource(
				initialDataSource.filter((vehicle) => {
					return vehicle.vehicleNo.toLowerCase().includes(vehicleNumber.toLowerCase());
				})
			);
		// when vehicle, and year entered
		} else if (year === "null") {
			setDataSource(
				initialDataSource.filter((vehicle) => {
					return (
						vehicle.vehicleNo.toLowerCase().includes(vehicleNumber.toLowerCase()) &&
						vehicle.manufacturerYear === null
					);
				})
			);
		// when vehicle, and year entered
		} 
		else {
			setDataSource(
				initialDataSource.filter((vehicle) => {
					return (
						vehicle.vehicleNo.toLowerCase().includes(vehicleNumber.toLowerCase()) &&
						vehicle.manufacturerYear == year
					);
				})
			);
		}
	};

	useEffect(() => {
		if (company.coRegNo) {
			setLoading(true);
			Promise.all([
				getInventoryListCount(branchId, company.id),
				// getInventoryList(branchId, company.coRegNo, pageLimit, currentPage),
			])
				.then(async (results) => {
					// let [inventoryCount, inventoryList] = results;
					let [inventoryCount] = results;

					/* inventoryList = inventoryList.map((inventory, index) => {
						return {
							key: index + 1,
							...inventory,
						};
					}); */

					let inventoryFullList = 
					await getInventoryList(branchId, company.coRegNo, inventoryCount.count, currentPage)
					inventoryFullList = inventoryFullList.map((inventory, index) => {
						return {
							key: index + 1,
							...inventory,
						};
					});

					message.info(`Total ${inventoryCount.count} inventories found`);
					setTotalInventoryCount(inventoryCount.count);
					setDataSource(inventoryFullList);
					setInitialDataSource(inventoryFullList);
					setLoading(false);
				})
				.catch((err) => {
					err && message.error(err.message);
				});
		}
	}, [ company, branchId]);
// }, [ currentPage, company, branchId]);

	useEffect(() => {
		if(branchs) {
			setBranchList(allBranches)
		} else {
			setBranchList([HQ_BRANCH])
		}
	}, [branchs])

	const handlePageSizeChange = (current, size) => {
		setPageSize(size);
		setCurrentPage(current);
	  };

	  // Calculate the data to display based on the current page and page size
  	const paginatedData = dataSource.slice((currentPage - 1) * pageSize, Math.min(currentPage * pageSize, dataSource.length));
	
	return (
		<div className="page-content">
			<Breadcrumb separator=">" style={{ marginTop: "60px" }}>
				<Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
				<Breadcrumb.Item href="/interbranchtransfer">Vehicle Inter-Branch Transfer</Breadcrumb.Item>
				<Breadcrumb.Item>Branch ID #{branchId}</Breadcrumb.Item>
			</Breadcrumb>
			<Row>
				<Col span={12}>
					<PageHeader
						style={{ paddingLeft: "0px" }}
						className="site-page-header"
						title={`Branch ID #${branchId}`}
					/>
				</Col>
				<Col span={12} className="export-btn-container">
					<Button
						type="primary"
						shape="round"
						size="large"
						className="add-new-user-button export-report"
						disabled
					>
						<Image src={export_xls} preview={false} width={25} height={25} />
						Export Report
					</Button>
				</Col>
			</Row>
			<Card className="section-card">
				<h2>Branch Details</h2>
				<Row>
					<Col span={6}>
						<p className="italic-label">Branch ID</p>
						<p className="bold-text">{branchId}</p>
					</Col>
					<Col span={6}>
						<p className="italic-label">Branch Name</p>
						<p className="bold-text">{branchList.length > 0 && branchList.find((branch) => branch.id == branchId).branchName}</p>
					</Col>
					<Col span={6}>
						<p className="italic-label">No. of Car(s) Available</p>
						<p className="bold-text">{totalInventoryCount}</p>
					</Col>
					<Col span={6}>
						<p className="italic-label">No. of Car(s) Transferred</p>
						<p className="bold-text">{successTransferCount}</p>
					</Col>
				</Row>
			</Card>
			<Card className="section-card">
				<h2>Filters</h2>
				<Form
					className="branch-filter-form"
					form={branchFilterForm}
					requiredMark={false}
					layout="vertical"
					initialValues={{
						month: "all",
						year: "all",
						vehicleNumber: "",
					}}
				>
					<Row className="filter-form-row" gutter={[20, 0]}>
						<Col span={6}>
							<Form.Item label="Month" name="month">
								<Select size="large">
									<Select.Option key={"all"} value="all">
										-- All --
									</Select.Option>
								</Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item label="Year" name="year">
								<Select size="large">
									<Select.Option key={"all"} value="all">
										-- All --
									</Select.Option>
									{/* receive entire datasource, and return unique manufacturer year, and turn them into selectable Options */}
									{[...new Set(initialDataSource.map((vehicle) => vehicle.manufacturerYear))]
										.map((manufacturerYear) => (
											<Select.Option key={manufacturerYear}>{manufacturerYear}</Select.Option>
										))
										.sort((a, b) => a.key - b.key)}
								</Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item label="Vehicle Number" name="vehicleNumber">
								<Search
									style={{ maxWidth: "100%" }}
									size="large"
									placeholder="Search by vehicle number"
									onSearch={handleInventoryFilter}
									enterButton
								/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Card>
			<p className="tips" style={{ margin: "40px 0 0" }}>
				Select the vehicles you wish to transfer to a different branch
			</p>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div></div>
				<Pagination
					current={currentPage}
					pageSize={pageSize}
					total={dataSource.length}
					onChange={(page, size) => handlePageSizeChange(page, size)}
					showSizeChanger
					pageSizeOptions={['10', '20', '50', '100']}
					style={{ marginBottom: '16px' }}
				/>
			</div>
			<Table
				className="report-table"
				rowSelection={{ type: "radio", onChange: (selectedRowKeys) => setSelectedKeys(selectedRowKeys) }}
				dataSource={paginatedData}
				columns={columns}
				onChange={(pagination) => {
					setCurrentPage(pagination.current);
				}}
				// pagination={{ position: ["topRight", "bottomRight"], total: dataSource.length, pageSize: pageLimit }}
				pagination={false}
				footer={() => {
					return (
						selectedKeys.length > 0 && (
							<Button
								className="delete-button buttons footer-button"
								icon={
									<Image
										src={transfer}
										preview={false}
										width={20}
										height={20}
										style={{ paddingRight: 10, height: 20, width: 25 }}
									/>
								}
								shape="round"
								onClick={() => setShowModal(true)}
								style={{ display: "flex", alignItems: "center" }}
							>
								Transfer
							</Button>
						)
					);
				}}
			/>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div></div>
				<Pagination
					current={currentPage}
					pageSize={pageSize}
					total={dataSource.length}
					onChange={(page, size) => handlePageSizeChange(page, size)}
					showSizeChanger
					pageSizeOptions={['10', '20', '50', '100']}
					style={{ marginBottom: '16px' }}
				/>
			</div>
			<Button
				className="back-button buttons"
				shape="round"
				onClick={() => {
					history.goBack();
				}}
			>
				<Image src={arrow_back} preview={false} />
				BACK
			</Button>
			<Modal
				visible={showModal}
				footer={null}
				onCancel={() => setShowModal(false)}
				destroyOnClose
				afterClose={() => transferVehicleForm.resetFields()}
				width={400}
			>
				<h2 style={{ fontWeight: "bold", textAlign: "center", marginTop: 30 }}>Transfer to branch</h2>
				<p>
					You have selected <span style={{ fontWeight: "bold" }}>{selectedKeys.length} vehicle(s)</span> to
					transfer to a new branch
				</p>
				<Form
					className="transfer-form"
					form={transferVehicleForm}
					onFinish={handleTransferVehicle}
					layout="vertical"
					requiredMark={false}
				>
					<Form.Item
						label="Select branch"
						name="newBranchId"
						rules={[{ required: true, message: "Please select a new branch to transfer vehicle" }]}
					>
						<Select>
							{branchList.map((branch) => {
								return (
									<Select.Option key={branch.id} value={branch.id}>
										{branch.branchName}
									</Select.Option>
								);
							})}
						</Select>
					</Form.Item>
					<Form.Item>
						<Button
							loading={loading}
							htmlType="submit"
							type="primary"
							className="add-new-user-button"
							style={{ width: "100%" }}
						>
							Transfer
						</Button>
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
}

export default BranchVehicles;
