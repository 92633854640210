import moment from 'moment'
import { config } from '../config'
import { getDistrictList } from '../services/api'
import { STATES_CODES } from '../constants'
import * as CryptoJS from 'crypto-js'
import {
    setIsAuthorizeToAccessUserManagement
} from '../services/local'

export const parseTime = (time) => {
    return moment(time).format('DD/MM/YYYY hh:mma')
}

export const parseDate = (time) => {
    return moment(time).format('DD/MM/YYYY')
}

export const breakdownIc = icNumber => {
    if (!icNumber) return {};

    // Remove asterisk character (if it exists)
    icNumber = icNumber.replace('*', '');

    if (icNumber.length !== 14 && icNumber.length !== 12) return {};

    let dob, state, postfix;
    if (icNumber.includes('-')) {
        const arr = icNumber.split('-');
        dob = arr[0];
        state = arr[1];
        postfix = arr[2];
    } else {
        dob = icNumber.slice(0, 6);
        state = icNumber.slice(6, 8);
        postfix = icNumber.slice(8, 12);
    }

    let Year = icNumber.substring(0, 2)
    let cutoff = (new Date()).getFullYear() - 2000

    const year = (Year > cutoff ? '19' : '20') + Year
    const month = dob.substring(2, 4);
    const day = dob.substring(4, 6);

    const formattedDob = moment(`${day} ${month} ${year}`, `DD MM YYYY`).format('DD MMMM YYYY');
    const isMale = Number(postfix) % 2 === 0 ? false : true;

    return {
        formattedDob,
        isMale,
    };
};


export const getDistrictName = async (stateCode, districtCode) => {
    const districts = await getDistrictList(stateCode)
    const district = districts.find(d => d.districtCode === districtCode)
    if (district) {
        return district.districtName
    } else {
        return ""
    }
}

export const parseAddress = (address) => {
    if (!address) return ''
    const string = [
        address.line1 || address.address1,
        address.line2 || address.address2,
        address.line3 || address.address3,
        address.line4,
        address.postcode,
        address.districtName ?? address.district,
        address.city,
        address.state,
    ]
        .filter(field => field)
        .join(', ')
        .toUpperCase()
    return string
}

/**
 * 30/3/2022 daniel.kwok
 * The only diff between parseCorrespondenceAddress and parseAddress
 * and the key, i.e. correspondence*
 */
export const parseCorrespondenceAddress = (address) => {
    if (!address) return ''
    const string = [
        address.correspondentAddress1,
        address.correspondentAddress2,
        address.correspondentAddress3,
        address.correspondentPostcode,
        address.districtName,
        address.correspondentState,
    ]
        .filter(field => field)
        .join(', ')
        .toUpperCase()
    return string
}

export const getJobPublicPostingUrl = jobId => {
    return `${config.jobDomain}/${jobId}`
}

export const getJobPublicTransactionUrl = jobId => {
    return `${config.jobDomain}/apply/${jobId}`
}

export const getIsMobileLayout = () => {
    return window.innerWidth < 400
}

/**
 * Remove trailing comma and whitespaces
 */
export const removeTrailings = (str) => {
    return str.replace(/(^[,\s]+)|([,\s]+$)/g, '');
}

/**
 * To be used with DatePicker.YearPicker
 * - Disables year greater than the current year
 */
export const disabledYear = (current) => {
    return current.year() > moment().year()
}

export const getState = (icNo) => {

    let icStateCode = icNo.substring(6, 8)

    let key = Object.keys(STATES_CODES).find(key => key == icStateCode)

    return STATES_CODES[key]
}

const ENCRIPTION_KEY = 'vj875^v&%9)7ShjL^+_)(^h@$qy7)7w6'

export const getDecryptedVerifyOtpResponse = (token) => {
    const decodedToken = decodeURIComponent(token)
    var keyHex = CryptoJS.enc.Utf8.parse(ENCRIPTION_KEY);

    const decrypted = CryptoJS.TripleDES.decrypt({
        ciphertext: CryptoJS.enc.Base64.parse(decodedToken)
    }, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });

    const decryptedString = decrypted.toString(CryptoJS.enc.Utf8)

    if (!decryptedString) return {}

    const [statusString, messageString] = decryptedString.split('&')
    const status = statusString.split('=')[1]
    const message = messageString.split('=')[1]

    setIsAuthorizeToAccessUserManagement(true)//set to true to disable otp

    return { status, message }
}

export const stripHtml = (str) => {
    return str.replace(/<[^>]+>/gim, '')
}
