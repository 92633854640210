import { DownloadOutlined } from "@ant-design/icons";
import { Breadcrumb, Card, PageHeader } from "antd";

import ETUKAR_USERGUIDE from "../../assets/files/UserGuide_Twocar_V1.1.1.pdf";
// import ETUKAR_EXISTING_USERGUIDE from "../../assets/files/Twocar_ExistingUserGuide.pdf";
import USERGUIDE_INSTALLATION from "../../assets/files/UserGuide_Installation_V1.1.1.pdf";
import BORANG_JPJK6 from "../../assets/files/Borang JPJK6 (Pind.1 2009).pdf";

export default function UserForms() {
	return (
		<>
			<div className="page-content">
				<Breadcrumb separator={">"}>
					<Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
					<Breadcrumb.Item>User Guide</Breadcrumb.Item>
				</Breadcrumb>
				<PageHeader
					className="site-page-header"
					style={{ paddingLeft: "0px", paddingRight: "0px" }}
					title="User Guide"
				/>
				<Card title="Twocar Files" className="form-background">
					<p>
						<a
							download={"Twocar_UserGuide.pdf"}
							href={ETUKAR_USERGUIDE}
							target="_blank"
						>
							<DownloadOutlined />
							Twocar User Guide
						</a>
					</p>
					{/* <p>
						<a
							download={"eTukar_ExistingUser_Login.pdf"}
							href={ETUKAR_EXISTING_USERGUIDE}
							target="_blank"
						>
							<DownloadOutlined />
							User Guide - Existing User
						</a>
					</p> */}
					<p>
						<a
							download={"Twocar_UserGuide_Installation.pdf"}
							href={USERGUIDE_INSTALLATION}
							target="_blank"
						>
							<DownloadOutlined />
							User Installation Guide
						</a>
					</p>
					<p>
						<a
							download={"Borang JPJK6 (Pind.1 2009).pdf"}
							href={BORANG_JPJK6}
							target="_blank"
						>
							<DownloadOutlined />
							Borang JPJK6 (Pind.1 2009) - Borang Permohonan Bayaran Balik LKM/LPKM
						</a>
					</p>
				</Card>
			</div>
			<style>
				{`
            .page-content {
                margin-top: 60px;
            }
            .ant-card { 
                margin-bottom: 10px;
            }
            .ant-card, .ant-card > * {
                text-align: start;
            }
            .ant-card-head { 
                background-color: #00000015;
            }
            .ant-card-head-title {
                font-size: 24px;
            }
            .ant-card-body a span { 
                margin: 0px 30px 5px 10px;
                font-size: 30px;
            }
            .ant-card-body a { 
                display: flex;
                align-items: center;
            }
            .ant-card-head { 
                padding: 0px 34px;
            }
        `}
			</style>
		</>
	);
}
