import React, { useContext, useEffect, useState } from 'react'
import {
    PageHeader,
    message,
    Card,
    Form,
    Input,
    Button,
    Select,
    Breadcrumb,
    Image,
    Row,
    Col,
} from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { STATES } from '../../constants'
import {
} from '@ant-design/icons'
import { addBranch, getBranchByBranchIdAndCoId, updateBranch } from '../../services/api'
import { SessionContext } from '../../App'
import arrow_back from '../../assets/arrow_back.svg'

export default function BranchFormPage() {

    const { user, branch: _branch, company } = useContext(SessionContext)
    const history = useHistory()
    const params = useParams()
    const [form] = Form.useForm()
    const [isLoading, setIsLoading] = useState(false)

    const isEdit = params.branchId

    useEffect(() => {
        if (isEdit) {
            if (!company?.id) return null
            setIsLoading(true)
            getBranchByBranchIdAndCoId(company.id, params.branchId)
                .then(res => {
                    if (res.status !== '00') throw res.message
                    form.setFieldsValue({
                        ...res.branchDetails,
                        ...res.branchDetails?.branchAddress,
                        line1 : `${res.branchDetails?.branchAddress?.line1} ${res.branchDetails?.branchAddress?.line2} ${res.branchDetails?.branchAddress?.line3}`,
                        state: res.branchDetails?.branchAddress?.stateCode,
                        contactPersonName: res.branchDetails?.branchContactName,
                    })
                })
                .catch(err => {
                    err && message.error(err.toString())
                })
                .finally(() => setIsLoading(false))
        }
    }, [company])

    return (
        <>
        <div className='page-content'>
             <Breadcrumb separator=">" style={{marginTop: isEdit ? "60px" : "0px", visibility: isEdit ? "visible" : "hidden"}}>
                <Breadcrumb.Item href='/'>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item href='/branchmanagement'>Branch management</Breadcrumb.Item>
                <Breadcrumb.Item >Edit branch</Breadcrumb.Item>
            </Breadcrumb>
            <PageHeader
                className="site-page-header"
                style={{
                    color: 'white',
                    paddingLeft: "0px"
                }}
                title={isEdit ? 'Edit Branch' : 'Add New Branch'}
            />

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >

                <Card
                    className='form-background'
                    style={{
                        height: '50%',
                        width: '100%'
                    }}
                >

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            flexDirection: 'column'
                        }}
                    >
                        <h2 style={{ fontWeight: 'bold' }}>Branch Information</h2>
                        <p style={styles.label}>Company Name</p>
                        <p>{company.coName || `Unavailable`}</p>
                        <Form
                            form={form}
                            layout={'vertical'}
                            style={{
                                width: '100%',
                                textAlign: 'left'
                            }}
                            requiredMark={'optional'}
                            onFinish={values => {

                                /**
                                 * 19.1.2022 daniel.kwokwok
                                 * trim all begining & trailing white space
                                 */
                                Object.keys(values).map(k => values[k] = values[k]?.trim())

                                const branch = {
                                    coId: company.id,
                                    username: user.username,
                                    branchId: params.branchId,

                                    address1: values.line1,
                                    address2: values.line2,
                                    address3: values.line3,
                                    branchName: values.branchName,
                                    city: values.city,
                                    contactPersonName: values.contactPersonName,
                                    contactno: values.branchContactNo,
                                    email: values.branchEmail,
                                    faxno: values.branchFaxNo,
                                    is_active: true,
                                    is_hq: false,
                                    postcode: values.postcode,
                                    state: values.state,
                                }

                                setIsLoading(true)
                                if (isEdit) {
                                    updateBranch(branch)
                                        .then(res => {
                                            if (res.status !== true) throw res.message
                                            message.success(res.message)
                                            history.goBack()
                                        })
                                        .catch(err => {
                                            alert(err)
                                        })
                                        .finally(() => setIsLoading(false))
                                } else {
                                    addBranch(branch)
                                        .then(res => {
                                            if (res.status !== true) throw res.message
                                            message.success(res.message)
                                            history.goBack()
                                        })
                                        .catch(err => {
                                            alert(err)
                                        })
                                        .finally(() => setIsLoading(false))
                                }
                            }}
                        >
                            <Form.Item name='username' hidden={true} />
                            <Form.Item name='branchId' hidden={true} />
                            <Form.Item name='coId' hidden={true} />
                            <Row gutter={[16, 0]}>
                                <Col span={7}>
                                    <Form.Item
                                        style={{flex: 1}}
                                        rules={[
                                            {
                                                required: true,
                                                pattern: new RegExp('^[a-zA-Z0-9%\'`()\\-&.\\/@*,!©®™ ]{2,100}$'),
                                                message: ['Must be between 2 - 100 characters',
                                                    'Allow for (a-z), (A-Z), (0-9) and % \' ` ( ) - & . / @ * , ! © ® ™ and space '
                                                ],
                                            },
                                        ]}
                                        name={'branchName'}
                                        label={<p style={styles.label}>Branch Name</p>}>
                                        <Input
                                            className='form-input-field'
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={7}>
                                    <Form.Item
                                        style={{flex: 1}}
                                        rules={[
                                            {
                                                required: true,
                                                pattern: new RegExp('^[a-zA-Z‘’@()`\'\\/,.\\- ]{1,100}$'),
                                                message: ['Must be between 1 - 100 characters',
                                                    'Allow for (a-z), (A-Z) and ‘ ’  @ ( ) ` \' \/ , . - and space']
                                            },
                                        ]}
                                        name={'contactPersonName'}
                                        label={<p style={styles.label}>Contact Person</p>}>
                                        <Input
                                            className='form-input-field'
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        pattern: new RegExp('^[a-zA-z0-9&()\\-\',.\\/@#: ]{1,255}$'),
                                        message: ['Must be between 1 - 255 characters',
                                            'Allow for (a-z), (A-Z), (0-9) and & ( ) - \' , . / @ # : and space']
                                    },
                                ]}
                                name={'line1'}
                                label={<p style={styles.label}>Address</p>}>
                                <Input
                                    className='form-input-field'
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                            <Row gutter={[16, 16]}>
                                <Col span={8}>
                                    <Form.Item
                                        name={'state'}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Required',
                                            },
                                        ]}
                                        label={<p style={styles.label}>State</p>}>
                                        <Select
                                            style={{border: "0px", textTransform: "uppercase"}}
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {
                                                Object.values(STATES)
                                                    .map(state => {
                                                        return <Select.Option
                                                            key={state.code}
                                                        // value={state.code}
                                                        >
                                                            {state.label}
                                                        </Select.Option>
                                                    })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                            name={'city'}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Required',
                                                    pattern: new RegExp('^[a-zA-Z ]{1,255}$'),
                                                    message: [
                                                        `Must be betweeen 1-255 characters`,
                                                        `Must be a-z, A-Z only`,
                                                    ]
                                                },
                                            ]}
                                            label={<p style={styles.label}>Town/City</p>}>
                                            <Input
                                                className='form-input-field'
                                                style={{textTransform: "uppercase"}}
                                            />
                                        </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        name={'postcode'}
                                        rules={[
                                            {
                                                required: true,
                                                pattern: new RegExp('^\\d{5}$'),
                                                message: 'Accepts only numbers (length must be 5 numbers, e.g. 47000',
                                            },
                                        ]}
                                        label={<p style={styles.label}>Postcode</p>}>
                                        <Input
                                            className='form-input-field'
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 0]}>
                                <Col span={8}>
                                    <Form.Item
                                        name={'branchContactNo'}
                                        rules={[
                                            {
                                                required: true,
                                                pattern: new RegExp('^\\d{9,11}$'),
                                                transform: value => Number(value),
                                                message: 'Accepts only numbers (length must be between 9 - 11)',
                                            },
                                        ]}
                                        label={<p style={styles.label}>Phone Number</p>}>
                                        <Input
                                            className='form-input-field'
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                pattern: new RegExp('^\\d{9,11}$'),
                                                transform: value => Number(value),
                                                message: 'Accepts only numbers (length must be between 9 - 11)',
                                            },
                                        ]}
                                        name={'branchFaxNo'}
                                        label={<p style={styles.label}>Fax Number</p>}>
                                        <Input
                                            className='form-input-field'
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        rules={[

                                        ]}
                                        name={'branchEmail'}
                                        label={<p style={styles.label}>Email Address</p>}>
                                        <Input
                                            className='form-input-field'
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item>
                                <div
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginTop: 20,
                                    }}
                                >
                                    <Button
                                        className='back-button buttons'
                                        shape='round'
                                        onClick={() => {
                                            history.goBack()
                                        }}
                                    >   
                                        <Image src={arrow_back} preview={false} />
                                        Back
                                    </Button>
                                    <Button
                                        className='add-new-branch-button'
                                        style={{
                                            width: 200
                                        }}
                                        loading={isLoading}
                                        htmlType='submit'
                                        type='primary'
                                    >
                                        {isEdit ? 'Save' : 'Complete'}
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>
                    </div>
                </Card>
            </div>
        </div>
        <style>
            {`
                .ant-select-selector { 
                    border: 1px solid #2B61C4 !important;
                    box-shadow: 0px 2px 4px #00000033 !important;
                    background: #FFFFFF 0% 0% no-repeat padding-box !important;
                    border-radius: 4px !important;
                    height: 40px !important;
                    display: flex;
                    align-items: center;
                }
                .ant-select-arrow {
                    color: #2B61C4;
                }
            `}
        </style>
        </>
    )
}
const styles = {
    label: {
        color: 'grey', fontStyle: 'italic', marginBottom: 0
    },
    value: {
        fontWeight: 'bold',
    }
}
