import React, { useEffect, useState } from 'react'
import {
    Input,
    Button,
    Card,
    Divider,
    message,
} from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import propTypes from 'prop-types'

import {
    generate2FA, verifyOTP
} from '../../services/api'

const STEPS = {
    preview: 'preview',
    activate: 'activate',
    finish: 'finish',
}

function Setup2fa(props) {

    const history = useHistory()
    const params = useParams()
    const [authCode, setAuthCode] = useState('')
    const [qrcodebase64, setQrcodebase64] = useState(null)
    const [showLoading, setShowLoading] = useState(false)

    const COMPONENT_STEPS = {
        [STEPS.preview]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Two-factor Authentication
                    </h1>
                    <p>
                        You will first need to install the Google Authenticator app on your phone before enabling two-factor authentication:
                    </p>
                    <p style={{ fontWeight: 'bold', marginTop: 20, marginBottom: 0 }}>
                        Android
                    </p>
                    <p>Install <a href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_GB&gl=US' target='_blank'>Google Authenticator</a> from the Google Play Store</p>

                    <p style={{ fontWeight: 'bold', marginTop: 20, marginBottom: 0 }}>
                        iPhone, iPod Touch, iPod:
                    </p>
                    <p>Install <a href='https://apps.apple.com/gb/app/google-authenticator/id388497605' target='_blank'>Google Authenticator</a> from the App Store</p>

                    <p style={{ fontWeight: 'bold', marginTop: 20, marginBottom: 0 }}>
                        BlackBerry:
                    </p>
                    <p>Install <a href='https://apps.apple.com/gb/app/google-authenticator/id388497605' target='_blank'>Google Authenticator</a> in the web browser on your BlackBerry</p>

                    <Button
                        type='primary'
                        size='large'
                        style={{
                            background: " #2B61C4 0% 0% no-repeat padding-box",
                            boxShadow: "0px 4px 0px #1E4489",
                            borderRadius: "8px",
                            borderColor: 'transparent',
                            color: 'white'
                        }}
                        onClick={() => {
                            history.push(`/2fa/${STEPS.activate}`)
                        }}
                    >
                        Setup Two-Factor Authentication
                    </Button>
                </div>
            )
        },
        [STEPS.activate]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Two-factor Authentication
                    </h1>
                    <p style={{ fontStyle: 'italic' }}>Scan QR</p>
                    <img src={`data:image/png;base64, ${qrcodebase64}`} height={200} />
                    <Divider />
                    <div
                        style={{
                            textAlign: 'left'
                        }}
                    >
                        <p style={{ fontStyle: 'italic' }}>Authenticator Code </p>
                        <Input
                            className='textbox-form-style'
                            value={authCode}
                            onChange={e => setAuthCode(e.target.value)}
                            placeholder='000 000'
                        />
                    </div>
                    <Button
                        loading={showLoading}
                        size='large'
                        style={{
                            background: " #2B61C4 0% 0% no-repeat padding-box",
                            boxShadow: "0px 4px 0px #1E4489",
                            borderRadius: "8px",
                            borderColor: 'transparent',
                            marginTop: 20,
                            width: '100%',
                            color: 'white'
                        }}

                        disabled={!authCode}
                        type='primary'
                        onClick={() => {
                            setShowLoading(true)
                            verifyOTP(authCode)
                                .then(res => {

                                    setShowLoading(false)
                                    
                                    if (res.status !== true) throw res.message
                                    message.success(res.message)

                                    history.push(`/2fa/${STEPS.finish}`)
                                })
                                .catch(err => {
                                    setShowLoading(false)
                                    err && message.error(err)
                                })
                        }}
                    >
                        Activate Two-Factor Authentication
                    </Button>
                </div>
            )
        },
        [STEPS.finish]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Two-factor Authentication
                    </h1>
                    <p style={{ fontStyle: 'italic' }}>Success!</p>
                    <Button
                        style={{
                            marginTop: 20,
                            width: '100%'
                        }}
                        type='primary'
                        onClick={() => {
                            window.location.pathname = '/selectbranch'
                        }}
                    >
                        Select Branch
                    </Button>
                </div>
            )
        },
    }

    const currentStep = COMPONENT_STEPS[params.step]

    useEffect(() => {
        if (!params.step) {
            history.replace(`/2fa/${STEPS.preview}`)
        }
    }, [params])

    useEffect(() => {

        /**Load 2FA QR Code */
        generate2FA()
            .then(res => {
                if (res.status !== true) throw res.message
                setQrcodebase64(res.qrImageData)
            })
            .catch(err => err && message.error(err))
    }, [])

    return (
        <div>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Card
                    style={{
                        height: '50%',
                        minWidth: 400,
                        width: '50%',
                        boxShadow: '0px 3px 6px #00000029',
                        borderRadius: 8,
                        marginBottom: 16
                    }}
                >
                    {
                        currentStep && currentStep.component
                    }
                </Card>
            </div>
        </div>
    )
}

Setup2fa.propTypes = {
    branchUser: propTypes.object,
    onChange: propTypes.func,
}

const styles = {
    label: {
        color: 'grey', fontStyle: 'italic', marginBottom: 0
    },
    value: {
        fontWeight: 'bold',
    }
}

export default Setup2fa