import { LoadingOutlined } from "@ant-design/icons";
import { message, Modal } from "antd";
import React, { createContext, useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.less";
import Frame from "./components/Frame";
import UnauthenticatedFrame from "./components/UnauthenticatedFrame";
import { HQ_BRANCH, APP_CONST } from "./constants";
import {
	getRolesByUsername,
	getRolesPermissions,
	getCompanyOfLoggedInUser,
	getPrepaidBalance,
	getProfile,
	logout,
	check2FA,
	checkOtpStatus
} from "./services/api";
import {
	getBranchId,
	removeUserRole,
	setUserRole,
	getUnverifiedAccount,
	getCompanyId,
	removeToken,
	removeAuthorizedNric,
	removeUnverifiedAccount,
	removeBranchId,
	removeCompanyId,
	removeIsAuthorizeToAccessUserManagement,
	removeLoggedInUsernaame,
	setPermissions
} from "./services/local";
import MetaTagsManager from "./components/MetaTagsManager";

export const SessionContext = createContext();

export default function App(props) {

	const [isLoading, setIsLoading] = useState(false);
	const [user, setUser] = useState(null);
	const [prepaid, setPrepaid] = useState(null);
	const [company, setCompany] = useState({});
	const [branch, setBranch] = useState({});
	const [branchs, setBranchs] = useState([]);
	const [users, setUsers] = useState([]);
	const [language, setLanguage] = useState("en");

	useEffect(() => {
		// Add event listener to window object
		window.addEventListener('storage', handleStorageChange);

		return () => {
			// Remove event listener when component unmounts
			window.removeEventListener('storage', handleStorageChange);
		};
	}, []);

	const handleStorageChange = (event) => {

		const { key, oldValue, newValue } = event;
		
		// Check if the change was made in a different tab
		if (event.storageArea !== window.sessionStorage) {
			// Check if the key is for the logged in user
			if (key === 'etukar_uid' && oldValue && newValue && oldValue !== newValue) {
				removeAuthorizedNric()
				removeToken()

				// removeBranchId()
				// removeCompanyId()
				// removeUnverifiedAccount()
				// removeIsAuthorizeToAccessUserManagement()
				// removeLoggedInUsernaame()
				window.location.pathname = '/login';
			}
		}
	};


	/**
	 * Check token validity every 10 minute
	 * If got token, but expired, prompt a re-login, if not already at login page
	 */
	useEffect(() => {
		const checkToken = async () => {
			if (!window.timer && window.location.pathname !== "/login") {
				window.timer = setInterval(async () => {
					const res = await getProfile();

					let isTokenExpired = res?.error_description?.includes(
						"Access token expired"
					);
					if (isTokenExpired) {
						clearInterval(window.timer);
						window.timer = undefined;

						Modal.error({
							maskStyle: { backgroundColor: "black", opacity: 0.9 },
							title: `Session expired`,
							okButtonProps: { style: { display: "none" } },
							content: (
								<div>
									<p>Your session had expired.</p>
									<a
										onClick={async () => {
											await logout();
											await removeUserRole();
											window.location.pathname = "/login";
										}}
									>
										Login again
									</a>
								</div>
							),
						});
					}
				}, 1 * 1000 * 600);
			}
		}

		checkToken()
	}, []);

	useEffect(() => {
		const init = async () => {
			setIsLoading(true);

			await getProfile()
				.then(async (res) => {
					/**
					 * User is not logged in
					 * Redirect to /login if not already there
					 */
					const isTokenInvalid = res?.error === "invalid_token";
					if (isTokenInvalid) {
						removeUserRole();

						if (window.location.pathname.includes('/forgot-password') || window.location.pathname.includes('/reginterest')) {
							// return and let react router re-route to forgot password page and landing page
							return Promise.resolve();
						}

						// If user token invalid, redirect to login
						if (window.location.pathname !== "/login" && !window.location.pathname.startsWith("/activate-account")) {
							window.location.pathname = "/login";
						}

						return Promise.resolve();
					} else {
						// set user and user roles if user is logged in
						// NOTE: each user can have ***UP TO*** 2 roles, 1 non-JPJ role, and 1 JPJ role
						// Here, we are storing user's non-JPJ role in local storage
						// In order to set permissions on what action they can perform and what not on eTukar.
						setUser(res);
						/* getRolesByUsername(res.nric).then((res) => {
							
							if (res.status === true) {
								setUserRole(res.roles.filter((role) => !role.name.toLowerCase().includes('jpj'))[0]["name"]);
								setPermissions(res.permissions ?? [])
							} else {

								message.error(res.message)

								setTimeout(async () => {
									message.loading('Redirecting to login page...')
									await logout();
									await removeUserRole();
									window.location.pathname = "/login";

								}, 1 * 2000)
							}
						}); */

						let coId = getCompanyId()

						if (!coId) {
							console.log('coId is empty...')
							return
						}
						getRolesPermissions(res.nric, coId).then((res) => {
							if (res.status === true) {
								if(res.permissions?.accessDenied) {
									message.warn("Access Denied.")

									setTimeout(async () => {
										message.loading('Redirecting to login page...')
										await logout();
										await removeUserRole();
										window.location.pathname = "/login";
	
									}, 1 * 2000)
								}

								setUserRole(res.roles.filter((role) => !role.name.toLowerCase().includes('jpj'))[0]["name"]);
								setPermissions(res.permissions ?? [])
							} 
							else {

								message.error(res.message)

								setTimeout(async () => {
									message.loading('Redirecting to login page...')
									await logout();
									await removeUserRole();
									window.location.pathname = "/login";

								}, 1 * 2000)
							}
						});

						if (APP_CONST.USE_OTP === true) {

							checkOtpStatus().then(res => {

								if (res.status === true) {

									return getCompanyOfLoggedInUser(coId)
										.then(async (res) => {
											if (res.status === "00") {
												setCompany(res.companyDetails);
												// This is referring to all the available branches for this company
												// Not the branch that user has chosen.
												setBranchs(res.companyDetails?.branchDetails);
												// This is getting the branch ID of the branch user has chosen from local storage
												await getBranchId().then(async (branchId) => {
													// ensure that branchId returned is indeed a valid integer
													if (parseInt(branchId) >= 0) {
														// HQ branch will have branch ID of 0, this is always the case.
														// Since HQ branch details does not exist in /etukar/viewCompany (line 99 above),
														// Therefore, we simply set the HQ branch details according to the company details.
														if (branchId === 0) {
															setBranch({
																...HQ_BRANCH,
																branchAddress:
																	res.companyDetails
																		.companyAddress,
																branchContactName: "",
																branchContactNo:
																	res.companyDetails
																		.contactNo,
																branchEmail:
																	res.companyDetails
																		.email,
																branchFaxNo:
																	res.companyDetails
																		.faxNo,
																is_active: true,
															});
														} else {
															const _branch =
																res?.companyDetails?.branchDetails.find(
																	(b) => b.id === branchId
																);
															_branch && setBranch(_branch);
														}

														//if remote user account is unverified, redirect to user thumbprint activation page
														const unverifiedAccount = getUnverifiedAccount()
														if (unverifiedAccount === 'true' && window.location.pathname !== '/remoteUserActivation') {
															window.location.pathname = '/remoteUserActivation'
														}

														// if cannot find a branchId, implies user did not choose a branch
														// therefore, redirect to /selectbranch page
													} else {
														if (
															window.location.pathname !==
															"/selectbranch"
														) {
															window.location.pathname =
																"/selectbranch";
														} else {
															throw 'No active branch or HQ branch available for this user.'
														}

													}
												});

												// refers to all the users that belong to this company
												setUsers(res.users);

												return getPrepaidBalance(
													res.companyDetails.coRegNo
												);
											} else {
												// throw `No company found for this user`;
												throw res.message
											}
										})
										.then((res) => {
											if (res.status !== 200) throw res.message;

											setPrepaid({
												balanceAmount: res.balanceAmount,
											});
										})
										.catch((err) => {
											err && message.error(err.toString());
											err && setTimeout(async () => {
												message.loading('Redirecting to login page...')
												await logout();
												await removeUserRole();
												window.location.pathname = "/login";
											}, 1 * 5000)
										})
										.finally(() => setIsLoading(false));

								} else {
									if (!window.location.pathname.includes("/otp")) {
										window.location.pathname = "/otp"
									}
								}
							})

						} else {
							check2FA()
								.then(res => {
									if (res.status === true) {
										// This is where we get the company details, and branch details
										return getCompanyOfLoggedInUser(coId)
											.then(async (res) => {
												if (res.status === "00") {
													setCompany(res.companyDetails);
													// This is referring to all the available branches for this company
													// Not the branch that user has chosen.
													setBranchs(res.companyDetails?.branchDetails);
													// This is getting the branch ID of the branch user has chosen from local storage
													await getBranchId().then(async (branchId) => {
														// ensure that branchId returned is indeed a valid integer
														if (parseInt(branchId) >= 0) {
															// HQ branch will have branch ID of 0, this is always the case.
															// Since HQ branch details does not exist in /etukar/viewCompany (line 99 above),
															// Therefore, we simply set the HQ branch details according to the company details.
															if (branchId === 0) {
																setBranch({
																	...HQ_BRANCH,
																	branchAddress:
																		res.companyDetails
																			.companyAddress,
																	branchContactName: "",
																	branchContactNo:
																		res.companyDetails
																			.contactNo,
																	branchEmail:
																		res.companyDetails
																			.email,
																	branchFaxNo:
																		res.companyDetails
																			.faxNo,
																	is_active: true,
																});
															} else {
																const _branch =
																	res?.companyDetails?.branchDetails.find(
																		(b) => b.id === branchId
																	);
																_branch && setBranch(_branch);
															}

															//if remote user account is unverified, redirect to user thumbprint activation page
															const unverifiedAccount = getUnverifiedAccount()
															if (unverifiedAccount === 'true' && window.location.pathname !== '/remoteUserActivation') {
																window.location.pathname = '/remoteUserActivation'
															}

															// if cannot find a branchId, implies user did not choose a branch
															// therefore, redirect to /selectbranch page
														} else {
															if (
																window.location.pathname !==
																"/selectbranch"
															) {
																window.location.pathname =
																	"/selectbranch";
															}
														}
													});

													// refers to all the users that belong to this company
													setUsers(res.users);

													return getPrepaidBalance(
														res.companyDetails.coRegNo
													);
												} else {
													throw `No company found for this user`;
												}
											})
											.then((res) => {
												if (res.status !== 200) throw res.message;

												setPrepaid({
													balanceAmount: res.balanceAmount,
												});
											})
											.catch((err) => {
												err && message.error(err.toString());
											})
											.finally(() => setIsLoading(false));
									} else {
										if (!window.location.pathname.includes("/2fa")) {
											window.location.pathname = "/2fa"
										}
									}
								})
						}

					}
				})
				.catch((err) => {
					message.error(err.toString() || `Unable to get profile`);

					if (window.location.pathname === "/") {
						message.loading("Redirecting to login");
						setTimeout(() => {
							removeUserRole();
							window.location.pathname = "/login";
						}, 1 * 1000);
					}
				})
				.finally(() => setIsLoading(false));
		}

		init()
	}, []);

	if (isLoading) {
		return (
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: "100vh",
				}}
			>
				<LoadingOutlined style={{ fontSize: 50 }} />
			</div>
		);
	}

	window.eTukar_context = {
		user,
		prepaid,
		branch,
		company,
		branchs,
		users,
	};

	return (
		<BrowserRouter>
			<MetaTagsManager/>
			{user ? (
				<SessionContext.Provider
					value={{ branchs, user, prepaid, branch, company, users }}
				>
					<Frame />
				</SessionContext.Provider>
			) : (
				<SessionContext.Provider value={{ language, setLanguage}}>
					<UnauthenticatedFrame />
				</SessionContext.Provider>
			)}
		</BrowserRouter>
	);
}
