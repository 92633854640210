import ApproverAuthInModal from "../../../JpjSubmissionModal/index";
import {
  verifyApproverOTP,
  verifyApproverSmsOtp,
} from "../../../../services/api";
import { Modal, Row, Col, Spin, Button, message, Card } from "antd";
import { APP_CONST } from "../../../../constants";
import { getDecryptedVerifyOtpResponse } from "../../../../util/index";
import { ApprovalStatusSpan } from "../../UI/Span";
import { TAB_TYPE } from "../../../CustomPagination/TransactionTable";
import "./index.css"; // Import your custom CSS file

export const ApprovalInModal = ({
  isIncorrectOtp,
  isModalVisible,
  transferIdList,
  isAdminUser,
  setIsModalVisible,
  setIsApprovalRejected,
  processApproval,
  setIsIncorrectOtp,
  approvalModuleType,
  setBulkTransferId,
  moduleType,
  transactionType,
  isM2mTransaction,
}) => {
  return (
    <ApproverAuthInModal
      isIncorrectOtp={isIncorrectOtp}
      isModalVisible={isModalVisible}
      isApprover={true}
      isM2M={true}
      transferId={""}
      transferIdList={transferIdList}
      isFromPending={true}
      isDisableApp={false}
      isSelfApproval={isAdminUser}
      approvalModuleType={approvalModuleType}
      moduleType={moduleType}
      transactionType={transactionType}
      isM2mTransaction={isM2mTransaction}
      onCancel={() => setIsModalVisible(false)}
      onOk={(
        approverIc,
        authCode,
        isPush,
        appApprovalResponse,
        isOtpSuccess,
        bulkTransferId
      ) => {
        if (isPush) {
          setIsModalVisible(false);
          if (appApprovalResponse.isRejected) {
            setIsApprovalRejected(true);
          } else {
            console.log("bulkTransferId-app", bulkTransferId);
            bulkTransferId &&
              bulkTransferId != null &&
              setBulkTransferId(bulkTransferId);
            setIsApprovalRejected(false);
            processApproval(bulkTransferId);
          }
        } else if (APP_CONST.USE_OTP === true) {
          if (isOtpSuccess) {
            setIsIncorrectOtp(false);
            setIsModalVisible(false);
            processApproval();
          } else {
            setIsIncorrectOtp(true);
          }
          /* verifyApproverSmsOtp(approverIc, authCode)
            .then((res) => {
              if (!res.encryptedString) {
                let errorMsg = res.message ? res.message : "ERROR";
                throw errorMsg;
              }
              const decryptedResponse = getDecryptedVerifyOtpResponse(
                res.encryptedString
              );
              if (decryptedResponse.status == "false") {
                setIsIncorrectOtp(true);
                throw decryptedResponse.message;
              }
              setIsIncorrectOtp(false);
              setIsModalVisible(false);
              processApproval();
            })
            .catch((err) => {
              setIsIncorrectOtp(true);
              err && message.error(err);
            })
            .finally(() => {
              setIsIncorrectOtp(false);
            }); */
        } else {
          verifyApproverOTP(approverIc, authCode)
            .then((res) => {
              if (res.status !== true) {
                setIsIncorrectOtp(true);
                throw res.message;
              }
              setIsIncorrectOtp(false);
              setIsModalVisible(false);
              processApproval();
            })
            .catch((err) => {
              setIsIncorrectOtp(true);
              err && message.error(err);
            })
            .finally(() => {
              setIsIncorrectOtp(false);
            });
        }
      }}
    />
  );
};

export const ApprovalStatusModal = ({
  isModalVisible,
  setIsModalVisible,
  bulkDetails,
  isM2mBulkCompleted,
  vehicleDataList,
  handleClearAll,
  isPayment,
  isOutM2m,
  setTab,
  selectedDataSourceAmount,
  balanceAmount,
}) => {
  const totalFailed = bulkDetails.paymentFailed
    ? bulkDetails.inquiryFailed + bulkDetails.paymentFailed
    : bulkDetails.inquiryFailed;
  const totalSuccess =
    bulkDetails.paymentSuccess !== null
      ? bulkDetails.paymentSuccess
      : bulkDetails.inquirySuccess;
  const isAllFailed = bulkDetails.totalVehicle === totalFailed;
  const isAllSuccess = bulkDetails.totalVehicle === totalSuccess;
  const totalAmount = bulkDetails.bulkTotalAmount
    ? bulkDetails.bulkTotalAmount
    : selectedDataSourceAmount;
  return (
    <>
      <Modal
        visible={isModalVisible}
        // closable={isM2mBulkCompleted}
        footer={false}
        onCancel={() => {
          setIsModalVisible(false);
        }}
        centered // This prop will center the modal
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }} // This will hide the cancel button
      >
        <div style={{ textAlign: "center" }}>
          <br />
          <p style={{ fontSize: "2.5em" }}>
            <b>
              {isM2mBulkCompleted ? (
                <>{bulkDetails.totalVehicle} transactions processed</>
              ) : (
                <>
                  {bulkDetails.totalVehicle != 0
                    ? bulkDetails.totalVehicle
                    : vehicleDataList.length}{" "}
                  Transactions will submit. This may take a minute.
                </>
              )}
            </b>
          </p>
          {isM2mBulkCompleted ? (
            <>
              <Row gutter={16}>
                <Col span={isAllSuccess ? 24 : isAllFailed ? 0 : 12}>
                  <ApprovalStatusSpan
                    isSuccess={true}
                    inquiryCount={totalSuccess}
                  />
                </Col>
                <Col span={isAllFailed ? 24 : isAllSuccess ? 0 : 12}>
                  <ApprovalStatusSpan
                    isSuccess={false}
                    inquiryCount={totalFailed}
                  />
                </Col>
              </Row>
              <br />
              <p style={{ fontSize: "1.5em" }}>
                {!isAllSuccess
                  ? "Retry the " +
                    totalFailed +
                    " failed transactions from the transaction list."
                  : isPayment && !isOutM2m
                  ? "You have paid RM" +
                    Number(totalAmount).toFixed(2) +
                    " from your prepaid wallet. The remaining balance is RM" +
                    parseFloat(Number(balanceAmount)).toFixed(2)
                  : isOutM2m
                  ? "You have approved " + totalSuccess + " transactions."
                  : "Please complete the payment for this transactions."}
              </p>
            </>
          ) : (
            <div style={{ textAlign: "center" }}>
              <Spin size="large" />
            </div>
          )}
          <br />
          <Button
            type="primary"
            disabled={!isM2mBulkCompleted}
            onClick={async () => {
              if (!isPayment && isAllSuccess) {
                await handleClearAll();
                setTab(TAB_TYPE.PAYMENT);
              } else {
                handleClearAll();
              }
              // handleClearAll();
              //   props.next({ bulkTransferId: bulkTransferId });
            }}
          >
            {!isAllSuccess
              ? "View Transaction List"
              : isPayment
              ? "Close"
              : "Proceed to payment"}
          </Button>
        </div>
        {/* )} */}
      </Modal>
    </>
  );
};

export const TransactionAmountModal = ({
  isModalVisible,
  setIsModalVisible,
  dataSource,
}) => {
  let subTotal = dataSource.subTotalAmount;
  if (!subTotal && dataSource.processingAmount && dataSource.eserviceAmount) {
    subTotal = dataSource.processingAmount + dataSource.eserviceAmount;
  }
  const style = {
    leftCol: {
      textAlign: "left",
      paddingLeft: "20px",
      paddingTop: "5px",
      paddingBottom: "5px",
    },
    rightCol: {
      textAlign: "right",
      paddingRight: "20px",
      paddingTop: "5px",
      paddingBottom: "5px",
    },
  };
  return (
    <>
      <Modal
        className="transaction-modal-border"
        bodyStyle={{ padding: "0px" }}
        visible={isModalVisible}
        // closable={isM2mBulkCompleted}
        footer={false}
        onCancel={() => {
          setIsModalVisible(false);
        }}
        centered // This prop will center the modal
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }} // This will hide the cancel button
      >
        <div style={{ textAlign: "center" }}>
          {/* <Card
            className="custom-transaction-card"
            bodyStyle={{ padding: 0, textAlign: "justify" }}
            style={{
              height: "50%",
              boxShadow: "0px 3px 6px #00000029",
              borderRadius: "4px",
              // marginBottom: 16,
            }}
            bordered={false}
          > */}
          {/* First Row */}
          <Row
            justify="left"
            style={{ paddingTop: "24px", paddingLeft: "20px" }}
          >
            <Col>
              <h2 style={{ textAlign: "left" }}>
                <b>Ownership transfer fees</b>
              </h2>
              {/* <h2 style={{ textAlign: "center" }}>
                  <b>{totalVehicle} vehicles</b>
                </h2> */}
              <br />
            </Col>
          </Row>

          {/* Second Row */}
          <Row justify="space-between">
            <Col span={12} style={style.leftCol}>
              <span>Items</span>
            </Col>
            <Col span={12} style={style.rightCol}>
              <span>Amount (RM)</span>
            </Col>
          </Row>
          {/* Third Row */}
          <Row style={{ backgroundColor: "#fafafa" }}>
            <Col span={12} style={style.leftCol}>
              Processing Fee
              {/* <br />
            <span>RM10 x {totalVehicle} vehicles</span> */}
            </Col>
            <Col span={12} style={style.rightCol}>
              <b>
                {dataSource.processingAmount
                  ? dataSource.processingAmount.toFixed(2)
                  : "-"}
              </b>
              <br />
            </Col>
            <Col span={12} style={style.leftCol}>
              MYEG Service Charge
              {/* <br />
            <span>RM2.75 x {totalVehicle} vehicles</span> */}
            </Col>
            <Col span={12} style={style.rightCol}>
              <b>
                {dataSource.eserviceAmount
                  ? dataSource.eserviceAmount.toFixed(2)
                  : "-"}
              </b>
            </Col>
          </Row>

          {/* Fourth Row */}
          <Row style={{ backgroundColor: "#efefef" }}>
            <Col span={12} style={style.leftCol}>
              Subtotal
            </Col>
            <Col span={12} style={style.rightCol}>
              <b>{subTotal ? subTotal.toFixed(2) : "-"}</b>
            </Col>
            <Col span={12} style={style.leftCol}>
              SST @ 8%
            </Col>
            <Col span={12} style={style.rightCol}>
              <b>
                {dataSource.sstAmount ? dataSource.sstAmount.toFixed(2) : "-"}
              </b>
            </Col>
            {dataSource.fisAmount ? (
              <>
                <Col span={12} style={style.leftCol}>
                  Ownership Claimed Fee
                </Col>
                <Col span={12} style={style.rightCol}>
                  <b>
                    {dataSource.fisAmount
                      ? dataSource.fisAmount.toFixed(2)
                      : "-"}
                  </b>
                </Col>
              </>
            ) : (
              <></>
            )}
            <Col span={12} style={style.leftCol}>
              JPJ Ownership Transfer Fee
            </Col>
            <Col span={12} style={style.rightCol}>
              <b>
                {dataSource.jpjAmount ? dataSource.jpjAmount.toFixed(2) : "-"}
              </b>
            </Col>
          </Row>

          {/* Last Row */}
          <Row
            style={{
              backgroundColor: "#ffd500",
              color: "black",
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            <Col span={12} style={style.leftCol}>
              <b>Total</b>
            </Col>
            <Col span={12} style={style.rightCol}>
              <b>
                <span style={{ fontSize: "medium" }}>
                  RM{" "}
                  {dataSource.totalAmount
                    ? dataSource.totalAmount.toFixed(2)
                    : "-"}
                </span>
              </b>
            </Col>
          </Row>
          {/* </Card> */}
        </div>
      </Modal>
    </>
  );
};
