import { } from '@ant-design/icons'
import {
    Breadcrumb,
    message,
    PageHeader,
    Skeleton
} from 'antd'
import moment from 'moment'
import React, { useEffect, useMemo, useState, useContext } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { STATES, STATES_FULLNAME } from '../../constants'
import { getAccountDetails, getRolesByUsername, getUser, isAccountExist } from '../../services/api'
import { getCachedStates, removeCachedStates, getUserRole, getToken } from '../../services/local'
import ViewPage from './view'
import AuthorizeUser from './authorizeUser'
import { STEPS } from './steps'
import { SessionContext } from "../../App";

const AdminCompanyFormPage = () => {

    const { users: _users, company: _company, branch } = useContext(SessionContext)

    const params = useParams()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [company, setCompany] = useState({})
    const [staffId, setStaffId] = useState()

    useEffect(async () => {

        if (_company.id) {

            await getUserRole().then(async (role) => {

                if (role != 'MYEG_ADMIN') history.goBack()
            })

        }
    }, [company])

    useEffect(() => {

        if (params.mode == 'edit') setIsEdit(true)
        else setIsEdit(false)

    }, [params])

    useEffect(() => {
        const token = getToken()
        if (token == null) return
        if (params.mode == 'edit' && !history?.location?.state?.staffId) history.goBack()

    }, [params])

    useEffect(() => {

        if (params.mode == 'view' && !history.location?.state?.companyInfo) history.goBack()

    }, [params])

    useEffect(() => {
        setCompany(history.location?.state?.companyInfo)
    }, [history.location?.state?.companyInfo])

    useEffect(() => {
        setStaffId(history.location?.state?.staffId)
    }, [history.location?.state?.staffId])

    const COMPONENTS = [
        {
            title: 'Admin Company View',
            label: STEPS.ADMIN_COMPANY_USER_VIEW.label,
            url: '/adminCompany/view/',
            component: <ViewPage
                company={company}
            />
        },
        {
            title: 'Admin Company Add',
            label: STEPS.AUTHORIZE_ADMIN_COMPANY_USER.label,
            url: '/adminCompany/add/',
            component: <AuthorizeUser
                subStep={history.location?.state?.subStep}
                company={company}
                staffId={staffId}
            />
        },
        {
            title: 'Admin Company Edit',
            label: STEPS.AUTHORIZE_ADMIN_COMPANY_USER.label,
            url: '/adminCompany/edit/',
            component: <AuthorizeUser
                subStep={history.location?.state?.subStep}
                company={company}
                staffInfo={staffId}
            />
        }
    ]

    // const { user } = useContext(SessionContext);

    // const location = useLocation()
    // const params = useParams()
    // // /usermanagement/edit/:id
    // const isEdit = params.id ? true : false

    // const [branchUser, setBranchUser] = useState({
    //     address1: '',
    //     address2: '',
    //     address3: '',
    //     dob: '',
    //     birthplace: '',
    //     citizenship: '',
    //     city: "",
    //     confirmEmail: "",
    //     email: '',
    //     gender: '',
    //     id: 0,
    //     mobileNo: '',
    //     personName: '',
    //     nricNo: '',
    //     identificationNo: '',
    //     phoneNo: '',
    //     postcode: '',
    //     race: '',
    //     religion: '',
    //     state: '',
    //     roles: [],
    // })

    // /**
    //  * Explanation:
    //  * useMemo essentially memorises the state of whatever that is in return {} bracket. 
    //  * [] denotes the dependency for WHEN a new object should be built and memorised. (just like dependency for useEffect)
    //  * In this case: any update to mobileNo/email/identificationNo should return a new branchUser object and memorise it
    //  */
    // const branchUserMemo = useMemo(() => {
    //     return { ...branchUser };
    // }, [branchUser.mobileNo, branchUser.email, branchUser.identificationNo]);

    // /**
    //  * 27/3/2022 daniel.kwok
    //  * Receiving and transforming data from Sagem
    //  */
    // useEffect(() => {
    //     const urlParams = new URLSearchParams(location.search);
    //     const cached = JSON.parse(getCachedStates())
    //     removeCachedStates()

    //     const decodedValues = decodeURIComponent(urlParams.get('values') || '{}')
    //     const values = JSON.parse(decodedValues)
    //     const decodedForKey = decodeURIComponent(urlParams.get('forKey') || '{}')

    //     if (values['ThumbSucess'] === 'true') {

    //         let stateKey, state, formatted, dob, photo
    //         switch (decodedForKey) {
    //             case 'branchUser':

    //                 const formattedState = values['State']?.toLowerCase().replace(/\s/g, '').replace(/[()]/g, '');
    // state = Object.values(STATES_FULLNAME).find(state => state.label.toLowerCase().replace(/\s/g, '').replace(/[()]/g, '') === formattedState);

    //                 if (user.nric.replace(/\D/g, '') !== values['Ic No.'].replace(/\D/g, '')) {

    //                     message.error('You are not allowed to add yourself as a staff member.')

    //                     setTimeout(() => {
    //                         history.push('/usermanagement')
    //                         // window.location.pathname = "/usermanagement"
    //                     }, 1000);

    //                 } else {

    //                     if (moment(values['Date of Birth'], 'DD/M/YYYY HH').isValid()) {

    //                         dob = moment(values['Date of Birth'], 'DD/M/YYYY HH').format('YYYY-MM-DD')

    //                     } else {

    //                         let ic = values['Ic No.']

    //                         let Year = ic.substring(0, 2)
    // let Month = ic.substring(2, 4)
    // let Day = ic.substring(4, 6)

    // let cutoff = (new Date()).getFullYear() - 2000

    // let fulldob = Day + '/' + Month + '/' + (Year > cutoff ? '19' : '20') + Year

    //                         if (moment(fulldob, 'DD/M/YYYY HH').isValid()) {
    //                             dob = moment(fulldob, 'DD/M/YYYY HH').format('YYYY-MM-DD')
    //                         } else {
    //                             dob = ''
    //                         }
    //                     }

    //                     if (dob == 'Invalid date') dob = ''

    //                     formatted = {
    //                         address1: values['Address 1'],
    //                         address2: values['Address 2'],
    //                         address3: values['Address 3'],
    //                         dob: dob,
    //                         birthplace: values['Place of Birth'],
    //                         citizenship: values['Warganegara'],
    //                         city: values['City'],
    //                         gender: values['Gender'],
    //                         mobileNo: '',
    //                         personName: values['Name'],
    //                         // 27/3/2022 daniel.kwok the model for user is really confusing, especially for nric.
    //                         // multiple keys for "Ic No." is used here as fall back values
    //                         nricNo: values['Ic No.'],
    //                         identificationNo: values['Ic No.'],
    //                         phoneNo: '',
    //                         postcode: values['PostCode'],
    //                         race: values['Race'],
    //                         religion: values['Religion'],
    //                         state: values['State'],
    //                         email: '',
    //                         photo: values['Photo'],
    //                     }

    //                     setBranchUser(formatted)

    //                     history.push(history.location.pathname, {
    //                         ...cached,
    //                         branchUser: formatted,
    //                     })

    //                 }



    //                 break
    //             default:
    //                 break
    //         }

    //     } else if (values['ThumbSucess'] !== 'true' && decodedForKey != '{}') {
    //         message.error('Thumbprint is not sucess')

    //         history.push(history.location.pathname, {
    //             ...history.location.state,
    //             step: STEPS.AUTHORIZE_BRANCH_USER.label,
    //             subStep: STEPS.AUTHORIZE_BRANCH_USER.subSteps.THUMBPRINT_FAILED
    //         })
    //         return
    //     }
    // }, [])

    const component = COMPONENTS.find(step => step.label === (history.location.state && history.location.state.step)) || COMPONENTS[0]

    return (
        <div className='page-content'>
            <Breadcrumb separator=">" style={{ marginTop: "0px", visibility: "hidden" }}>
                {/* <Breadcrumb separator=">" style={{ marginTop: isEdit ? "60px" : "0px", visibility: isEdit ? "visible" : "hidden" }}> */}
                <Breadcrumb.Item href='/'>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item href='/usermanagement'>User management</Breadcrumb.Item>
                <Breadcrumb.Item >Edit user details</Breadcrumb.Item>
            </Breadcrumb>
            {
                !isEdit ? null : <PageHeader
                    className="site-page-header"
                    style={{
                        color: 'white',
                        paddingLeft: "0px"
                    }}
                    title={isEdit == true ? 'Edit User' : 'Add Staff'}
                />
            }


            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >

                {
                    isLoading ? <Skeleton active /> : component?.component
                }

            </div>
        </div>
    )
}

export default AdminCompanyFormPage