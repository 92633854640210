import { PageHeader, Card, Collapse, Button, Image, Breadcrumb } from "antd";
import { useHistory } from "react-router-dom";
import "./index.less";
import arrow_back from "../../assets/arrow_back.svg";
import React, { useContext, useEffect, useState } from "react";
import { SessionContext } from "../../App";
import {
	getPermissions
} from '../../services/local';

export default function ReportingPage() {
	const history = useHistory();
	const { company } = useContext(SessionContext);
	const [permissions, setPermissions] = useState([]);
	useEffect(async () => {
		if (company.id) {
		  await getPermissions().then(async (permissions) => {
			setPermissions(permissions);
		  });
		}
	  }, [company]);

	return (
		<>
			<div style={{ padding: "0px 50px" }}>
				<Breadcrumb separator=">" style={{marginTop: "60px"}}>
					<Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
					<Breadcrumb.Item href="/reporting">Reporting</Breadcrumb.Item>
				</Breadcrumb>
				<PageHeader
					className="site-page-header"
					style={{
						color: "white",
						paddingLeft: "0px",
					}}
					title={"Reporting"}
				/>

				<Card style={{ borderRadius: "8px", boxShadow: "0px 3px 6px #00000029" }}>
					<div
						style={{
							display: "flex",
							flexWrap: "wrap",
							flexDirection: "row",
							gap: 20,
							justifyContent: "center",
							alignItems: "center",
							margin: "88px 0px",
						}}
					>
						{[
							{
								description: "Re-Print Slip",
								disabled: !permissions.transactionReport,
								onClick: () => {
									history.push("/reporting/transferreport");
								},
							},
							{
								description: "e-Statement",
								disabled: !permissions.paymentReport,
								onClick: () => {
									history.push("/reporting/paymentreport");
								},
							},
							{
								description: "APT",
								disabled: !permissions.auditReport,
								onClick: () => {
									history.push("/reporting/audittraillog");
								},
							},
							{
								description: "Vehicle Movement Report",
								disabled: !permissions.vehicleMovementReport,
								onClick: () => {
									history.push("/reporting/vehiclemovementreport");
								},
							},
							{
								description: "Inventory Listing",
								disabled: !permissions.inventoryReport,
								onClick: () => {
									history.push("/reporting/inventorylisting");
								},
							},
						].map((item, j) => {
							return (
								!item.disabled && <Card
									className={item.disabled ? null : "ant-card-hover dashboard-card"}
									key={j}
									onClick={item.onClick}
									disabled={item.disabled}
									style={item.disabled ? styles.reportCardDisabled : styles.reportCard}
								>
									<p style={{ fontWeight: "bold", fontSize: "16px", margin: "0px" }}>
										{item.description}
									</p>
								</Card>
							);
						})}
					</div>
				</Card>
				<div
					style={{
						width: "100%",
						display: "flex",
						justifyContent: "center",
						marginTop: 40,
					}}
				>
					<Button
						style={{
							border: "1px solid #2B61C4",
							color: "#2B61C4",
							padding: "15px",
							height: "fit-content",
							minWidth: "160px",
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            fontSize: "16px",
                            fontWeight: "bold",
							background: "transparent"
						}}
						shape="round"
						onClick={() => {
							history.push("/");
						}}
					>
						<Image src={arrow_back} preview={false}/>
						BACK
					</Button>
				</div>
			</div>
			<style>
				{`
                    .ant-page-header-heading-title{ 
                        font: normal normal 600 32px/37px Raleway;
                    }
                `}
			</style>
		</>
	);
}

const styles = {
	reportCard: {
		width: "15%",
		minWidth: 100,
		height: "40%",
		minHeight: 100,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		background: "#EEEEEE 0% 0% no-repeat padding-box",
	},
	reportCardDisabled: {
		opacity: 0.5,
		width: "15%",
		minWidth: 100,
		height: "40%",
		minHeight: 100,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
};
