import { Button, Card, Col, Image, message, Row, Skeleton } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import successIcon from "../../assets/icon-success.svg";
import { useHistory } from 'react-router-dom';
import { getInTransferByTransferId } from '../../services/api';
import { PropsFromChildren } from '.';
import { GetInTransferDetailRequest, GetInTransferDetailResponse } from '../../services/api/types';
import { parseAddress, parseTime, getDistrictName } from '../../util';
import { SessionContext } from '../../App';
import { STATES } from '../../constants';

type SellerApprovalProps = {
    transferId: number
    isApprover: boolean
    next: (data?: PropsFromChildren) => void
}

export default function SellerApproval(props: SellerApprovalProps) {
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const [transaction, setTransaction] = useState<GetInTransferDetailResponse | null>(null)
    const { user } = useContext(SessionContext)

    const [sellerDistrictName, setSellerDistrictName] = useState('')
    const [buyerDistrictName, setBuyerDistrictName] = useState('')

    useEffect(() => {
        async function getAndSetTransfer() {
            setIsLoading(true)
            try {
                const getInTransferDetailRequest: GetInTransferDetailRequest = {
                    transferId: props.transferId, "uId" : "", companyId: 0, moduleType : "M2M"
                }
                const transferRes = await getInTransferByTransferId(getInTransferDetailRequest)
                if (transferRes.id === undefined) throw transferRes.message
                setTransaction(transferRes)

                if (transferRes.buyerInfo?.state && transferRes.buyerInfo?.district) {

                    getDistrictName(
                        transferRes.buyerInfo?.state,
                        transferRes.buyerInfo?.district
                    ).then((res) => {
                        setBuyerDistrictName(res)
                    })

                }

                if (transferRes.sellerInfo?.state && transferRes.sellerInfo?.district) {

                    getDistrictName(
                        transferRes.sellerInfo?.state,
                        transferRes.sellerInfo?.district
                    ).then((res) => {
                        setSellerDistrictName(res)
                    })

                }


            } catch (error) {
                message.error(error)
            } finally {
                setIsLoading(false)
            }
        }

        getAndSetTransfer()
    }, [props.transferId])

    return (
        isLoading
            ? <Skeleton active />
            : (transaction &&
                <>
                    <Card className='section-card section-card-left'>
                        <div className="header-icon-container">
                            <Image src={successIcon} alt="Success icon" preview={false} style={{ paddingRight: '20px' }} />
                            <div>
                                <div className="header-success">JPJ checking completed</div>
                                <div>Data sent to buyer for payment process</div>
                            </div>
                        </div>
                    </Card>

                    {isLoading
                        ? <Skeleton active />
                        : (
                            <>
                                <Card className='section-card section-card-left'>
                                    <h2 className='section-header'>Vehicle Information</h2>
                                    <Row>
                                        <Col span={8}>
                                            <div className='detail-wrapper'>
                                                <span>Vehicle Number</span>
                                                <p>{transaction.carRegistrationNo}</p>
                                            </div>
                                            {/* <div className='detail-wrapper'>
                                                <span>Manufacture Year</span>
                                                <p>{transaction.manufactureYear}</p>
                                            </div> */}
                                        </Col>
                                        <Col span={8}>
                                            {/* <div className='detail-wrapper'>
                                                <span>Make</span>
                                                <p>{transaction.make}</p>
                                            </div> */}
                                            <div className='detail-wrapper'>
                                                <span>Engine Number</span>
                                                <p>{transaction.engineNo}</p>
                                            </div>
                                        </Col>
                                        <Col span={8}>
                                            {/* <div className='detail-wrapper'>
                                                <span>Model</span>
                                                <p>{transaction.model}</p>
                                            </div> */}
                                            <div className='detail-wrapper'>
                                                <span>Chassis Number</span>
                                                <p>{transaction.chassisNo}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>

                                <Card className='section-card section-card-left'>
                                    <h2 className='section-header'>JPJ Enquiry</h2>
                                    <Row>
                                        <Col span={8}>
                                            <div className='detail-wrapper'>
                                                <span>Blacklisted</span>
                                                <p>No</p>
                                            </div>
                                        </Col>
                                        <Col span={8}>
                                            <div className='detail-wrapper'>
                                                <span>Ownership Claim</span>
                                                <p>No</p>
                                            </div>
                                        </Col>
                                        <Col span={8}>
                                            <div className='detail-wrapper'>
                                                <span>Condition Code</span>
                                                <p>No</p>
                                            </div>
                                        </Col>
                                    </Row>
                                    {/* <Row>
                                        <div className='detail-wrapper'>
                                            <span>Enquiry History</span>
                                            <p>No</p>
                                        </div>
                                    </Row> */}
                                    <div className='detail-label'>
                                        Valid as at {parseTime(transaction.createdDate)}
                                    </div>
                                </Card>

                                <Row gutter={[20, 0]} style={{ marginTop: '-20px' }}>
                                    <Col span={12}>
                                        <Card className='section-card section-card-left'>
                                            <h2 className='section-header'>Dealer's Detail</h2>
                                            <div className='detail-wrapper'>
                                                <span>Company Name</span>
                                                <p>{transaction.sellerInfo.name}</p>
                                            </div>
                                            <div className='detail-wrapper'>
                                                <span>Company ROC Number</span>
                                                <p>{transaction.sellerInfo.identificationNo}</p>
                                            </div>
                                            <div className='detail-wrapper'>
                                                <span>Company Address</span>
                                                <p>{parseAddress({ ...transaction.sellerInfo, state: Object.values(STATES).find(s => s.code === transaction?.sellerInfo?.state)?.label, districtName: sellerDistrictName })}</p>
                                            </div>
                                            <div className='detail-wrapper'>
                                                <span>Company E-mail</span>
                                                <p>{transaction.sellerInfo.email}</p>
                                            </div>
                                            <div className='detail-wrapper'>
                                                <span>Company Phone Number</span>
                                                <p>{transaction.sellerInfo.phoneNo}</p>
                                            </div>
                                            <div className='detail-wrapper'>
                                                <span>Dealer Representative</span>
                                                <p>{transaction.sellerInfo.repName ?? '-'}</p>
                                            </div>
                                            <div className='detail-wrapper'>
                                                <span>Dealer Representative's NRIC</span>
                                                <p>{transaction.sellerInfo.repIc ?? '-'}</p>
                                            </div>
                                            <div className='detail-wrapper'>
                                                <span>Dealer Representative's Mobile Number</span>
                                                <p>{user.mobileno || '-'}</p>
                                            </div>
                                        </Card>
                                    </Col>
                                    <Col span={12}>
                                        <Card className='section-card section-card-left'>
                                            <h2 className='section-header'>Buyer's Detail</h2>
                                            <div className='detail-wrapper'>
                                                <span>Company Name</span>
                                                <p>{transaction.buyerInfo.name}</p>
                                            </div>
                                            <div className='detail-wrapper'>
                                                <span>Company ROC Number</span>
                                                <p>{transaction.buyerInfo.identificationNo}</p>
                                            </div>
                                            <div className='detail-wrapper'>
                                                <span>Company Address</span>
                                                <p>{parseAddress({ ...transaction.buyerInfo, state: Object.values(STATES).find(s => s.code === transaction?.buyerInfo?.state)?.label, districtName: buyerDistrictName })}</p>
                                            </div>
                                            <div className='detail-wrapper'>
                                                <span>Company E-mail</span>
                                                {/* 
                                                    From BE:
                                                    correspondentEmail refers to company email
                                                    email refers to representative email 
                                                */}
                                                <p>{transaction.buyerInfo.correspondentEmail || 'N/A'}</p>
                                            </div>
                                            <div className='detail-wrapper'>
                                                <span>Company Phone Number</span>
                                                {/* 
                                                    From BE:
                                                    correspondentMobileNo refers to company phone number
                                                    phoneNo refers to representative representative mobile number 
                                                */}
                                                <p>{transaction.buyerInfo.correspondentMobileNo || 'N/A'}</p>
                                            </div>
                                            <div className='detail-wrapper'>
                                                <span>Dealer Representative</span>
                                                <p>{transaction.buyerInfo.repName || 'N/A'}</p>
                                            </div>
                                            <div className='detail-wrapper'>
                                                <span>Dealer Representative's NRIC</span>
                                                <p>{transaction.buyerInfo.repIc || 'N/A'}</p>
                                            </div>
                                            <div className='detail-wrapper'>
                                                <span>Dealer Representative's Mobile Number</span>
                                                <p>{transaction.buyerInfo.phoneNo || 'N/A'}</p>
                                            </div>
                                        </Card>
                                    </Col>
                                </Row>

                                <div className='section-bottom-button'>
                                    <Button className='rounded-button-primary' onClick={() => history.push('/')}>Home</Button>
                                </div>
                            </>
                        )
                    }
                </>
            )
    )
}
