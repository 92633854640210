import { Steps, Space, message, Card } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { STEPS } from './steps'
import { SessionContext } from '../../App'
import AuthorizePerson from './authorizePerson'
import VehicleInformation from './vehicleInformation'
import AuthorizeBuyer from './authorizeBuyer'
import JPJSubmission from './jpjSubmission'
import Payment from './payment'
import TransactionType from './transactionType'
import {
    getAuthorizedNric,
    getCachedStates,
    removeCachedStates,
    setAuthorizedNric,
    setCachedBuyerType,
    getCachedBuyerType,
    removeCachedBuyerType,
    getSagemUrlForKey,
    removeSagemUrlForKey,
    getCompanyId,
} from '../../services/local'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { STATES, OUT_TRANSACTION_TYPES, OUT_TRANSACTION_TYPES_IDS, OUT_TRANSACTION_STATUSES, STATES_FULLNAME } from '../../constants'
import AuthorizeSeller from './authorizeSeller'
import './index.less'
import PrintSlip from './printSlip'
import moment from 'moment'
import { getOutTransferByTransferId, getAccountDetails, isAccountExist } from '../../services/api'
import {
    getState
} from '../../util'
import {skipAuth, decodeForSagem, decryptUrlVerify} from '../../components/Utils'


export default function CreateOutTransferPage() {
    const history = useHistory()
    const location = useLocation()
    const params = useParams()
    const [from, setFrom] = useState('Dealer')
    const [to, setTo] = useState('Buyer')
    const { user } = useContext(SessionContext)
    const [dealer, setDealer] = useState({
        "address1": "",
        "address2": "",
        "addres3": "",
        "district": "",
        "state": "",
        "postcode": "",
        "dob": "",
        "correspondentEmail": "",
        "gender": "",
        "correspondentMobileNo": null,
        "name": "",
        "nric": "",
        "phoneno": "",
        "username": ""
    })
    const [transactionType, setTransactionType] = useState(OUT_TRANSACTION_TYPES_IDS.D2I)
    const [vehicle, setVehicle] = useState(
        {
            "carMake": "",
            "carModel": "",
            "chassisNo": "",
            "engineNo": "",
            "manufactureYear": "",
            "ownerIdCat": "",
            "vehicleRegistrationNo": "",
            "vehicleType": "",
            "isWhiteListVehicle": false,    // 28.12.2021 daniel.kwok hardcoded defaults
            "lkmoption": 1,                 // 28.12.2021 daniel.kwok hardcoded defaults

        }
    )
    const [seller, setSeller] = useState({
        "address1": "",
        "address2": "",
        "address3": "",
        "correspondentAddress": "",
        "correspondentEmail": "",
        "correspondentMobileNo": "",
        "dateOfBirth": "",
        "district": "",
        "email": "",
        "gender": "",
        "identificationNo": "",
        "name": "",
        "phoneNo": "",
        "postcode": "",
        "repIc": "",
        "repIc2": "",
        "repName": "",
        "state": "",
        "dob": ""
    })
    const [buyer, setBuyer] = useState({
        "address1": "",
        "address2": "",
        "address3": "",
        "dateOfBirth": "",
        "district": "",
        "email": "",
        "gender": "",
        "identificationNo": "",
        "name": "",
        "phoneNo": "",
        "postcode": "",
        "state": "",
        "citizenship": "",
    })
    const [approverNric, setApproverNric] = useState(null)
    const [isUnknownTransfer, setIsUnknownTransfer] = useState(false)
    const tabType = history.location.state?.tabType ?? "1"

    window.historyProps = history

    useEffect(() => {

        const STATUSES_REDIRECT_TO_JPJ_SUBMISSION = [
            OUT_TRANSACTION_STATUSES.SELLER_INQUIRY_FAILED,
            OUT_TRANSACTION_STATUSES.BUYER_INQUIRY_FAILED,
            OUT_TRANSACTION_STATUSES.UPDATE_TO_JPJ_FAILED,
            OUT_TRANSACTION_STATUSES.PENDING_BUYER_MYSIKAP,
            OUT_TRANSACTION_STATUSES.PENDING_SELLER_MYSIKAP,
            OUT_TRANSACTION_STATUSES.PENDING_BUYER_AND_SELLER_MYSIKAP,
        ]

        const urlParams = new URLSearchParams(location.search);
        // When sagem redirect back, prevent it from re-executing below logic
        // so that it goes to sagem data receiver useEffect
        if (urlParams.get('forKey') == null) {

            if (location?.state?.fromAdminPage === true) {//for adminpage view

                if (params.mode == 'vehicleinfo') {

                    getOutTransferByTransferId(location?.state?.transferId, "OUT", getCompanyId()).then((res) => {

                        if (res.id === undefined) throw res.message

                        if (params.mode === 'vehicleinfo') {


                            let vt = {
                                "carMake": res?.make,
                                "carModel": res?.model,
                                "chassisNo": res?.chassisNo,
                                "engineNo": res?.engineNo,
                                "manufactureYear": res?.manufactureYear,
                                "ownerIdCat": res.ownerCatagory,
                                "vehicleRegistrationNo": res?.carRegistrationNo,
                                "vehicleType": res?.vehicleTypeId,
                                "isWhiteListVehicle": false,
                                "lkmoption": 1,
                            }

                            history.push(history.location.pathname, {
                                ...history.location.state,
                                vehicle: vt,
                                transactionType: res.transactionType,
                                step: STEPS.VEHICLE_INFORMATION.label,
                                subStep: STEPS.VEHICLE_INFORMATION.subSteps.UPDATE_VEHICLE_INFO
                            })

                        }

                    }).catch(err => {
                        err && message.error(err)
                        setIsUnknownTransfer(true)
                    })

                } else {

                    history.push(history.location.pathname, {
                        ...history.location.state,
                        step: STEPS.PRINT_SLIP.label,
                    })

                }

            }
            else if (params.transferId) {

                const _transferId = Number(params.transferId)

                getOutTransferByTransferId(_transferId, "OUT", getCompanyId()).then((res) => {

                    if (res.id === undefined) throw res.message

                    if (params.mode === 'vehicleinfo') {


                        let vt = {
                            "carMake": res?.make,
                            "carModel": res?.model,
                            "chassisNo": res?.chassisNo,
                            "engineNo": res?.engineNo,
                            "manufactureYear": res?.manufactureYear,
                            "ownerIdCat": res.ownerCatagory,
                            "vehicleRegistrationNo": res?.carRegistrationNo,
                            "vehicleType": res?.vehicleTypeId,
                            "isWhiteListVehicle": false,
                            "lkmoption": 1,
                        }

                        history.push(history.location.pathname, {
                            ...history.location.state,
                            vehicle: vt,
                            transactionType: res.transactionType,
                            step: STEPS.VEHICLE_INFORMATION.label,
                            subStep: STEPS.VEHICLE_INFORMATION.subSteps.UPDATE_VEHICLE_INFO
                        })

                    } else {

                        if (STATUSES_REDIRECT_TO_JPJ_SUBMISSION.includes(res.status)) {

                            history.push(history.location.pathname, {
                                ...history.location.state,
                                step: STEPS.JPJ_SUBMISSION.label,
                                subStep: STEPS.JPJ_SUBMISSION.subSteps.REVIEW,
                                transferId: res.id,
                            })

                        } else if (res.status === OUT_TRANSACTION_STATUSES.PENDING_SELLER_INFO) {
                            history.push(history.location.pathname, {
                                ...history.location.state,
                                step: STEPS.AUTHORIZE_SELLER.label,
                                subStep: STEPS.AUTHORIZE_SELLER.subSteps.INSERT_IC,
                                transferId: res.id,
                            })
                        } else if (res.status === OUT_TRANSACTION_STATUSES.PENDING_BUYER_INFO) {
                            history.push(history.location.pathname, {
                                ...history.location.state,
                                step: STEPS.AUTHORIZE_BUYER.label,
                                subStep: STEPS.AUTHORIZE_BUYER.subSteps.INSERT_IC,
                                transferId: res.id,
                            })
                        } else {
                            history.push(history.location.pathname, {
                                ...history.location.state,
                                step: STEPS.JPJ_SUBMISSION.label,
                                subStep: STEPS.JPJ_SUBMISSION.subSteps.REVIEW,
                                transferId: res.id,
                            })
                        }

                    }

                }).catch(err => {
                    err && message.error(err)
                    setIsUnknownTransfer(true)
                })
            } else {
                /**
                 * Check if user is already authorized for this session.
                 * If no, need re-authorize
                 * Else, redirect to next step
                 */
                const authorizedNric = getAuthorizedNric()
                if (authorizedNric === user?.nric) {
                    if (
                        !history.location.state?.step
                        && params.mode !== 'payment' && params.mode !== 'printslip'
                    ) {
                        history.push(history.location.pathname, {
                            ...history.location.state,
                            step: STEPS.TRANSACTION_TYPE.label,
                            subStep: STEPS.TRANSACTION_TYPE.subSteps.TRANSACTION_TYPE
                        })
                    } else if (params.mode === 'payment') {
                        history.push(history.location.pathname, {
                            ...history.location.state,
                            step: STEPS.PAYMENT.label,
                        })
                    } else if (params.mode === 'printslip') {
                        history.push(history.location.pathname, {
                            ...history.location.state,
                            step: STEPS.PRINT_SLIP.label,
                        })
                    }
                } else {
                    history.push(history.location.pathname, {
                        ...history.location.state,
                        step: STEPS.AUTHORIZE_DEALER.label,
                        subStep: STEPS.AUTHORIZE_DEALER.subSteps.INSERT_IC
                    })
                }
            }
        }
    }, [])

    /**
     * 23/2/2022 daniel.kwok
     * Receing and transforming data from Sagem
     */
    useEffect(async () => {
        const urlParams = new URLSearchParams(location.search);

        if (urlParams.get('forKey') != null) {

            if (getSagemUrlForKey() !== urlParams.get('forKey')) {

                removeSagemUrlForKey()

                history.push(history.location.pathname, {
                    ...history.location.state,
                    step: STEPS.TRANSACTION_TYPE.label,
                    subStep: STEPS.TRANSACTION_TYPE.subSteps.TRANSACTION_TYPE
                })

                return

            }

            let values;
            let decodedValues;
            const decodedForKey = decodeURIComponent(urlParams.get('forKey'))
            const cached = JSON.parse(getCachedStates())
            const isSkipAuth = skipAuth(false, null, null, urlParams, user?.nric)
            if (isSkipAuth) {
                values = isSkipAuth
            }
            else {
                if (urlParams.get('tk') != null && urlParams.get('dt') != null) {
                    decodedValues = await decodeForSagem(
                        decodeURIComponent(urlParams.get('tk')), 
                        decodeURIComponent(urlParams.get('dt')),
                        urlParams.get('forKey'),
                        history,
                        STEPS,
                        "OUT")
                    if (decodedValues === undefined) {
                        message.error("Please try again.")
                        removeSagemUrlForKey()
                        history.push(history.location.pathname, {
                            ...history.location.state,
                            step: STEPS.TRANSACTION_TYPE.label,
                            subStep: STEPS.TRANSACTION_TYPE.subSteps.TRANSACTION_TYPE
                        })
                        return
                    }
                }
                else {
                    message.error("Please try again.")
                    removeSagemUrlForKey()
                    history.push(history.location.pathname, {
                        ...history.location.state,
                        step: STEPS.TRANSACTION_TYPE.label,
                        subStep: STEPS.TRANSACTION_TYPE.subSteps.TRANSACTION_TYPE
                    })
                    return
                }
                removeCachedStates()

                // const decodedValues = decodeURIComponent(urlParams.get('values') || '{}')
                values = JSON.parse(decodedValues)

                const decryptResponse = await decryptUrlVerify(values,
                    urlParams.get('tk'),
                    decodedForKey,
                    getCompanyId(),
                    "SAGEM",
                    user.nric.replace(/[\s*]/g, ""));
                if(decryptResponse === false) {
                    removeSagemUrlForKey()
                    if (user.nric.replace(/\D/g, '') !== values['Ic No.'].replace(/\D/g, '')) {
                        history.push(history.location.pathname, {
                            step: STEPS.AUTHORIZE_DEALER.label,
                            subStep: STEPS.AUTHORIZE_DEALER.subSteps.AUTHORIZE_FAILED
                        })
                        return
                    }
                    history.push(history.location.pathname, {
                        ...history.location.state,
                        step: STEPS.TRANSACTION_TYPE.label,
                        subStep: STEPS.TRANSACTION_TYPE.subSteps.TRANSACTION_TYPE
                    })
                    return
                }
            }

            if (values['ThumbSucess'] === 'true') {

                let errorMessages = [];

                if (!values['Name']) {
                    errorMessages.push("Failed to read the Name from MyKad. The Name read as '" + values['Name'] + "'. Please contact the eTukar Admin for more information.");
                }

                // if (!values['City']) {
                //     errorMessages.push("Failed to read the District from MyKad. The District read as '" + values['City'] + "'. Please contact the eTukar Admin for more information.");
                // }

                if (!values['Ic No.'] || !/^[+]?\d+([.]\d+)?[*]?$/.test(values['Ic No.'])) {
                    errorMessages.push("Failed to read the IC No from MyKad. The IC No. read as '" + values['Ic No.'] + "'. Please contact the eTukar Admin for more information.");
                }

                if (errorMessages.length > 0) {
                    for (let errorMessage of errorMessages) {
                        message.error(errorMessage);
                    }
                    if (decodedForKey == 'dealer') {
                        history.push(history.location.pathname, {
                            ...history.location.state,
                            step: STEPS.AUTHORIZE_DEALER.label,
                            subStep: STEPS.AUTHORIZE_DEALER.subSteps.INSERT_IC
                        })
                    } else if (decodedForKey == 'seller') {
                        history.push(history.location.pathname, {
                            step: STEPS.AUTHORIZE_BUYER.label,
                            subStep: STEPS.AUTHORIZE_BUYER.subSteps.INSERT_IC
                        })
                    } if (decodedForKey == 'buyer') {

                        history.push(history.location.pathname, {
                            step: STEPS.TRANSACTION_TYPE.label,
                            subStep: STEPS.TRANSACTION_TYPE.subSteps.TRANSACTION_TYPE
                        })
                    }

                    return;
                }

                history.push(history.location.pathname, {
                    ...history.location.state,
                    ...cached,
                })

                if (cached?.transactionType) {
                    setTransactionType(cached?.transactionType)
                }

                let stateKey, state, formatted, dob, formattedState
                switch (decodedForKey) {
                    case 'dealer':
                        if (user.nric.replace(/\D/g, '') !== values['Ic No.'].replace(/\D/g, '')) {
                            history.push(history.location.pathname, {
                                step: STEPS.AUTHORIZE_DEALER.label,
                                subStep: STEPS.AUTHORIZE_DEALER.subSteps.AUTHORIZE_FAILED
                            })
                            return
                        }

                        formattedState = values['State']?.toLowerCase().replace(/\s/g, '').replace(/[()]/g, '');
                        state = Object.values(STATES_FULLNAME).find(state => state.label.toLowerCase().replace(/\s/g, '').replace(/[()]/g, '') === formattedState);

                        if (!state) state = getState(values['Ic No.'])

                        if (moment(values['Date of Birth'], 'DD/M/YYYY HH').isValid()) {

                            dob = moment(values['Date of Birth'], 'DD/M/YYYY HH').format('YYYY-MM-DD')

                        } else {

                            let ic = values['Ic No.']

                            let Year = ic.substring(0, 2)
                            let Month = ic.substring(2, 4)
                            let Day = ic.substring(4, 6)

                            let cutoff = (new Date()).getFullYear() - 2000

                            let fulldob = Day + '/' + Month + '/' + (Year > cutoff ? '19' : '20') + Year

                            if (moment(fulldob, 'DD/M/YYYY HH').isValid()) {
                                dob = moment(fulldob, 'DD/M/YYYY HH').format('YYYY-MM-DD')
                            } else {
                                dob = ''
                            }
                        }

                        if (dob == 'Invalid date') dob = ''

                        formatted = {
                            address1: values['Address 1'].replace(/\0.*$/, ""),
                            address2: values['Address 2'].replace(/\0.*$/, ""),
                            address3: values['Address 3'].replace(/\0.*$/, ""),
                            district: values['City'].replace(/[^a-zA-Z\s]/g, ''),
                            state: state?.code,
                            postcode: values['PostCode'],
                            dob: dob,
                            correspondentEmail: '',
                            gender: values['Gender'] === 'Male' ? 'L' : 'P',
                            correspondentMobileNo: '',
                            "correspondentAddress": "",
                            name: values['Name'].replace(/\*/g, ''),
                            nric: values['Ic No.'],
                            phoneno: '',
                            username: '',
                            photo: values['Photo']
                        }

                        setDealer(formatted)
                        setAuthorizedNric(values['Ic No.'])

                        // history.push(history.location.pathname, {
                        //     ...history.location.state,
                        //     dealer: formatted
                        // })

                        history.push(history.location.pathname, {
                            ...history.location.state,
                            dealer: formatted,
                            ...cached,
                            step: STEPS.TRANSACTION_TYPE.label,
                            subStep: STEPS.TRANSACTION_TYPE.subSteps.TRANSACTION_TYPE,
                        })

                        break
                    case 'seller':

                        formattedState = values['State']?.toLowerCase().replace(/\s/g, '').replace(/[()]/g, '');
                        state = Object.values(STATES_FULLNAME).find(state => state.label.toLowerCase().replace(/\s/g, '').replace(/[()]/g, '') === formattedState);

                        if (!state) state = getState(values['Ic No.'])

                        if (moment(values['Date of Birth'], 'DD/M/YYYY HH').isValid()) {

                            dob = moment(values['Date of Birth'], 'DD/M/YYYY HH').format('YYYY-MM-DD')

                        } else {

                            let ic = values['Ic No.']

                            let Year = ic.substring(0, 2)
                            let Month = ic.substring(2, 4)
                            let Day = ic.substring(4, 6)

                            let cutoff = (new Date()).getFullYear() - 2000

                            let fulldob = Day + '/' + Month + '/' + (Year > cutoff ? '19' : '20') + Year

                            if (moment(fulldob, 'DD/M/YYYY HH').isValid()) {
                                dob = moment(fulldob, 'DD/M/YYYY HH').format('YYYY-MM-DD')
                            } else {
                                dob = ''
                            }
                        }

                        if (dob == 'Invalid date') dob = ''

                        formatted = {
                            address1: values['Address 1'].replace(/\0.*$/, ""),
                            address2: values['Address 2'].replace(/\0.*$/, ""),
                            address3: values['Address 3'].replace(/\0.*$/, ""),
                            correspondentAddress: '',
                            correspondentEmail: '',
                            correspondentMobileNo: '',
                            dateOfBirth: dob,
                            district: values['City'].replace(/[^a-zA-Z\s]/g, ''),
                            email: '',
                            gender: values['Gender'] === 'Male' ? 'L' : 'P',
                            identificationNo: values['Ic No.'].replace(/[\s*]/g, ""),
                            name: values['Name'].replace(/\*/g, ''),
                            phoneNo: '',
                            postcode: values['PostCode'],
                            "repIc": "",
                            "repIc2": "",
                            "repName": "",
                            state: state?.code,
                            dob: dob,
                            photo: values['Photo']
                        }

                        setSeller(formatted)
                        history.push(history.location.pathname, {
                            ...history.location.state,
                            seller: formatted
                        })

                        break
                    case 'buyer':

                        formattedState = values['State']?.toLowerCase().replace(/\s/g, '').replace(/[()]/g, '');
                        state = Object.values(STATES_FULLNAME).find(state => state.label.toLowerCase().replace(/\s/g, '').replace(/[()]/g, '') === formattedState);

                        if (!state) state = getState(values['Ic No.'])

                        if (moment(values['Date of Birth'], 'DD/M/YYYY HH').isValid()) {

                            dob = moment(values['Date of Birth'], 'DD/M/YYYY HH').format('YYYY-MM-DD')

                        } else {

                            let ic = values['Ic No.']

                            let Year = ic.substring(0, 2)
                            let Month = ic.substring(2, 4)
                            let Day = ic.substring(4, 6)

                            let cutoff = (new Date()).getFullYear() - 2000

                            let fulldob = Day + '/' + Month + '/' + (Year > cutoff ? '19' : '20') + Year

                            if (moment(fulldob, 'DD/M/YYYY HH').isValid()) {
                                dob = moment(fulldob, 'DD/M/YYYY HH').format('YYYY-MM-DD')
                            } else {
                                dob = ''
                            }
                        }

                        if (dob == 'Invalid date') dob = ''

                        let mobileNo
                        let _email

                        isAccountExist(values['Ic No.'].replace(/[\s*]/g, ""))
                            .then(res => {

                                if (!res.status) {
                                    return {
                                        status: false
                                    }
                                } else {
                                    return getAccountDetails(values['Ic No.'].replace(/[\s*]/g, ""))
                                }

                            })
                            .then(res => {

                                if (res.status == false) {
                                    mobileNo = ''
                                    _email = ''
                                } else {

                                    const { email, mobileno } = res

                                    mobileNo = mobileno
                                    _email = email

                                }

                            })
                            .catch(err => {
                                err && message.error(err)
                            }).finally(() => {
                                
                                formatted = {
                                    address1: values['Address 1'].replace(/\0.*$/, ""),
                                    address2: values['Address 2'].replace(/\0.*$/, ""),
                                    address3: values['Address 3'].replace(/\0.*$/, ""),
                                    dateOfBirth: dob,
                                    city: values['City'].replace(/[^a-zA-Z0-9\s\/-]/g, ''),
                                    district: values['City'].replace(/[^a-zA-Z0-9\s\/-]/g, ''),
                                    email: _email,
                                    mobileNo: mobileNo,
                                    gender: values['Gender'] === 'Male' ? 'L' : 'P',
                                    identificationNo: values['Ic No.'].replace(/[\s*]/g, ""),
                                    name: values['Name'].replace(/\*/g, ''),
                                    phoneNo: '',
                                    postcode: values['PostCode'],
                                    state: state?.code,
                                    photo: values['Photo'],
                                    buyerType: getCachedBuyerType() != '' && Number(getCachedBuyerType()),
                                    citizenship: values['Warganegara']
                                }

                                setBuyer(formatted)

                                removeCachedBuyerType()

                                history.push(history.location.pathname, {
                                    ...history.location.state,
                                    buyer: formatted
                                })

                            })

                        break
                    default:
                        break
                }

            } else if (values['ThumbSucess'] !== 'true' && decodedForKey != '{}') {
                message.error('Thumbprint is not sucess')

                if (decodedForKey == 'dealer') {
                    history.push(history.location.pathname, {
                        step: STEPS.AUTHORIZE_DEALER.label,
                        subStep: STEPS.AUTHORIZE_DEALER.subSteps.THUMBPRINT_FAILED
                    })
                    return
                } else if (decodedForKey == 'seller') {
                    history.push(history.location.pathname, {
                        step: STEPS.AUTHORIZE_SELLER.label,
                        subStep: STEPS.AUTHORIZE_SELLER.subSteps.THUMBPRINT_FAILED
                    })
                    return
                } else if (decodedForKey == 'buyer') {
                    history.push(history.location.pathname, {
                        step: STEPS.AUTHORIZE_BUYER.label,
                        subStep: STEPS.AUTHORIZE_BUYER.subSteps.THUMBPRINT_FAILED
                    })
                    return
                }
            }
        }
    }, [])


    /**
     * 9/2/2022 daniel.kwok
     * Currently used by the following transactionTypes
     * I2C
     * 
     * Done this way because foresee the flow might be very customized for diff transactionTypes
     */
    const COMPONENTS_WITH_SELLER = [
        {
            title: 'Buyer Authentication',
            label: STEPS.AUTHORIZE_BUYER.label,
            component: <AuthorizeBuyer
                buyer={history.location.state?.buyer || buyer}
                transactionType={history.location.state?.transactionType || transactionType}
                onChange={_buyer => {
                    history.push(history.location.pathname, {
                        ...history.location.state,
                        buyer: _buyer
                    })
                    setBuyer(_buyer)
                }}
                tabType={tabType}
            />
        },
        {
            title: 'Authorize Seller',
            label: STEPS.AUTHORIZE_SELLER.label,
            component: <AuthorizeSeller
                seller={history.location.state?.seller || seller}
                onChange={_seller => {
                    history.push(history.location.pathname, {
                        ...history.location.state,
                        seller: _seller
                    })
                    setSeller(_seller)
                }}
            />
        },
        {
            title: 'Vehicle Information',
            label: STEPS.VEHICLE_INFORMATION.label,
            url: '/transfer/out/vehicleinfo/',
            component: <VehicleInformation
                buyer={history.location.state?.buyer || buyer}
                dealer={history.location.state && history.location.state.dealer || dealer}
                vehicle={history.location.state && history.location.state.vehicle || vehicle}
                transactionType={history.location.state && history.location.state.transactionType}
                onChange={_vehicle => {

                    history.push(history.location.pathname, {
                        ...history.location.state,
                        vehicle: _vehicle
                    })

                    setSeller(seller)
                    setVehicle(_vehicle)
                }}
                tabType={tabType}
            />
        },
        {
            title: 'JPJ Submission',
            label: STEPS.JPJ_SUBMISSION.label,
            component: <JPJSubmission
                buyer={history.location.state?.buyer || buyer}
                vehicle={history.location.state?.vehicle || vehicle}
                seller={history.location.state?.seller || seller}
                transactionType={history.location.state?.transactionType || transactionType}
                onApproverNRICChange={approverNric => {
                    setApproverNric(approverNric)
                }}
                approverNric={approverNric}
                isReport={history.location.state?.isReport ?? false}
                tabType={tabType}
            />
        },
        /**
         * This step is a little special.
         * Payment page is a separate page, managed by location url instead of location state
         * This assumes that all transactions that's in the payment stage is already created
         */
        {
            title: 'Payment',
            label: STEPS.PAYMENT.label,
            url: '/transfer/out/payment/',
            component: <Payment
                approverNric={approverNric}
                vehicle={vehicle}
                seller={seller}
                dealer={dealer}
                isReport={history.location.state?.isReport ?? false}
                tabType={tabType}
            />
        },
        {
            title: 'Print Slip',
            label: STEPS.PRINT_SLIP.label,
            url: '/transfer/out/printslip/',
            component: <PrintSlip
                isReport={history.location.state?.isReport ?? false}
            />
        }
    ]

    // To navigate between components (a.k.a form steps), we use history.push by
    // pushing to the current path, along with the steps and desired substeps inside the history state.
    // Below is an example to navigate to Authorize Seller (Owner Authentication) step, 
    // and pointing to the Summary substep:
    /* 
    history.push(history.location.pathname, {
        ...locationState,
        step: STEPS.AUTHORIZE_SELLER.label,
        subStep: STEPS.AUTHORIZE_SELLER.subSteps.SUMMARY
    })
    */
    // The ...locationState (history.location.state) was used to carry data from one step to the next
    const COMPONENTS_WITHOUT_SELLER = [
        {
            title: 'Buyer Authentication',
            label: STEPS.AUTHORIZE_BUYER.label,
            component: <AuthorizeBuyer
                buyer={history.location.state?.buyer || buyer}
                transactionType={history.location.state?.transactionType || transactionType}
                onChange={_buyer => {
                    history.push(history.location.pathname, {
                        ...history.location.state,
                        buyer: _buyer
                    })
                    setBuyer(_buyer)
                }}
            />
        },
        {
            title: 'Vehicle Information',
            label: STEPS.VEHICLE_INFORMATION.label,
            url: '/transfer/out/vehicleinfo/',
            component: <VehicleInformation
                buyer={history.location.state?.buyer || buyer}
                dealer={history.location.state && history.location.state.dealer || dealer}
                vehicle={history.location.state && history.location.state.vehicle || vehicle}
                transactionType={history.location.state && history.location.state.transactionType}
                onChange={_vehicle => {

                    history.push(history.location.pathname, {
                        ...history.location.state,
                        vehicle: _vehicle
                    })

                    /**
                     * 31.12.2021 daniel.kwok
                     * seller.sellerType === vehicle.ownerIdCat
                     */
                    seller.sellerType = _vehicle.ownerIdCat

                    setSeller(seller)
                    setVehicle(_vehicle)
                }}
                tabType={tabType}
            />
        },
        {
            title: 'JPJ Submission',
            label: STEPS.JPJ_SUBMISSION.label,
            component: <JPJSubmission
                // The data for this buyer props will be either from locationState or buyer variable.
                // If thumbscan is using SAGEM, every time we do history.push, index.js will reload, 
                // and all variable from useState will be reset to initial state. 
                // Hence we need to take previous step data from locationState.
                // But if using DERMALOG, no need to get buyer from locationState, because buyer data was 
                // already set into buyer variable.
                buyer={history.location.state?.buyer || buyer}
                vehicle={history.location.state?.vehicle || vehicle}
                seller={history.location.state?.seller || seller}
                transactionType={history.location.state?.transactionType || transactionType}
                onApproverNRICChange={approverNric => {
                    setApproverNric(approverNric)
                }}
                approverNric={approverNric}
                isReport={history.location.state?.isReport ?? false}
                tabType={tabType}
            />
        },
        /**
         * This step is a little special.
         * Payment page is a separate page, managed by location url instead of location state
         * This assumes that all transactions that's in the payment stage is already created
         */
        {
            title: 'Payment',
            label: STEPS.PAYMENT.label,
            url: '/transfer/out/payment/',
            component: <Payment
                approverNric={approverNric}
                vehicle={vehicle}
                seller={seller}
                dealer={dealer}
                isReport={history.location.state?.isReport ?? false}
                tabType={tabType}
            />
        },
        {
            title: 'Print Slip',
            label: STEPS.PRINT_SLIP.label,
            url: '/transfer/out/printslip/',
            component: <PrintSlip
                isReport={history.location.state?.isReport ?? false}
            />
        }
    ]

    let COMPONENTS = []
    switch (transactionType) {
        case OUT_TRANSACTION_TYPES_IDS.I2C:
            COMPONENTS = COMPONENTS_WITH_SELLER
            break;
        default:
            COMPONENTS = COMPONENTS_WITHOUT_SELLER
            break
    }

    const component = COMPONENTS.find(step => {
        if (params.mode === 'create') {
            return step.label === (history.location.state?.step)
        } else if (params.mode === 'payment') {
            return step.label === STEPS.PAYMENT.label
        } else if (params.mode === 'printslip') {
            return step.label === STEPS.PRINT_SLIP.label
        } else if (params.mode === 'vehicleinfo') {
            return step.label === STEPS.VEHICLE_INFORMATION.label
        }
    })

    const index = component && COMPONENTS.findIndex(step => step.label === component.label) || 0

    return (
        <div
            style={{
                margin: '40px 80px'
            }}
        >
            <div style={{ textAlign: 'center' }}>
                <Space direction='horizontal'>
                    <h2 className='transaction-title-out'>Out</h2>
                    <h2 style={{ color: '#FFFFFF' }}>-</h2>
                    <h2 className='transaction-type-out'>{OUT_TRANSACTION_TYPES[transactionType] && `${from}` || ``}</h2>
                    <h2 className='transaction-additional-out'>to</h2>
                    <h2 className='transaction-type-out'>{OUT_TRANSACTION_TYPES[transactionType] && `${to}` || ``}</h2>
                </Space>
            </div>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    background: 'transparent'
                }}
            >
                {
                    (
                        location?.state?.fromAdminPage != true
                        && history.location.state?.step !== STEPS.AUTHORIZE_DEALER.label
                        && history.location.state?.step !== STEPS.TRANSACTION_TYPE.label

                    ) ? (

                        <div style={{
                            marginBottom: 40, marginTop: 20
                        }} >
                            <Steps
                                labelPlacement='vertical'
                                style={{ width: '60vw' }}

                                size="default"
                                current={index}
                            >
                                {
                                    COMPONENTS.map(step => {
                                        return <Steps.Step
                                            key={step.title} title={<span style={{ color: 'white', width: '100%' }}>{step.title}</span>}
                                        />
                                    })
                                }
                            </Steps>
                        </div>
                    ) : null
                }

                {
                    history.location.state?.step === STEPS.AUTHORIZE_DEALER.label ? (
                        <AuthorizePerson
                            onAuthorizedSuccess={() => {
                                history.push(history.location.pathname, {
                                    ...history.location.state,
                                    step: STEPS.TRANSACTION_TYPE.label,
                                    subStep: STEPS.TRANSACTION_TYPE.subSteps.TRANSACTION_TYPE
                                })
                            }} />
                    ) : history.location.state?.step === STEPS.TRANSACTION_TYPE.label ? (
                        <TransactionType
                            transactionType={transactionType}
                            onChange={data => {
                                let transactionType = data.transactionType

                                setBuyer({
                                    ...buyer,
                                    buyerType: data.buyerType
                                })
                                setCachedBuyerType(data.buyerType)
                                setVehicle(vehicle)
                                setTransactionType(transactionType)

                                if (transactionType === OUT_TRANSACTION_TYPES_IDS.M2M) {
                                    setFrom('Member')
                                    setTo('Member')
                                    history.push('/transfer/m2m')
                                    return
                                } 
                                // else if (transactionType === OUT_TRANSACTION_TYPES_IDS.M2MV2) {
                                //     setFrom('Member')
                                //     setTo('Member')
                                //     history.push('/transfer/m2mV2')
                                //     return
                                // }
                                else if (transactionType === OUT_TRANSACTION_TYPES_IDS.D2I) {
                                    setFrom('Dealer')
                                    setTo('Buyer')
                                }

                                history.push(history.location.pathname, {
                                    ...history.location.state,
                                    transactionType,
                                    step: STEPS.AUTHORIZE_BUYER.label,
                                    subStep: STEPS.AUTHORIZE_BUYER.subSteps.INSERT_IC
                                })

                            }}
                        />
                    ) : (
                        component?.component
                    )
                }
            </div>

            {isUnknownTransfer && 
            <Card
                style={{
                    height: '50%',
                    width: '100%'
                }}
            >
                <h1>Unknown transfer</h1>
                <a href='/'>Go back to home</a>
            </Card>}
        </div >
    )
}
