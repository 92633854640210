import React, { useContext, useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import {
    Select,
    message,
    Modal,
    Input,
    Button,
    Row,
    Col,
    Radio
} from 'antd'
import './index.less'
import propTypes from 'prop-types'
import {
    sendSmsOtp,
    getAccountDetails,
    getAdminUsersForOut,
    getAdminUsers,
    sendNotification,
    checkTxnApprovalStatus
} from '../../services/api'
import {
    /**
     * 22/1/2022 daniel.kwok
     * Hard coded HQ branch id to be 0
     * Ideally value should be from api
     */
    HQ_BRANCH,
    APP_CONST,
    OUT_TRANSACTION_TYPES_IDS
} from '../../constants'

import { SessionContext } from '../../App'
import { getAuthorizedNric, getUserRole } from '../../services/local'


function PaymentSubmissionModal(props) {

    const { user, company, branch } = useContext(SessionContext)
    const [authCode, setAuthCode] = useState('')
    const [approverIc, setApproverIc] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [adminUsers, setAdminUsers] = useState([])
    const [role, setRole] = useState(null)
    const [userMobileNo, setUserMobileNo] = useState(null)
    const [showApprovalSection, setShowApprovalSection] = useState(true)
    const [smsProvider, setSmsProvider] = useState(1)
    const [isDisabled, setIsDisabled] = useState(false)

    const [minutes, setMinutes] = useState('00')
    const [seconds, setSeconds] = useState('00')
    const [otpTimeout, setOtpTimeout] = useState(0)

    const Ref = useRef(null);

    const params = useParams();
    const { transferId } = params;
    const [approverEmail, setApproverEmail] = useState('')
    const [showOtpSection, setShowOtpSection] = useState(true)
    const [otpSend, setOtpSend] = useState(false)
    const [pushNotificationSend, setPushNotificationSend] = useState(false)

    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        return {
            total, minutes, seconds
        };
    }

    const startTimer = (e) => {
        let { total, minutes, seconds } = getTimeRemaining(e);
        if (total >= 0) {
            setMinutes('' + (minutes > 9 ? minutes : '0' + minutes));
            setSeconds('' + (seconds > 9 ? seconds : '0' + seconds));
        } else {
            if (otpTimeout !== 0) {
                setOtpTimeout(0);
            }
            // Set OtpSend to false when countdown ends
            if (otpSend === true) {

                setOtpSend(false);
                setAuthCode('');
            }

            if (pushNotificationSend === true) {

                setPushNotificationSend(false);
                clearInterval(Ref.current)
            }
        }
    }

    const clearTimer = (e) => {

        setMinutes('0' + otpTimeout)
        setSeconds('00')

        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }

    const getDeadTime = () => {
        let deadline = new Date();
        // deadline.setSeconds(deadline.getSeconds() + otpTimeout)
        deadline.setMinutes(deadline.getMinutes() + otpTimeout)
        return deadline;
    }

    const onClickReset = () => {
        clearTimer(getDeadTime());
    }

    const sendOtp = () => {
        sendSmsOtp(approverIc, smsProvider).then(res => {
            if (!res.status) throw res.message
            // if (!res.status === true) throw res.message

            // setOtpTimeout(3)
            if (res.status === true) message.success(res.message)

        }).catch(err => err && message.error(err.toString()))
    }

    const resetOtpModal = () => {
        setOtpTimeout(0)
        setApproverIc(null)
        setUserMobileNo('')
        setAuthCode('')
        setApproverEmail('')
    }

    useEffect(() => {
        onClickReset()
    }, [otpTimeout])

    useEffect(() => {
        if (isDisabled && props.isIncorrectOtp) {
            setIsDisabled(false)
        }
    }, [props.isIncorrectOtp, props.isModalVisible])

    useEffect(async () => {

        if (company.id) {
            getAdminUsersFromApi()
        }

        await getUserRole().then(async localRole => {

            setRole(localRole)

            // if (props?.transactionType == OUT_TRANSACTION_TYPES_IDS.D2I) {

            //     if (localRole == 'OWNER' || localRole == 'GENERAL_MANAGER') {
            //         setApproverIc(user.nric)
            //         setUserMobileNo(user?.mobileno)
            //     } else {
            //         setApproverIc(null)
            //         setUserMobileNo(null)
            //     }

            // } else {

            //     // if (localRole == 'VALUER' || localRole == 'SALES' || localRole == 'ADMIN') {
            //     //     setApproverIc(null)
            //     //     setUserMobileNo(null)
            //     // } else {
            //     //     setApproverIc(user.nric)
            //     //     setUserMobileNo(user?.mobileno)
            //     // }

            //     setApproverIc(null)
            //     setUserMobileNo(null)

            // }

        })

    }, [company.id, props?.transactionType, showOtpSection])

    function getAdminUsersFromApi() {

        setIsLoading(true)
        /**
         * 22/1/2022 daniel.kwok
         * Instructed by BE to get admin users where branchid = 0 (i.e. HQ)
         * at this moment. 
         */

        if (props?.transactionType == OUT_TRANSACTION_TYPES_IDS.D2I) {

            getAdminUsersForOut(company.id, HQ_BRANCH.id)
                .then(res => {
                    if (res.status !== true) throw res.message
                    let userList = res.users.sort((a, b) => a.name.localeCompare(b.name))
                    if (!showOtpSection) userList = userList.filter(item => item.isDeviceRegistered === 'Y');
                    userList = userList.filter(item => item.nric_no !== getAuthorizedNric());
                    setAdminUsers(userList)
                })
                .catch(err => err && message.error(err.toString()))
                .finally(() => setIsLoading(false))

        } else {

            getAdminUsers(company.id, HQ_BRANCH.id)
                .then(res => {
                    if (res.status !== true) throw res.message
                    let userList = res.users.sort((a, b) => a.name.localeCompare(b.name))
                    if (!showOtpSection) userList = userList.filter(item => item.isDeviceRegistered === 'Y');
                    userList = userList.filter(item => item.nric_no !== getAuthorizedNric());
                    setAdminUsers(userList)
                })
                .catch(err => err && message.error(err.toString()))
                .finally(() => setIsLoading(false))
        }

    }

    const setApproverInfo = (approverInfo) => {
        setApproverIc(approverInfo.approverIc)
        setApproverEmail(approverInfo.approverEmail)
        getAccountDetails(approverInfo.approverIc).then(res => {
            setUserMobileNo(res?.mobileno)
        }).catch(err => err && message.error(err))
    }

    // useEffect(() => {

    //     if (props?.transactionType && role) {

    //         if (props?.transactionType == OUT_TRANSACTION_TYPES_IDS.D2I) {

    //             if (role == 'OWNER' || role == 'GENERAL_MANAGER') setShowApprovalSection(false)
    //             else setShowApprovalSection(true)

    //         } else {

    //             // if (role == 'VALUER') setShowApprovalSection(true)
    //             // else setShowApprovalSection(false)

    //             setShowApprovalSection(false)

    //         }

    //     }

    // }, [props.transactionType, role])

    const handleRadioChange = (e) => {
        setShowOtpSection(e.target.value === 'sms'); // Set to true for SMS, false for app
        resetOtpModal()
    };

    const intervalId = useRef(null);

    useEffect(() => {
        if (pushNotificationSend) {
            // Call sendNotification here and handle the response
            sendNotification(approverEmail, 'OUT', transferId, [],null,false)
                .then(res => {
                    if (!res.status) {
                        message.warning(res.message)
                        setPushNotificationSend(false)
                    }
                    else {
                        setOtpTimeout(3);
                        message.success(res.message)
                        setPushNotificationSend(true)
                        intervalId.current = setInterval(() => {
                            // Call checkTxnApprovalStatus here and handle the response
                            checkTxnApprovalStatus(transferId, 'OUT')
                                .then(res => {
                                    if (res.status || res.isRejected) {
                                        res.isRejected ? message.error(res.message) : message.success(res.message)
                                        // clearInterval(intervalId.current);
                                        // onClickReset();
                                        setOtpTimeout(0);
                                        setPushNotificationSend(false);
                                        props.onOk(approverIc, '', true, res);//send empty authcode/otp due to use app approval
                                    } 
                                    else if (res.errorMessage !== "") {
                                        message.error(res.errorMessage)
                                        // clearInterval(intervalId.current);
                                        // onClickReset();
                                        setOtpTimeout(0);
                                        setPushNotificationSend(false);
                                    }
                                });
                        }, 3000);
                    }
                });
        } else if (intervalId.current) {
            clearInterval(intervalId.current);
        }
        //prevent interval from continuing when user navigates away from the page
        return () => clearInterval(intervalId.current);
    }, [pushNotificationSend]);

    return (
        <>
            {
                APP_CONST.USE_OTP === true ?
                    <>
                        <Modal
                            className='i2i-buyer-info-modal'
                            visible={props.isModalVisible}
                            okButtonProps={{
                                disabled: !authCode,
                            }}
                            onCancel={props.onCancel}
                            width={400}
                            footer={null}
                            afterClose={() => setSmsProvider(1)}
                            maskClosable={false}
                        >
                            <div style={{ marginTop: 24, width: '100%' }}>
                                <p style={{ textAlign: 'center', font: 'normal normal 600 24px Raleway' }}>Proceed Payment</p>

                                <div style={{ display: 'flex', flexDirection: 'column', gap: 20, marginBottom: 16 }}>
                                    <div>
                                        <p style={{ fontStyle: 'italic' }}>Select Approval Method</p>
                                        <Radio.Group onChange={handleRadioChange} value={showOtpSection ? 'sms' : 'app'}>
                                            <Radio value='sms' disabled={pushNotificationSend}>
                                                SMS OTP
                                            </Radio>
                                            <Radio value='app' disabled={otpSend}>
                                                Mobile App
                                            </Radio>
                                        </Radio.Group>
                                    </div>

                                    <div>
                                        <p style={{ fontStyle: 'italic' }}>Approval Name</p>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                            <Select
                                                disabled={pushNotificationSend}
                                                className='textbox-form-style modal-dropdown'
                                                loading={isLoading}
                                                style={{ width: '100%' }}
                                                placeholder='Select Approval Name'
                                                value={approverIc}
                                                onChange={(approverIc) => {
                                                    // Find the selected user
                                                    const selectedUser = adminUsers.find(u => u.nric_no === approverIc);
                                                    // Set the approverId and name
                                                    if (selectedUser) {
                                                        setApproverInfo({
                                                            approverIc: selectedUser.nric_no,
                                                            approverEmail: selectedUser.login_name
                                                        });
                                                    }
                                                }}
                                            >
                                                {adminUsers.map((u) => (
                                                    <Select.Option value={u.nric_no} key={u.nric_no}>
                                                        {u.name}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </div>
                                    </div>

                                    {showOtpSection ? (
                                        <div>
                                            <p style={{ fontStyle: 'italic' }}>Verification Code</p>
                                            <p>
                                                Please enter the verification code sent to <br />
                                                <b>{userMobileNo}</b>
                                            </p>

                                            <Row gutter={[8, 8]}>
                                                <Col span={16}>
                                                    <Input.Password
                                                        className='textbox-form-style'
                                                        disabled={!approverIc || !otpSend}
                                                        placeholder='e.g. 000000'
                                                        value={authCode}
                                                        onChange={(e) => {
                                                            setAuthCode(e.target.value);
                                                        }}
                                                        visibilityToggle
                                                    />
                                                </Col>
                                                <Col span={8}>
                                                    {minutes === '00' && seconds === '00' && (
                                                        <Button
                                                            disabled={!approverIc}
                                                            type='text'
                                                            style={{
                                                                width: '100%',
                                                                background: 'white',
                                                                border: 'none',
                                                                color: '#2B61C4',
                                                                textTransform: 'uppercase',
                                                            }}
                                                            onClick={() => {
                                                                setSmsProvider(1);
                                                                setOtpTimeout(3);
                                                                sendOtp();
                                                                setOtpSend(true);
                                                            }}
                                                        >
                                                            SEND CODE
                                                        </Button>
                                                    )}
                                                </Col>
                                            </Row>
                                            {minutes === '00' && seconds === '00' ? (
                                                <p style={{ color: 'grey', fontStyle: 'italic', marginTop: '1em' }}></p>
                                            ) : (
                                                <p style={{ color: 'grey', fontStyle: 'italic', marginTop: '1em' }}>
                                                    Code will expire in {minutes} min {seconds} sec
                                                </p>
                                            )}
                                        </div>
                                    ) : (
                                        <div>
                                            {minutes === '00' && seconds === '00' ? (
                                                <p style={{ color: 'grey', fontStyle: 'italic', marginTop: '1em' }}></p>
                                            ) : (
                                                <p style={{ color: 'grey', fontStyle: 'italic', marginTop: '1em' }}>
                                                    App approval will expire in {minutes} min {seconds} sec
                                                </p>
                                            )}
                                            <Button
                                                type='primary'
                                                shape={'round'}
                                                disabled={!approverEmail}
                                                onClick={() => {
                                                    setPushNotificationSend(true)
                                                }}
                                                size='large'
                                                loading={pushNotificationSend} // This line makes the button show a loading indicator
                                                style={{
                                                    width: '100%',
                                                    background: '#2B61C4 0% 0% no-repeat padding-box',
                                                    boxShadow: '0px 4px 0px #1E4489',
                                                    borderRadius: '8px',
                                                    borderColor: 'transparent',
                                                    color: 'white',
                                                }}
                                            >
                                                {pushNotificationSend ? "Waiting for approval" : "Send Push Notification Approval"}
                                            </Button>
                                        </div>
                                    )}

                                    {showOtpSection && (
                                        <Button
                                            type='primary'
                                            shape={'round'}
                                            onClick={() => {
                                                setIsDisabled(true)
                                                props.onOk(approverIc, authCode, false);
                                            }}
                                            disabled={!authCode || !approverIc || isDisabled}
                                            size='large'
                                            loading={props.paymentLoading}
                                            style={{
                                                width: '100%',
                                                background: '#2B61C4 0% 0% no-repeat padding-box',
                                                boxShadow: '0px 4px 0px #1E4489',
                                                borderRadius: '8px',
                                                borderColor: 'transparent',
                                                color: 'white',
                                            }}
                                        >
                                            Submit
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </Modal>
                    </>
                    :
                    <>
                        <Modal
                            visible={props.isModalVisible}
                            onCancel={props.onCancel}
                            width={330}
                            footer={null}
                            className='i2i-buyer-info-modal'
                        >
                            <div direction='vertical' style={{ marginTop: 24, width: '100%' }}>
                                <p style={{ textAlign: 'center', font: 'normal normal 600 24px Raleway' }}>Proceed Payment</p>

                                {role === 'SALES_MANAGER' ? <div>
                                    <p style={styles.label}>Approval Name</p>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 10,
                                            marginBottom: 16
                                        }}
                                    >
                                        <Select
                                            className='textbox-form-style'
                                            loading={isLoading}
                                            placeholder="Select an approver"
                                            style={{ width: '100%' }}
                                            onChange={approverIc => setApproverIc(approverIc)}
                                        >
                                            {
                                                adminUsers.map(u => {
                                                    return <Select.Option value={u.nric_no} key={u.nric_no}>
                                                        {u.name}
                                                    </Select.Option>
                                                })
                                            }
                                        </Select>

                                    </div></div> : null}

                                <p style={styles.label}>Authentication Code</p>
                                <Input
                                    className='textbox-form-style'
                                    disabled={!approverIc}
                                    placeholder='e.g. 000000'
                                    value={authCode}
                                    onChange={e => {
                                        setAuthCode(e.target.value)
                                    }}
                                />
                                <Button
                                    type='primary'
                                    onClick={() => {
                                        setIsDisabled(true)
                                        props.onOk(approverIc, authCode, false)
                                    }}
                                    disabled={!authCode || isDisabled}
                                    size='large'
                                    loading={props.paymentLoading}
                                    style={{
                                        marginTop: 24
                                    }}
                                >
                                    Submit
                                </Button>
                            </div>
                        </Modal >
                    </>
            }
        </>

    )
}

PaymentSubmissionModal.propTypes = {
    onCancel: propTypes.func,
    onOk: propTypes.func,
    isModalVisible: propTypes.bool,
    paymentLoading: propTypes.bool,
    transactionType: propTypes.string,
    isIncorrectOtp: propTypes.bool,
}

const styles = {
    label: {
        color: 'grey', font: 'italic normal normal 16px Open Sans', marginBottom: 8,
        textAlign: 'initial'
    },
    value: {
        font: 'normal normal 600 16px Open Sans'
    }
}

export default PaymentSubmissionModal