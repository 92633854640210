export const PaginationShowingSpan = (startItem, endItem, count) => {
  return (
    <span>
      Showing{" "}
      <b>
        {startItem} - {endItem}
      </b>{" "}
      of <b>{count} transactions</b>.
    </span>
  );
};

export const ApprovalStatusSpan = ({ isSuccess, inquiryCount }) => {
  const backgroundColor = isSuccess ? "#def2d6" : "#ffefef";
  return (
    <div
      style={{
        backgroundColor,
        padding: "20px",
        textAlign: "center",
      }}
    >
      <span style={{ fontSize: "2.5em" }}>
        <strong>{inquiryCount}</strong>
      </span>
      <br />
      <span style={{ fontSize: "1.5em" }}>
        {isSuccess ? "Successful" : "Failed"}
      </span>
    </div>
  );
};