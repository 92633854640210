import React, { useContext, useEffect, useState, useRef } from 'react'
import {
    Input,
    Button,
    Card,
    Row,
    message,
    Col
} from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import {
    sendSmsOtp, verifySmsOtp
} from '../../services/api'
import { SessionContext } from '../../App';
import { HideEyeIcon, ShowEyeIcon } from '../../components/CustomIcons'
import { getDecryptedVerifyOtpResponse } from "../../util/index"

const STEPS = {
    preview: 'preview',
    activate: 'activate',
    finish: 'finish',
}

function SetupOtp(props) {

    const { user } = useContext(SessionContext)
    const history = useHistory()
    const params = useParams()
    const [authCode, setAuthCode] = useState('')
    const [showLoading, setShowLoading] = useState(false)
    const [userMobileNo, setUserMobileNo] = useState('')
    const [smsProvider, setSmsProvider] = useState(1)

    // We need ref in this, because we are dealing
    // with JS setInterval to keep track of it and
    // stop it when needed
    const Ref = useRef(null);

    // The state for our timer
    const [minutes, setMinutes] = useState('00')
    const [seconds, setSeconds] = useState('00')

    useEffect(() => {
        if (Object.keys(user).length > 0) setUserMobileNo(user?.mobileno)
    }, [user])

    useEffect(() => {
        if (Object.keys(user).length > 0) {
            sendSmsOtp(user.nric, smsProvider).then(res => {
                if (!res.status) throw res.message
                // if (!res.status === true) throw res.message

                clearTimer(getDeadTime())

            }).catch(err => err && message.error(err.toString()))
            // clearTimer(getDeadTime())
        }
    }, [user])

    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        // const hours = Math.floor((total / 1000 / 60 / 60) % 24);
        // const minstosecs = Math.floor((total / 1000));
        return {
            total, minutes, seconds
        };
    }

    const startTimer = (e) => {
        let { total, minutes, seconds }
            = getTimeRemaining(e);
        if (total >= 0) {

            // update the timer
            // check if less than 10 then we need to 
            // add '0' at the beginning of the variable
            setMinutes((minutes > 9 ? minutes : '0' + minutes))
            setSeconds((seconds > 9 ? seconds : '0' + seconds))
        } else if (total < 0) {

        }
    }

    const clearTimer = (e) => {

        // If you adjust it you should also need to
        // adjust the Endtime formula we are about
        // to code next
        setMinutes('03')
        setSeconds('00')

        // If you try to remove this line the 
        // updating of timer Variable will be
        // after 1000ms or 1sec
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }

    const getDeadTime = () => {
        let deadline = new Date();

        // This is where you need to adjust if 
        // you entend to add more time
        // deadline.setSeconds(deadline.getSeconds() + 10);
        deadline.setMinutes(deadline.getMinutes() + 3)
        return deadline;
    }

    // Another way to call the clearTimer() to start
    // the countdown is via action event from the
    // button first we create function to be called
    // by the button
    const onClickReset = () => {
        clearTimer(getDeadTime());
    }

    const resendOtp = () => {
        sendSmsOtp(user.nric, smsProvider).then(res => {
            if (!res.status) throw res.message
            // if (!res.status === true) throw res.message

            onClickReset()

        }).catch(err => err && message.error(err.toString()))
    }

    const verifyOtp = () => {
        setShowLoading(true)

        verifySmsOtp(authCode).then(res => {

            const decryptedResponse = getDecryptedVerifyOtpResponse(res.encryptedString)

            if (decryptedResponse.status == 'false') throw decryptedResponse.message

            message.success(decryptedResponse.message)
            setShowLoading(false)
            history.push(`/otp/${STEPS.finish}`)

        }).catch(err => {
            setShowLoading(false)
            err && message.error(err)
        })
    }

    const COMPONENT_STEPS = {
        [STEPS.activate]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Verify Code
                    </h1>
                    <div
                        style={{
                            textAlign: 'left'
                        }}
                    >
                        <p style={{ fontStyle: 'italic' }}>Please enter the verification code sent to <br /><b>{userMobileNo}</b></p>

                        <Row gutter={[8, 8]}>
                            <Col span={16}>
                                <Input.Password
                                    iconRender={visible => (visible ? ShowEyeIcon() : HideEyeIcon())}
                                    value={authCode}
                                    placeholder={'000 000'}
                                    onChange={e => setAuthCode(e.target.value)}
                                    style={{
                                        border: '1px solid #2B61C4',
                                        borderRadius: '4px',
                                        font: 'normal normal 600 15px/20px Open Sans'
                                    }}
                                />
                            </Col>
                            <Col span={8}>
                                {
                                    minutes === '00' && seconds === '00' &&
                                    <Button
                                        id={'show-resend-otp'}
                                        style={{
                                            width: '100%',
                                            background: 'white',
                                            border: 'none',
                                            color: '#2B61C4',
                                            textTransform: 'uppercase'
                                        }}
                                        type="secondary" onClick={() => {
                                            // setSmsProvider((smsProvider < 2 ? smsProvider + 1 : 1))
                                            setSmsProvider(1)
                                            resendOtp()
                                        }}>
                                        RESEND CODE
                                    </Button>
                                }

                            </Col>
                        </Row>
                        {
                            minutes === '00' && seconds === '00' ? <p style={{ color: 'grey', fontStyle: 'italic', marginTop: '1em' }}></p> :
                                <p style={{ color: 'grey', fontStyle: 'italic', marginTop: '1em' }}>Code will expire in {minutes} min {seconds} sec</p>
                        }

                    </div>
                    <Button
                        loading={showLoading}
                        size='large'
                        style={{
                            background: " #2B61C4 0% 0% no-repeat padding-box",
                            boxShadow: "0px 4px 0px #1E4489",
                            borderRadius: "8px",
                            borderColor: 'transparent',
                            width: '100%',
                            color: 'white'
                        }}

                        disabled={!authCode}
                        type='primary'
                        onClick={() => verifyOtp()}
                    >
                        Verify code
                    </Button>
                </div>
            )
        },
        [STEPS.finish]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        OTP Authentication
                    </h1>
                    <p style={{ fontStyle: 'italic' }}>Success!</p>
                    <Button
                        style={{
                            marginTop: 20,
                            width: '100%'
                        }}
                        type='primary'
                        onClick={() => {
                            window.location.pathname = '/selectbranch'
                        }}
                    >
                        Select Branch
                    </Button>
                </div>
            )
        },
    }

    const currentStep = COMPONENT_STEPS[params.step]

    useEffect(() => {
        if (!params.step) {
            history.replace(`/otp/${STEPS.activate}`)
        }
    }, [params])

    return (
        <div>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}
            >
                <Card
                    style={{
                        width: 400,
                        boxShadow: '0px 3px 6px #00000029',
                        borderRadius: '8px',
                        marginTop: '3%'
                    }}
                >
                    {
                        currentStep && currentStep.component
                    }
                </Card>
            </div>
        </div>
    )
}

export default SetupOtp