import { DownloadOutlined } from "@ant-design/icons";
import { Breadcrumb, Card, PageHeader } from "antd";
import JPJK3 from "../../assets/files/JPJK3.pdf";
import JPJK6 from "../../assets/files/JPJK6.pdf";
import JPJK7 from "../../assets/files/JPJK7.pdf";
import JPJK8 from "../../assets/files/JPJK8.pdf";

import UAT_SAGEM_DRIVERS from "../../assets/files/SAGEM/UAT/UAT_SAGEM_DRIVERS.zip";
import SAGEM_DRIVERS from "../../assets/files/SAGEM/PROD/SAGEM_DRIVERS.zip";

import UAT_DERMALOG_DRIVERS from "../../assets/files/DERMALOG/UAT/UAT_DERMALOG_DRIVERS.zip";
import DERMALOG_DRIVERS from "../../assets/files/DERMALOG/PROD/DERMALOG_DRIVERS.zip";

import UAT_IDEMIA_DRIVERS from "../../assets/files/IDEMIA/UAT/IDEMIA_DRIVERS.zip";
import IDEMIA_DRIVERS from "../../assets/files/IDEMIA/PROD/IDEMIA_DRIVERS.zip";

import MyKadMyKid from "../../assets/files/MYKADMYKID/MyKadMyKid.zip";

export default function DownloadForms() {
	return (
		<>
			<div className="page-content">
				<Breadcrumb separator={">"}>
					<Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
					<Breadcrumb.Item>Download</Breadcrumb.Item>
				</Breadcrumb>
				<PageHeader
					className="site-page-header"
					style={{ paddingLeft: "0px", paddingRight: "0px" }}
					title="Download"
				/>
				<Card title="Remote Deskstop Application" className="form-background">
					<p>
						<a
							download={
								"AnyDesk"
							}
							href={'https://anydesk.com/en/downloads/thank-you?dv=win_exe'}
							target="_blank"
						>
							<DownloadOutlined />
							AnyDesk
						</a>
					</p>
				</Card>
				<Card title="Driver Installation" className="form-background">
					<p>
						<a
							download={
								process.env.REACT_APP_ENVIRONMENT == "uat"
									? "UAT_SAGEM_DRIVERS.zip"
									: "SAGEM_DRIVERS.zip"
							}
							href={
								process.env.REACT_APP_ENVIRONMENT == "uat"
									? UAT_SAGEM_DRIVERS
									: SAGEM_DRIVERS
							}
							target="_blank"
						>
							<DownloadOutlined />
							SAGEM Driver
						</a>
					</p>
					<p>
						<a
							download={
								process.env.REACT_APP_ENVIRONMENT == "uat"
									? "UAT_DERMALOG_DRIVERS.zip"
									: "DERMALOG_DRIVERS.zip"
							}
							href={
								process.env.REACT_APP_ENVIRONMENT == "uat"
									? UAT_DERMALOG_DRIVERS
									: DERMALOG_DRIVERS
							}
							target="_blank"
						>
							<DownloadOutlined />
							DERMALOG Driver
						</a>
					</p>
					<p>
						<a
							download={
								process.env.REACT_APP_ENVIRONMENT == "uat"
									? "UAT_IDEMIA_DRIVERS.zip"
									: "IDEMIA_DRIVERS.zip"
							}
							href={
								process.env.REACT_APP_ENVIRONMENT == "uat"
									? UAT_IDEMIA_DRIVERS
									: IDEMIA_DRIVERS
							}
							target="_blank"
						>
							<DownloadOutlined />
							IDEMIA Driver
						</a>
					</p>
					<p>
						<a
							download={"MyKadMyKid.zip"}
							href={MyKadMyKid}
							target="_blank"
						>
							<DownloadOutlined />
							MyKadMyKid
						</a>
					</p>
				</Card>
			</div>
			<style>
				{`
                .page-content {
                    margin-top: 60px;
                }
                .ant-card { 
                    margin-bottom: 20px;
                }
                .ant-card, .ant-card > * {
                    text-align: start;
                }
                .ant-card-head { 
                    background-color: #00000015;
                }
                .ant-card-head-title {
                    font-size: 24px;
                }
                .ant-card-body a span { 
                    margin: 0px 30px 5px 10px;
                    font-size: 30px;
                }
                .ant-card-body a { 
                    display: flex;
                    align-items: center;
                }
				.ant-card-head { 
					padding: 0px 34px;
				}
            `}
			</style>
		</>
	);
}
