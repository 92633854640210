import React, { useEffect, useContext, useState } from 'react'
import {
    Card,
    Col,
    Row,
    Button,
    message,
    Skeleton,
    Space,
    Image
} from 'antd'
import './index.less'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import {
    PrinterOutlined,
} from '@ant-design/icons'
import {
    breakdownIc,
    parseAddress,
    parseTime,
    parseCorrespondenceAddress,
    getDistrictName
} from '../../util'
import {
    getOutTransferAmountByTransferId,
    getOutTransferByTransferId,
    getPrepaidBalance,
    getPrepaidReceiptOUT,
    getPrepaidReceiptV2OUT,
    getCompanyDetails,
} from '../../services/api'
import {
    OUT_TRANSACTION_TYPES_IDS,
    STATES,
    TRANSFER_PAYMENT_STATUSES,
    OUT_TRANSACTION_STATUSES
} from '../../constants'
import { SessionContext } from '../../App'
import successIcon from "../../assets/icon-success.svg";
import { ReactComponent as WarningIcon } from '../../assets/icon-warning-red-28.svg';
import {
    getCompanyId,
} from '../../services/local'

function PrintSlip(props) {

    const { isReport } = props

    const history = useHistory()
    const params = useParams()
    const { company } = useContext(SessionContext)
    const { state: locationState } = useLocation()

    const [isLoading, setIsLoading] = useState(false)
    const [isPrintLoading, setIsPrintLoading] = useState(false)
    const [transfer, setTransfer] = useState(null)
    const [balance, setBalance] = useState(0)
    const [districtName, setDistrictName] = useState('')
    const [transferId, setTransferId] = useState('')

    const [sellerDistrictName, setSellerDistrictName] = useState('')
    const [sellerCorrespondenceDistrictName, setSellerCorrespondenceDistrictName] = useState('')
    const [buyerDistrictName, setBuyerDistrictName] = useState('')
    const [buyerCorrespondenceDistrictName, setBuyerCorrespondenceDistrictName] = useState('')
    const [isNewPrice, setIsNewPrice] = useState(false)
    const [isPrint2Loading, setIsPrint2Loading] = useState(false)
    const [isPrint3Loading, setIsPrint3Loading] = useState(false)
    const [dealerCompanyDetail, setDealerCompanyDetail] = useState(null)

    const ReceiptType = {
        vehicleDealerCopy : '1',
        confirmationSlip : '2',
        dealerCopy : '3',
    }

    useEffect(() => {

        if (params.transferId) setTransferId(params.transferId)

    }, [params.transferId])

    useEffect(() => {

        if (transferId) getAndSetTransfer()

    }, [transferId])

    useEffect(() => {

        if (locationState && !transferId && locationState?.fromAdminPage === true) setTransferId(history?.location?.state?.transferId)

    }, [transferId, history.location.state])

    function getAndSetTransfer() {
        setIsLoading(true)

        Promise.all([
            getOutTransferAmountByTransferId(transferId),
            getOutTransferByTransferId(transferId, "OUT", getCompanyId()),
            getPrepaidBalance(company.coRegNo)
        ]).then(res => {
            if (!res) throw `Something went wrong`
            const [resAmount, resTransfer, resBalance] = res
            if (resTransfer.id === undefined) throw res.message

            setTransfer({
                id: resTransfer.id,
                status: resTransfer.status,
                paymentStatus: resTransfer.paymentStatus,
                sellerNRIC: resTransfer.sellerNRIC,
                sellerName: resTransfer.sellerName,
                transferDate: resTransfer.transferDate,
                updatedDate: resTransfer.updatedDate,
                createdDate: resTransfer.createdDate,
                transactionType: resTransfer.transactionType,
                repName: resTransfer.sellerInfo?.repName,
                repIc: resTransfer.sellerInfo?.repIc,
                carRegistrationNo: resTransfer.carRegistrationNo,
                chassisNo: resTransfer.chassisNo,
                engineNo: resTransfer.engineNo,
                // carMake: resTransfer.make,
                // carModel: resTransfer.model,
                // manufactureYear: resTransfer.manufactureYear,

                ownerCatagory: resTransfer.ownerCatagory,
                vehicleTypeId: resTransfer.vehicleTypeId,
                sellerInfo: resTransfer.sellerInfo,
                buyerInfo: resTransfer.buyerInfo,
                fisStatus: resTransfer.fisStatus,

                "eserviceAmount": resAmount.eserviceAmount,
                "fisAmount": resAmount.fisAmount,
                "fisEserviceAmount": resAmount.fisEserviceAmount,
                "jpjAmount": resAmount.jpjAmount,
                "name": resAmount.name,
                "totalAmount": resAmount.totalAmount,
                "sstAmount": resAmount.sstAmount,
                "processingAmount": resAmount.processingAmount
            })

            if (resTransfer.sellerInfo?.identificationNo) {
                getCompanyDetails(resTransfer.sellerInfo?.identificationNo).then((res) => {
                    console.log(res)
                    setDealerCompanyDetail(res.companyDetails);
                })
            }

            if (resTransfer.buyerInfo?.state && resTransfer.buyerInfo?.district) {

                getDistrictName(
                    resTransfer.buyerInfo?.state,
                    resTransfer.buyerInfo?.district
                ).then((res) => {
                    setBuyerDistrictName(res)
                })

            }

            if (resTransfer.buyerInfo?.correspondentState && resTransfer.buyerInfo?.correspondentDistrict) {

                getDistrictName(
                    resTransfer.buyerInfo?.correspondentState,
                    resTransfer.buyerInfo?.correspondentDistrict
                ).then((res) => {
                    setBuyerCorrespondenceDistrictName(res)
                })

            }

            if (resTransfer.sellerInfo?.state && resTransfer.sellerInfo?.district) {

                getDistrictName(
                    resTransfer.sellerInfo?.state,
                    resTransfer.sellerInfo?.district
                ).then((res) => {
                    setSellerDistrictName(res)
                })

            }

            if (resTransfer.sellerInfo?.correspondentState && resTransfer.sellerInfo?.correspondentDistrict) {

                getDistrictName(
                    resTransfer.sellerInfo?.correspondentState,
                    resTransfer.sellerInfo?.correspondentDistrict
                ).then((res) => {
                    setSellerCorrespondenceDistrictName(res)
                })

            }

            setBalance(resBalance.balanceAmount)

            if (resTransfer?.vehicleTypeNewId !== 0) {
                setIsNewPrice(true)
            }
        })
            .catch(err => err && message.error(err))
            .finally(() => setIsLoading(false))
    }


    function onPrintSlip(type, setPrintLoading) {
        // console.log("type",type)
        if (type !== null && type !== 'undefined' && (ReceiptType.confirmationSlip === type || 
            ReceiptType.dealerCopy=== type || ReceiptType.vehicleDealerCopy=== type)) {
            setPrintLoading(true)
            getPrepaidReceiptV2OUT(company.id, transfer.id, transfer.carRegistrationNo, type)
                .finally(() => setPrintLoading(false))
        }
        else {
            setIsPrintLoading(true)
            getPrepaidReceiptOUT(company.id, transfer.id, transfer.carRegistrationNo)
                .finally(() => setIsPrintLoading(false))
        }
    }

    /**
     * If transfer store no longer loading,
     * and no transfer is found
     */
    if (
        !isLoading
        && !transfer
    ) {
        return <></>
        /* return (
            <Card
                style={{
                    height: '50%',
                    width: '100%',
                    boxShadow: '0px 3px 6px #00000029',
                    borderRadius: 4
                }}
            >
                <h1>Unknown transfer</h1>
                <a
                    onClick={() => history.push('/')}
                >Go back to home</a>
            </Card>
        ) */
    }

    const { formattedDob: buyerFormattedDob, isMale: buyerIsMale } = breakdownIc(transfer?.buyerInfo?.identificationNo)
    const { formattedDob: sellerFormattedDob, isMale: sellerIsMale } = breakdownIc(transfer?.sellerInfo?.identificationNo)

    const PaymentSuccessBlock = () => {
        const isAdminPage = history?.location?.state?.fromAdminPage;
        const paymentSuccess = transfer?.paymentStatus === TRANSFER_PAYMENT_STATUSES.SUCCESS;

        if (paymentSuccess) {
            return (
                <Card
                    style={{
                        height: '50%',
                        textAlign: 'left',
                        boxShadow: '0px 3px 6px #00000029',
                        borderRadius: 4
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <Image src={successIcon} alt="Success icon" preview={false} style={{ paddingRight: '20px' }} />
                        <div style={{ flex: 4 }}>
                            <h2 style={{ color: '#40e327a8', font: 'normal normal bold 23px/31px Open Sans', margin: 0 }}>
                                Payment Successful
                            </h2>
                            {!isAdminPage && (
                                <p>
                                    Your remaining balance is{' '}
                                    {new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency: 'MYR'
                                    }).format(balance || 0) || 'Unavailable'}
                                </p>
                            )}
                        </div>
                        {transfer?.status !== OUT_TRANSACTION_STATUSES.UPDATE_TO_JPJ_FAILED && (
                           /*  <Button
                                className="back-button"
                                icon={<PrinterOutlined />}
                                shape="round"
                                style={{ flex: 1, border: '1px solid #2B61C4', color: '#2B61C4' }}
                                loading={isPrintLoading}
                                onClick={onPrintSlip}
                            >
                                Print Slip
                            </Button> */
                            !isNewPrice ?
                                <Button
                                    className="back-button"
                                    icon={<PrinterOutlined />}
                                    shape="round"
                                    style={{ flex: 1, border: '1px solid #2B61C4', color: '#2B61C4' }}
                                    loading={isPrintLoading}
                                    onClick={onPrintSlip}
                                >
                                    Print Slip
                                </Button> :
                    
                                <Col>
                                    <Row>
                                    <Button
                                        className='back-button'
                                        icon={<PrinterOutlined />}
                                        shape={'round'}
                                        style={{
                                            flex: 1
                                        }}
                                        loading={isPrintLoading}
                                        onClick={() => onPrintSlip(ReceiptType.vehicleDealerCopy, setIsPrintLoading)}
                                    >
                                        Vehicle Dealer Copy
                                    </Button>
                                    </Row>
                                    <br/>
                                    <Row>
                                    <Button
                                        className='back-button'
                                        icon={<PrinterOutlined />}
                                        shape={'round'}
                                        style={{
                                            flex: 1
                                        }}
                                        loading={isPrint2Loading}
                                        onClick={() => onPrintSlip(ReceiptType.confirmationSlip, setIsPrint2Loading)}
                                    >
                                        Confirmation Slip
                                    </Button>
                                    </Row>
                                    <br/>
                                    <Row>
                                    <Button
                                        className='back-button'
                                        icon={<PrinterOutlined />}
                                        shape={'round'}
                                        style={{
                                            flex: 1
                                        }}
                                        loading={isPrint3Loading}
                                        onClick={() => onPrintSlip(ReceiptType.dealerCopy, setIsPrint3Loading)}
                                    >
                                        Dealer Copy
                                    </Button>
                                    </Row>
                                </Col>
                        )}
                    </div>
                </Card>
            );
        }

        return <></>;
    };

    const PrintButton = () => {
        const isPrintable =
            (isReport || history?.location?.state?.fromAdminPage) &&
            transfer?.paymentStatus === TRANSFER_PAYMENT_STATUSES.SUCCESS &&
            transfer?.status !== OUT_TRANSACTION_STATUSES.UPDATE_TO_JPJ_FAILED;

        return isPrintable ? 
        /* (
            {<Button
                icon={<PrinterOutlined />}
                style={{
                    border: '1px solid #2B61C4',
                    color: '#2B61C4',
                    background: 'transparent'
                }}
                shape="round"
                onClick={onPrintSlip}
                loading={isPrintLoading}
                className="back-button buttons"
            >
                Print Slip
            </Button>}
            
        ) */ 
        (
            !isNewPrice ?
            <Button
                icon={<PrinterOutlined />}
                style={{
                    border: '1px solid #2B61C4',
                    color: '#2B61C4',
                    background: 'transparent'
                }}
                shape="round"
                onClick={onPrintSlip}
                loading={isPrintLoading}
                className="back-button buttons"
            >
                Print Slip
            </Button> : null)
        
        : null;
    };

    return (

        <div
            style={{
                width: '100%',
            }}
        >

            <div>
                <PaymentSuccessBlock />
            </div>
            <div>
                <Card
                    style={{
                        height: '50%',
                        textAlign: 'left',
                        marginTop: 10,
                        boxShadow: '0px 3px 6px #00000029',
                        borderRadius: 4
                    }}
                >
                    <h2 style={{ font: 'normal normal 600 24px Raleway', marginBottom: 24 }}>Payment Details</h2>

                    {
                        isLoading ? (
                            <Skeleton active />
                        ) : (

                            <Row gutter={16}>
                                <Col className="gutter-row" span={transfer?.fisStatus == true ? 5 : 6}>
                                    <p style={styles.label}>JPJ Ownership Transfer Fee</p>
                                    <p style={styles.value}>RM {transfer && transfer.jpjAmount?.toFixed(2)}</p>
                                </Col>
                                <Col className="gutter-row" span={transfer?.fisStatus == true ? 5 : 6}>
                                    <p style={styles.label}>MYEG Service Charge</p>
                                    <p style={styles.value}>RM {transfer?.fisStatus == true ? (transfer && (transfer.eserviceAmount + transfer.fisEserviceAmount)) : (transfer && transfer.eserviceAmount)}</p>
                                </Col>
                                <Col className="gutter-row" span={transfer?.fisStatus == true ? 5 : 6}>
                                    <p style={styles.label}>SST 8%</p>
                                    <p style={styles.value}>RM {transfer && transfer.sstAmount?.toFixed(2)}</p>
                                </Col>
                                {
                                    transfer?.fisStatus == true &&
                                    <Col className="gutter-row" span={5}>
                                        <p style={styles.label}>Ownership Claimed Fee</p>
                                        <p style={styles.value}>RM {transfer && transfer.fisAmount.toFixed(2)}</p>
                                    </Col>
                                }
                                {transfer?.processingAmount && <Col className="gutter-row" span={transfer?.fisStatus == true ? 5 : 6}>
                                    <p style={styles.label}>Processing Fee</p>
                                    <p style={styles.value}>RM {transfer && transfer?.processingAmount.toFixed(2)}</p>
                                </Col>}
                                <Col className="gutter-row" span={transfer?.fisStatus == true ? 5 : 6}>
                                    <p style={styles.label}>Total Payment</p>
                                    <p style={styles.value}>RM {transfer && transfer.totalAmount?.toFixed(2)}</p>
                                </Col>
                            </Row>
                        )
                    }
                </Card>
            </div>
            <div>
                <Card
                    style={{
                        height: '50%',
                        textAlign: 'left',
                        marginTop: 10,
                        boxShadow: '0px 3px 6px #00000029',
                        borderRadius: 4
                    }}
                >
                    <h2 style={{ font: 'normal normal 600 24px Raleway', marginBottom: 24 }}>Vehicle Information</h2>
                    {
                        isLoading ? (
                            <Skeleton active />

                        ) : (
                            <div>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Vehicle Number</p>
                                        <p style={styles.value}>{transfer?.carRegistrationNo}</p>
                                    </Col>
                                    {/* <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Make</p>
                                        <p style={styles.value}>{transfer?.carMake}</p>
                                    </Col>
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Model</p>
                                        <p style={styles.value}>{transfer?.carModel}</p>
                                    </Col> */}
                                {/* </Row>
                                <Row gutter={16}> */}
                                    {/* <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Manufacture Year</p>
                                        <p style={styles.value}>{transfer?.manufactureYear}</p>
                                    </Col> */}
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Engine Number</p>
                                        <p style={styles.value}>{transfer?.engineNo}</p>
                                    </Col>
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Chassis Number</p>
                                        <p style={styles.value}>{transfer?.chassisNo}</p>
                                    </Col>
                                </Row>
                            </div>
                        )
                    }
                </Card>
            </div>
            <div>
                <Card
                    style={{
                        height: '50%',
                        textAlign: 'left',
                        marginTop: 10,
                        boxShadow: '0px 3px 6px #00000029',
                        borderRadius: 4
                    }}
                >
                    <h2 style={{ font: 'normal normal 600 24px Raleway', marginBottom: 24 }}>JPJ Enquiry Status</h2>
                    {
                        isLoading ? (
                            <Skeleton active />

                        ) : (
                            <div>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Blacklisted</p>
                                        <p style={styles.value}>No</p>
                                    </Col>
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Ownership Claim</p>
                                        <p style={styles.value}>No</p>
                                    </Col>
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Condition Code</p>
                                        <p style={styles.value}>No</p>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Valid as at</p>
                                        <p style={styles.value}> {parseTime(transfer?.updatedDate)}</p>
                                    </Col>
                                </Row>
                            </div>
                        )
                    }
                </Card>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    gap: 10,
                }}
            >

                <>
                    {
                        /**
                         * 9/3/2022 daniel.kwok
                         * Show individual information if it's i2i or i2c
                         * Show company information if it's d2i or d2c
                         */
                        <Card
                            style={{
                                flex: 3,
                                textAlign: 'left',
                                marginTop: 10,
                                maxWidth: '50%',
                                boxShadow: '0px 3px 6px #00000029',
                                borderRadius: 4
                            }}
                        >

                            {

                                (
                                    transfer?.transactionType === OUT_TRANSACTION_TYPES_IDS.I2C
                                    ||
                                    transfer?.transactionType === OUT_TRANSACTION_TYPES_IDS.I2I
                                ) ? (
                                    <>
                                        <h2 style={{ font: 'normal normal 600 24px Raleway', marginBottom: 24 }}>Seller Details</h2>
                                        {
                                            isLoading ? (
                                                <Skeleton active />

                                            ) : (
                                                <>

                                                    <p style={styles.label}>Name</p>
                                                    <p style={styles.value}>{transfer?.sellerInfo?.name}</p>
                                                    <p style={styles.label}>NRIC</p>
                                                    <p style={styles.value}>{transfer?.sellerInfo?.identificationNo}</p>

                                                    <p style={styles.label}>Date of Birth</p>
                                                    <p style={styles.value}>{transfer?.sellerInfo?.dob || sellerFormattedDob}</p>
                                                    <p style={styles.label}>Gender</p>
                                                    <p style={styles.value}>{sellerIsMale ? 'Male' : 'Female'}</p>
                                                    <p style={styles.label}>Address</p>
                                                    <p style={styles.value}>{parseAddress({ ...transfer?.sellerInfo, state: Object.values(STATES).find(s => s.code === transfer?.sellerInfo?.state)?.label, districtName: sellerDistrictName })}</p>
                                                </>
                                            )
                                        }
                                    </>
                                ) :
                                    <>
                                        <h2 style={{ font: 'normal normal 600 24px Raleway', marginBottom: 24 }}>Dealer's Details</h2>
                                        {
                                            isLoading ? (
                                                <Skeleton active />

                                            ) : (
                                                <>
                                                    <p style={styles.label}>Company name</p>
                                                    <p style={styles.value}>{transfer?.sellerInfo?.name}</p>
                                                    <p style={styles.label}>Company ROC number</p>
                                                    <p style={styles.value}>{transfer?.sellerInfo?.identificationNo}</p>
                                                    <p style={styles.label}>Company Address</p>
                                                    <p
                                                        style={styles.value}>{parseAddress({ ...dealerCompanyDetail?.companyAddress, state: Object.values(STATES).find(s => s.code === dealerCompanyDetail?.companyAddress?.stateCode)?.label })}</p>
                                                    <p style={styles.label}>Company e-mail</p>
                                                    <p style={styles.value}>{dealerCompanyDetail?.email}</p>
                                                    <p style={styles.label}>Company Phone Number</p>
                                                    <p style={styles.value}>{transfer?.sellerInfo?.phoneNo}</p>
                                                    {/* <p style={styles.label}>Dealer Representative</p>
                                                    <p style={styles.value}>{transfer?.repName}</p>
                                                    <p style={styles.label}>Dealer Representative's NRIC</p>
                                                    <p style={styles.value}>{transfer?.repIc}</p>
                                                    <p style={styles.label}>Dealer Representative's Mobile Number</p>
                                                    <p style={styles.value}>{transfer?.sellerInfo?.phoneNo}</p> */}
                                                </>
                                            )
                                        }
                                    </>
                            }
                        </Card>
                    }
                    <Card
                        style={{
                            flex: 3,
                            textAlign: 'left',
                            marginTop: 10,
                            boxShadow: '0px 3px 6px #00000029',
                            borderRadius: 4
                        }}
                    >
                        <h2 style={{ font: 'normal normal 600 24px Raleway', marginBottom: 24 }}>Buyer's Details</h2>

                        {
                            isLoading ? (
                                <Skeleton active />

                            ) : (
                                <>

                                    <p style={styles.label}>Name</p>
                                    <p style={styles.value}>{transfer?.buyerInfo?.name}</p>
                                    <p style={styles.label}>NRIC</p>
                                    <p style={styles.value}>{transfer?.buyerInfo?.identificationNo}</p>

                                    <p style={styles.label}>Date of Birth</p>
                                    <p style={styles.value}>{transfer?.buyerInfo?.dob || buyerFormattedDob}</p>
                                    <p style={styles.label}>Gender</p>
                                    <p style={styles.value}>{buyerIsMale ? 'Male' : 'Female'}</p>
                                    <p style={styles.label}>Address</p>
                                    <p style={styles.value}>{parseAddress({ ...transfer?.buyerInfo, state: Object.values(STATES).find(s => s.code === transfer?.buyerInfo?.state)?.label, districtName: buyerDistrictName })}</p>
                                    {/* <p style={styles.label}>Current Address</p>
                                    <p style={styles.value}>{parseCorrespondenceAddress({ ...transfer?.buyerInfo, correspondentState: Object.values(STATES).find(s => s.code === transfer?.buyerInfo?.correspondentState)?.label, districtName: buyerCorrespondenceDistrictName })}</p> */}
                                    <p style={styles.label}>Email</p>
                                    <p style={styles.value}>{transfer?.buyerInfo?.email}</p>
                                    <p style={styles.label}>Mobile Number</p>
                                    <p style={styles.value}>{transfer?.buyerInfo?.phoneNo}</p>
                                </>
                            )
                        }
                    </Card>
                </>
            </div>
            <Card
                style={{
                    backgroundColor: '#FFFFBB', // Set the background color
                    boxShadow: '0px 3px 6px #00000029',
                    borderRadius: 8,
                    marginTop: 16,
                    textAlign: 'inherit',
                }}
            >
                <Space>
                    <WarningIcon style={{ color: '#2B61C4' }} width={24} />
                    <span style={{ font: 'normal normal normal 16px Open Sans', marginLeft: 8 }}>
                        <b>Road Tax will be cancelled.</b> Please be reminded to apply for a new insurance policy and road tax as soon as possible.
                    </span>
                </Space>
            </Card>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: 20
                }}
            >
                <Button
                    className='back-button buttons'
                    shape='round'
                    onClick={() => {

                        if (history?.location?.state?.fromAdminPage == true) {

                            history.push('/adminSearchVehicleInfo')

                        } else if (locationState?.oldIsReport) {

                            if (locationState?.fromPendingPage === true) {

                                history.push('/reporting/transferreport/Out')

                            } else {

                                history.push('/reporting/transferreport')

                            }

                        } else {

                            history.push('/')

                        }
                    }}
                >
                    {locationState?.oldIsReport || history?.location?.state?.fromAdminPage == true ? "Back" : "Home"}
                </Button>
                <PrintButton />
            </div>
        </div>
    )
}

const styles = {
    label: {
        font: 'italic normal normal 16px Open Sans',
        color: '#333333',
        marginBottom: 8
    },
    value: {
        font: 'normal normal 600 16px Open Sans',
        color: '#333333'
    }
}

export default PrintSlip