import React, { useContext, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Card, Col, Input, message, Row, Table, Typography, Tooltip, Form,
    Space,
    Image, 
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { getInVehicleTypes,
    getVehicleDetails,
    verifyApproverOTP,
    verifyApproverSmsOtp,
    m2mBuyer,
    getAdminUsersForOut,
    inUpdateVehicleDetails,
    ucdRegistration,
} from '../../services/api'
import { SessionContext } from '../../App'
import { PropsFromChildren } from '.'
import { VehicleResponse, M2MBuyerResponse, CompanyResponse, Buyer, Vehicle, SaveBuyerRequest, M2MProceedResponse } from './types'
// import ApproverAuthModal from '../CreateInTransferPage/jpjSubmission_modal';
import ApproverAuthModal from './jpjSubmission_modal';
import { GetVehicleTypesResponse,
    UcdRegistrationRequest,
    UcdRegistrationResponse,
} from '../../services/api/types'
import {
    APP_CONST,
    SEARCH_QUERY_TOOLTIP,
    HQ_BRANCH,
} from '../../constants'
import { getDecryptedVerifyOtpResponse } from '../../util/index'
import { getAuthorizedNric,
} from '../../services/local'
import {useShortcut} from '../../components/Utils/'
import InventoryListingPage from '../../components/Inventory/InventoryV1'
import failedIcon from "../../assets/icon-failed.svg";

type VehicleInformationProps = {
    isApprover: boolean
    company: CompanyResponse
    vehicle: VehicleResponse
    transferId: number
    pendingType: string,
    isBulk: boolean,
    isReport: boolean,
    isVehicleEdit: boolean,
    isFromAdminPage: boolean,
    isJpjSubmitted: boolean,
    tabType: string,
    next: (data?: PropsFromChildren) => void
    prev: () => void
}

type VehicleData = {
    key: number,
    vehicleNo: string,
    // vehicleType: number,
    // make: string,
    // model: string,
    // manufacturerYear: string,
    chassisNo: string,
    engineNo: string,
}

export default function VehicleInformation(props: VehicleInformationProps) {
    const { branch, company, user } = useContext(SessionContext)
    const { Text } = Typography
    const { Column } = Table;
    const [isVehicleSearched, setIsVehicleSearched] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const inputRef = useRef<any>(null)
    const [vehicle, setVehicle] = useState<VehicleResponse>()
    const [vehicleData, setVehicleData] = useState<VehicleData[]>([])
    const [vehicleTypes, setVehicleTypes] = useState<GetVehicleTypesResponse>([])
    const [m2MBuyerResponse, setM2mBuyerResponse] = useState<M2MBuyerResponse>()
    const [transferId, setTransferId] = useState(0)
    const [vehicleForSave, setVehicleForSave] = useState<VehicleResponse>()
    const [isIncorrectOtp, setIsIncorrectOtp] = useState(false)
	const [searchTerm, setSearchTerm] = useState("");
    const history = useHistory()
	const [isFromPending, setIsFromPending] = useState(false);
	const [vehicleLoading, setVehicleLoading] = useState(false);
    const [vehicleInfoForm] = useForm()
    const [disableVehicleInfoField, setDisableVehicleInfoField] = useState(false)
    const [isApprovalRejected, setIsApprovalRejected] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    // const [registerationNum, setRegistrationNum] = useState("")
	// const [chassisNo, setChassisNo] = useState("");
	// const [engineNo, setEngineNo] = useState("");
    // const [vehicleDetails, setVehicleDetails] = useState({
    //     registrationNum: "",
    //     chassisNo: "",
    //     engineNo: ""
    //   });

    useEffect(() => {
        getInVehicleTypes().then(res => {
            setVehicleTypes(res)
        })
    }, [])

    useEffect(() => {
        if(props.vehicle && props.vehicle.inventory) { //cater pending list m2m
            setIsFromPending(true)
            const inventory : VehicleData = {
                key: 1,
                vehicleNo: props.vehicle.inventory.vehicleNo,
                // vehicleType: props.vehicle.inventory.vehicleType,
                // make: props.vehicle.inventory.make,
                // model: props.vehicle.inventory.model,
                // manufacturerYear: props.vehicle.inventory.manufacturerYear,
                chassisNo: props.vehicle.inventory.chassisNo,
                engineNo: props.vehicle.inventory.engineNo,
            }
            setVehicleData([{ ...inventory, key: 1 }])
            setIsVehicleSearched(true)
            setTransferId(props.transferId)
            const buyerResponse: M2MBuyerResponse = {
                saveBuyerMSG: "",
                saveBuyerStatus: "",
                transferId: transferId,
            }
            setM2mBuyerResponse(buyerResponse)
            // setChassisNo(props.vehicle.inventory.chassisNo)
            // setEngineNo(props.vehicle.inventory.engineNo)
            vehicleInfoForm.setFieldsValue({
                ...vehicleInfoForm,
                chassisNo: props.vehicle.inventory.chassisNo,
                engineNo: props.vehicle.inventory.engineNo
              });

            if (props.isJpjSubmitted && props.transferId && props.vehicle) {
                props.next({ vehicleProps: props.vehicle, transferId: props.transferId})
            }
        }
    }, [props.vehicle])

    const saveM2mBuyer = async (vehicle: VehicleResponse) => {
        setIsLoading(true)
        const { companyDetails } = props.company
        const { companyAddress: addr } = companyDetails
        const buyer: Buyer = {
            name: companyDetails.coName,
            identificationNo: companyDetails.coRegNo,
            phoneNo: companyDetails.contactNo,
            dateOfBirth: companyDetails.createdDate,
            gender: '',
            email: companyDetails.email,
            address1: addr.line1,
            address2: addr.line2,
            address3: addr.line3,
            state: addr.stateCode,
            postcode: addr.postcode,
            district: addr.district,
            correspondentAddress1: '',
            correspondentAddress2: '',
            correspondentAddress3: '',
            correspondentDistrict: '',
            correspondentEmail: '',
            correspondentMobileNo: '',
            correspondentPostcode: '',
            correspondentState: '',
        }

        const { inventory: vehicleDetails } = vehicle
        const vehicleInfo: Vehicle = {
            // carMake: vehicleDetails.make,
            // carModel: vehicleDetails.model,
            chassisNo: vehicleDetails.chassisNo,
            engineNo: vehicleDetails.engineNo,
            isWhiteListVehicle: false, // hardcoded defaults
            lkmoption: 1, // hardcoded defaults
            // manufactureYear: vehicleDetails.manufacturerYear,
            ownerIdCat: '', // hardcoded
            vehicleRegistrationNo: vehicleDetails.vehicleNo,
            // vehicleType: vehicleDetails.vehicleType.toString(),
        }

        const saveBuyerPayload: SaveBuyerRequest = {
            currentLoginUserName: user.username,
            dealerCompanyROC: company.coRegNo,
            branchId: branch.id ?? 0,
            buyerInfo: buyer,
            uId: "",
            vehicleInfo: vehicleInfo,
        }

        // Commented to allow save buyer, BE inquiry to ucdRegistration
        /* if (companyDetails.ucdCode === null) {
            const ucdRegistrationRequest: UcdRegistrationRequest = {
                idNo: companyDetails.coRegNo,
                ucdDescription: companyDetails.coName,
                ucdName: companyDetails.coName,
                address1: companyDetails.companyAddress.line1,
                address2: companyDetails.companyAddress.line2,
                address3: companyDetails.companyAddress.line3,
                postcode: companyDetails.companyAddress.postcode,
                district: companyDetails.companyAddress.district,
                state: companyDetails.companyAddress.stateCode,
                email: companyDetails.email,
            }
            const ucdRegistrationResponse = await ucdRegistration(ucdRegistrationRequest)
            if (ucdRegistrationResponse.responseCode === "00") {
                // message.success("Buyer UCD Registration Success")
            }
            else {
                message.error("Buyer UCD Registration Failed")
                return
            }
        } */

        try {
            const m2mPBuyerRes = await m2mBuyer(saveBuyerPayload)
            if (m2mPBuyerRes.saveBuyerStatus === '00') {
                setVehicleForSave(vehicle)
                setM2mBuyerResponse(m2mPBuyerRes)
                setTransferId(m2mPBuyerRes.transferId)
                props.next({ vehicleProps: vehicle, transferId: m2mPBuyerRes.transferId})
                return m2mPBuyerRes.transferId
            } else {
                message.error(m2mPBuyerRes.saveBuyerMSG)
                setIsModalVisible(false)
                setIsLoading(false)
            }
        }
        catch (error) {
            message.error(error)
            setIsModalVisible(false)
        }
    };

    /* useEffect(() => {

        if( ((hasVehicleChanged(vehicle, vehicleForSave) && m2MBuyerResponse) || // Check if vehicle data has changed and M2M buyer response is available
            (isVehicleSearched && !m2MBuyerResponse) // SaveM2Mbuyer if vehicle is searched, modal is visible, and no M2M response yet
            ) && isModalVisible && vehicle
        ) {
            saveM2mBuyer(vehicle);
        }
    }, [isVehicleSearched, m2MBuyerResponse, isModalVisible]); */

    //For testing purpose , can remove if deploy to prod
    /* useEffect(() => {
        if(isModalVisible) {
            proceedSaveM2m()
        }
    },[isModalVisible]) */

    function proceedSaveM2m() {
        if( ((hasVehicleChanged(vehicle, vehicleForSave) && m2MBuyerResponse) || // Check if vehicle data has changed and M2M buyer response is available
            (isVehicleSearched && !m2MBuyerResponse) // SaveM2Mbuyer if vehicle is searched, modal is visible, and no M2M response yet
            ) && vehicle
        ) {
            saveM2mBuyer(vehicle);
        }
    }

    function hasVehicleChanged(vehicle, vehicleForSave) {
      // Early exit if vehicleForSave is null or undefined
      if (vehicleForSave === null || vehicleForSave === undefined) {
        return false;
      }

      const keys1 = Object.keys(vehicle);
      const keys2 = Object.keys(vehicleForSave);

      // Check for differences in key length
      if (keys1.length !== keys2.length) {
        return true;
      }

      // Loop through keys and compare values
      for (const key of keys1) {
        const val1 = vehicle[key];
        const val2 = vehicleForSave[key];

        // Check for nested objects
        const areObjects = isObject(val1) && isObject(val2);

        // Compare values based on object type
        if (areObjects) {
          // Recursively compare nested objects
          if (hasVehicleChanged(val1, val2)) {
            return true;
          }
        } else if (val1 !== val2) {
          // Simple value comparison for non-objects
          return true;
        }
      }

      // No changes found
      return false;
    }

    function isObject(obj) {
      return obj !== null && typeof obj === 'object';
    }

    const searchVehicle = async () => {
        // if (!vehicleDetails.registrationNum) {
        //     message.error("Please key in vehicle registration number")
        //     inputRef.current!.focus()
        //     return
        // }
        // if (!vehicleDetails.chassisNo) {
        //     message.error("Please key in vehicle chassis number")
        //     inputRef.current!.focus()
        //     return
        // }
        // if (!vehicleDetails.engineNo) {
        //     message.error("Please key in vehicle engine number")
        //     inputRef.current!.focus()
        //     return
        // }
        setSearchTerm(vehicleInfoForm.getFieldValue('registrationNum'))
        /* const vehicleRes = await getVehicleDetails(branch.id ?? 0, company.id, vehicleDetails.registrationNum)
        if (vehicleRes.inventory === null) {
            message.error("Vehicle not found")
            return
        } else {
            // Hardcoded the key to remove warning on ant-table
            setVehicleData([{ ...vehicleRes.inventory, key: 1 }])
            setIsVehicleSearched(true)
        } */

        vehicleInfoForm.setFieldsValue({
            ...vehicleInfoForm,
            registrationNum: vehicleInfoForm.getFieldValue('registrationNum').toUpperCase().replace(/\s/g, ""),
            chassisNo: vehicleInfoForm.getFieldValue('chassisNo').toUpperCase().replace(/\s/g, ""),
            engineNo: vehicleInfoForm.getFieldValue('engineNo').toUpperCase().replace(/\s/g, ""),
        });

        const inventory : VehicleData = {
            key: 1,
            vehicleNo: vehicleInfoForm.getFieldValue('registrationNum'),
            chassisNo: vehicleInfoForm.getFieldValue('chassisNo'),
            engineNo: vehicleInfoForm.getFieldValue('engineNo'),
        }
        setVehicleData([{ ...inventory, key: 1 }])
        setIsVehicleSearched(true)
        const vehicleRes: VehicleResponse= {
            companyId: "",
            inventory: {
                id: "",
                vehicleNo: vehicleInfoForm.getFieldValue('registrationNum'),
                chassisNo: vehicleInfoForm.getFieldValue('chassisNo'),
                engineNo: vehicleInfoForm.getFieldValue('engineNo'),
                manufacturerYear: "",
                thumbInUser: "",
                transactionDate: "",
                transactionStatus: "",
                transactionType: "0",
                isWhiteListVehicle: false, //30.3.2022 daniel.kwok hardcoded defaults
                lkmoption: 1, //30.3.2022 daniel.kwok hardcoded defaults
                branchId: "",
            },
            message: "00",
        }
        setVehicle(vehicleRes)
    }

    /* function onApprovalSubmit() {
        let isAdminUser = false
        getAdminUsersForOut(company.id, HQ_BRANCH.id)
            .then(res => {
                if (res.status !== true) throw res.message
                res.users.map(user => {
                    if (user.nric_no === getAuthorizedNric()) {
                        isAdminUser = true
                        // message.success("APPROVED")
                    }
                })
            })
            .catch(err => err && message.error(err.toString()))
            .finally(async ()=> {
                if (!isAdminUser) {
                    proceedSaveM2m()
                    setIsModalVisible(true)
                }
                else if (vehicle) {
                    let transferId = await saveM2mBuyer(vehicle)
                    props.next({ vehicleProps: vehicle, transferId: transferId})
                }
                else if (isFromPending && transferId) {
                    props.next({ vehicleProps: vehicle, transferId: transferId})
                }
            })
    }
 */
    const SearchVehicleForm = () => {
        return (
            <>
                <Form form={vehicleInfoForm}>
                    <Card className='section-card section-card-left'>
                        <h1 className='section-header'>Vehicle Information</h1>
                        <Row className='input-wrapper'>
                            <Col span={7} >
                                <Text className='detail-label'>Vehicle registration number</Text>
                                <Tooltip title={SEARCH_QUERY_TOOLTIP} placement="bottom">
                                    <Form.Item
                                        name='registrationNum'
                                        rules={[
                                            {
                                                required: true,
                                                pattern: new RegExp('^[a-zA-Z0-9]{2,20}$'),
                                                // pattern: new RegExp('^[a-zA-Z0-9\\-]{2,20}$'),
                                                message: (
                                                    <ol>
                                                        <li>{'Must be between 2 - 20 characters'}</li>
                                                        <li>{'Allow for (a-z), (A-Z), (0-9)'}</li>
                                                    </ol>
                                                ),
                                            },
                                        ]}
                                    >
                                    <Input
                                        className='input-field'
                                        placeholder='Registration number'
                                        value={searchTerm}
                                        onChange={(e) => {
                                            setSearchTerm(e.target.value);
                                            if (!e.target.value || e.target.value.toUpperCase().replace(/\s/g, "") === "") return
                                            // getVehicleDetails(branch.id ?? 0, company.id, e.target.value.toUpperCase().replace(/\s/g, ""))
                                            getVehicleDetails("", company.id, e.target.value.toUpperCase().replace(/\s/g, ""))
                                            .then(res => {
                                                if (res.message == 'Success') {
                                                    setDisableVehicleInfoField(true)
                                                    vehicleInfoForm.setFieldsValue({
                                                        ...vehicleInfoForm,
                                                        registrationNum: e.target.value.toUpperCase().replace(/\s/g, ""),
                                                        chassisNo: res.inventory.chassisNo,
                                                        engineNo: res.inventory.engineNo,
                                                        });
                                                } else {
                                                    setDisableVehicleInfoField(false)
                                                    vehicleInfoForm.setFieldsValue({
                                                        ...vehicleInfoForm,
                                                        registrationNum: e.target.value.toUpperCase().replace(/\s/g, ""),
                                                        chassisNo: '',
                                                        engineNo: '',
                                                    });
                                                }
                                            });
                                        }}
                                        ref={inputRef}
                                    /></Form.Item>
                                </Tooltip>
                            </Col>
                            <Col span={1} />
                            <Col span={7}>
                                <Text className='detail-label'>Chasis number</Text>
                                <Form.Item 
                                    name="chassisNo"
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: `Chassis Number is required`
                                        },
                                    ]}
                                >
                                <Input
                                        className='input-field'
                                        placeholder='Chasis number'
                                        value={vehicleInfoForm.getFieldValue('chassisNo')}
                                        disabled={disableVehicleInfoField}
                                        onChange={(e) => {
                                            vehicleInfoForm.setFieldsValue({
                                                ...vehicleInfoForm,
                                                chassisNo: e.target.value
                                            });
                                        }}
                                        ref={inputRef}
                                    /></Form.Item>
                            </Col>
                            <Col span={1} />
                            <Col span={7}>
                                    <Text className='detail-label'>Engine number</Text>
                                    <Form.Item 
                                        name="engineNo"
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: `Engine Number is required`
                                            },
                                        ]}
                                    >
                                        <Input
                                            className='input-field'
                                            placeholder='Engine number'
                                            value={vehicleInfoForm.getFieldValue('engineNo')}
                                            disabled={disableVehicleInfoField}
                                            onChange={(e) => {
                                                vehicleInfoForm.setFieldsValue({
                                                    ...vehicleInfoForm,
                                                    engineNo: e.target.value
                                                });
                                            }}
                                            ref={inputRef}
                                        />
                                    </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Form>
                <Card>
                        <InventoryListingPage
                                isReport={false}
                                isM2mTransaction={true}
                                vehicleProps={vehicleInfoForm}
                                setVehicleSearchTerm={setSearchTerm}
                                setDisableVehicle={setDisableVehicleInfoField}
                        />
                </Card>
                <div className='section-bottom-button'>
                <Button className='rounded-button-primary' onClick={props.prev}>BACK</Button>
                    <Button type='primary' className='rectangle-button to-the-right' 
                    onClick={() => {
                        vehicleInfoForm
                            .validateFields()
                            .then(() => {
                                searchVehicle();
                            });
                    }}
                    // onClick={searchVehicle}
                    >Check Vehicles</Button>
                </div>
            </>
        )
    }

    const onUpdateVehicle = () => {
        setVehicleLoading(true)

        inUpdateVehicleDetails(
            // values.carMake.trim().toUpperCase(),
            // values.carModel.trim().toUpperCase(),
            vehicleInfoForm.getFieldValue('chassisNo').trim().toUpperCase(),
            company.coRegNo,
            vehicleInfoForm.getFieldValue('engineNo').trim().toUpperCase(),
            // values.manufactureYear?.format("YYYY"),
            props.transferId,
            // values.vehicleType
        ).then(res => {

            if (res.updateStatus != '00') {

                if (res.updateMSG) throw res.updateMSG

                throw res.message
            }

            message.success(res.updateMSG)

            if (props.isBulk) {
                // history.push("/reporting/transferreport/" + props.pendingType + "/bulk")
                // history.push("/reporting/transferreport/" + props.pendingType)
                history.push(`/reporting/transferreport/Out`, {tabType: props.tabType})
            }
            else if (props.isFromAdminPage) {
                history.push("/adminSearchVehicleInfo")
            }
            else if (props.isReport) {
                history.push("/reporting/transferreport/")
            }
            else {
                history.push("/reporting/transferreport/" + props.pendingType)
            }
        }).catch(err => { err && message.error(err) }).finally(() => 
        setVehicleLoading(false))
    }

    const VehicleInfoTable = () => {
        return (
            <>
                {
                    isApprovalRejected ? (
                    <>
                        <br/>
                        <Card
                            style={{
                                height: '50%',
                                textAlign: 'left',
                                boxShadow: '0px 3px 6px #00000029',
                                borderRadius: '4px',
                                marginBottom: 16
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                }}
                            >
                                <Image src={failedIcon} alt="failed icon" preview={false} style={{ transform: 'scale(0.6)' }} />
                                <Space direction='vertical' size={0}>
                                    <h2 style={{ color: '#E53B34', font: 'normal normal bold 23px/31px Open Sans' }} >Approval Unsuccessful</h2>
                                </Space>
                            </div>
                        </Card>
                    </>): <></>
                }
                {vehicleData.length > 0
                    ? (
                        <Table className='vehicle-table' dataSource={vehicleData} pagination={false} loading={vehicleLoading}>
                            {/* <Column title="No" dataIndex="key" key="key" /> */}
                            <Column title="Vehicle Number" dataIndex="vehicleNo" key="vehicleNo" />
                            {/* <Column title="Vehicle Type" dataIndex="vehicleType"
                                key="vehicleType"
                                render={(vType: number) => {
                                    const type = vehicleTypes.find(x => x.id === vType)
                                    if (type) {
                                        return type.name
                                    } else {
                                        return '-'
                                    }
                                }}
                            /> */}
                            {/* <Column title="Make" dataIndex="make" key="make" />
                            <Column title="Model" dataIndex="model" key="model" />
                            <Column title="Manufacturer Year" dataIndex="manufacturerYear" key="manufacturerYear" /> */}

                            {props.isVehicleEdit ? <>
                                <Column title="Chassis Number" dataIndex="chassisNo" key="chassisNo" render={(text, record) => (
                                    <Input defaultValue={text} onChange={e =>
                                        // setChassisNo(e.target.value)
                                        vehicleInfoForm.setFieldsValue({
                                            ...vehicleInfoForm,
                                            chassisNo: e.target.value,
                                          })} />
                                )}/>
                                <Column title="Engine Number" dataIndex="engineNo" key="engineNo" render={(text, record) => (
                                    <Input defaultValue={text} onChange={e =>
                                        // setEngineNo(e.target.value)
                                        vehicleInfoForm.setFieldsValue({
                                            ...vehicleInfoForm,
                                            engineNo: e.target.value,
                                          })
                                    } />
                                )}/>
                            </>:
                            <>
                            <Column title="Chassis Number" dataIndex="chassisNo" key="chassisNo" />
                            <Column title="Engine Number" dataIndex="engineNo" key="engineNo" /></>}
                            {/* <Column 
                                title="" 
                                render={() => (
                                    <Button className='rounded-button-danger'>Remove</Button>
                                )}
                            /> */}
                        </Table>
                    )
                    : (
                        <div className="section-error">
                            {`Vehicle not found for this plate number ${vehicleInfoForm.getFieldValue('registrationNum')}.`}
                        </div>
                    )
                }
                <div className='section-bottom-button'>
                    {
                        isFromPending ? (
                            <>
                            <Button
                                className="rounded-button-primary"
                                onClick={() => {
                                    if (props.isBulk) {
                                        // history.push("/reporting/transferreport/" + props.pendingType + "/bulk")
                                        // history.push("/reporting/transferreport/" + props.pendingType)
                                        history.push(`/reporting/transferreport/Out`, {tabType: props.tabType})
                                    }
                                    else if (props.isFromAdminPage) {
                                        history.push("/adminSearchVehicleInfo")
                                    }
                                    else if (props.isReport) {
                                        history.push("/reporting/transferreport/")
                                    }
                                    else {
                                        history.push("/reporting/transferreport/" + props.pendingType)
                                    }
                                }}
                            >
                                BACK
                            </Button>
                            </>
                        ) : (
                            <>
                            <Button
                                className="rounded-button-primary"
                                onClick={() => setIsVehicleSearched(false)}
                            >
                                BACK
                            </Button>
                            </>
                        )
                    }
                    {/* <Button className='rounded-button-primary' onClick={() => setIsVehicleSearched(false)}>BACK</Button> */}
                    {props.isVehicleEdit ? <Button type='primary' className='rectangle-button to-the-right' onClick={() => onUpdateVehicle()}>Update</Button> :
                    // !props.isReport && <Button type='primary' className='rectangle-button to-the-right' onClick={() => onApprovalSubmit()}>Seller Approval & JPJ Submission</Button>}
                    !props.isReport && 
                    <Button type='primary' 
                        className='rectangle-button to-the-right' 
                        loading={isLoading}
                        onClick={() => proceedSaveM2m()}>JPJ Submission</Button>}
                </div>
            </>
        )
    }

    return (
        <>
            {useShortcut(setIsModalVisible)}
            {!isVehicleSearched
                ? SearchVehicleForm()
                : VehicleInfoTable()
            }
            <ApproverAuthModal
                isIncorrectOtp={isIncorrectOtp}
                isModalVisible={isModalVisible && transferId != 0}
                isApprover={props.isApprover}
                isM2M={true}
                transferId={transferId}
                onCancel={() => setIsModalVisible(false)}
                onOk={(approverIc, authCode, isPush, appApprovalResponse) => {

                    if (isPush) {
                        setIsModalVisible(false)
                        if (appApprovalResponse.isRejected) {
                            setIsApprovalRejected(true)
                        }
                        else {
                            setIsApprovalRejected(false)
                            props.next({ vehicleProps: vehicle, transferId: m2MBuyerResponse?.transferId})
                        }
                    }
                    else if (APP_CONST.USE_OTP === true) {

                        verifyApproverSmsOtp(approverIc, authCode)
                            .then(res => {
                                if(!res.encryptedString) {
                                    let errorMsg = res.message ? res.message : "ERROR"
                                    throw errorMsg
                                }
                                const decryptedResponse = getDecryptedVerifyOtpResponse(res.encryptedString)
                                if (decryptedResponse.status == 'false') {
                                    setIsIncorrectOtp(true)
                                    throw decryptedResponse.message
                                }
                                setIsIncorrectOtp(false)
                                setIsModalVisible(false)
                                // Next step: JPJ Verification
                                props.next({ vehicleProps: vehicle, transferId: m2MBuyerResponse?.transferId})
                            })
                            .catch((err: any) => {
                                setIsIncorrectOtp(true)
                                err && message.error(err)
                            })
                            .finally(() => {
                                setIsIncorrectOtp(false)
                            })
                    } else {

                        verifyApproverOTP(approverIc, authCode)
                            .then(res => {
                                if (res.status !== true) {
                                    setIsIncorrectOtp(true)
                                    throw res.message
                                }
                                setIsIncorrectOtp(false)
                                setIsModalVisible(false)
                                // Next step: JPJ Verification
                                props.next({ vehicleProps: vehicle, transferId: m2MBuyerResponse?.transferId })
                            })
                            .catch((err: any) => {
                                setIsIncorrectOtp(true)
                                err && message.error(err)
                            })
                            .finally(() => {
                                setIsIncorrectOtp(false)
                            })

                    }

                }}
            />
        </>
    )
}
