import { Button, Card, Col, Divider, Form, Image, Input, message, Modal, PageHeader, Progress, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { SessionContext } from "../../App";
import {
	changeOwnPassword,
	getCompanyOfLoggedInUser,
	getRolesByUsername,
	logout,
	updateBranchUser,
	updateEmail,
	getUsersByCompanyId,
} from "../../services/api";
import { removeUserRole } from "../../services/local";
import { breakdownIc, parseAddress } from "../../util";
import arrow_back from "../../assets/arrow_back.svg";
import "./index.css";
import CryptoJS from 'crypto-js';
import AES from 'crypto-js/aes';
import { iv } from '../../constants'

export default function EditProfilePage() {
	const { user, branch, company } = useContext(SessionContext);

	const { formattedDob, isMale } = breakdownIc(user.nric);

	const history = useHistory();

	const [branchUser, setBranchUser] = useState(user || {});

	const [showChangeEmailModal, setShowChangeEmailModal] = useState(false);
	const [changeEmailLoading, setChangeEmailLoading] = useState(false);
	const [changeEmailForm] = useForm();

	const passwordStrengthMap = {
		WEAK: {
			percent: 30,
			color: "red",
			message: "Weak",
			messageBody: "It will be easy for a computer to crack your password.",
		},
		NORMAL: {
			percent: 50,
			color: "orange",
			message: "Normal",
			messageBody: "It will take some time for a computer to crack your password.",
		},
		STRONG: {
			percent: 91,
			color: "green",
			message: "Strong",
			messageBody: "It will take a very long time for a computer to crack your password.",
		},
	};

	const [showChangePassModal, setShowChangePassModal] = useState(false);
	const [changePassLoading, setChangePassLoading] = useState(false);
	const [changePasswordForm] = useForm();
	const [showPasswordStrength, setShowPasswordStrength] = useState(false);
	const [passwordStrength, setPasswordStrength] = useState(passwordStrengthMap["WEAK"]);

	// QA feedback: don't allow change email in profile, since this function already present in User Management page
	// But this function works, therefore keep it, in case need it in the future.
	const onChangeEmail = async () => {
		setChangeEmailLoading(true);
		const { newEmail, password } = changeEmailForm.getFieldsValue();

		// to get the ID of the user, we get it from here
		// let currentUserWithID = await getCompanyOfLoggedInUser(company.id).then((res) => {
			let currentUserWithID = await getUsersByCompanyId(company.id).then((res) => {
			if (res.status !== "00") throw res.message;
			const currentUser = res.users.find((companyUser) => {
				return companyUser.email == user.username && companyUser.nricNo == user.nric;
			});

			return currentUser;
		});

		// not only do we need to change email, we also need to update user 
		// change email is for changing email for 1MID, updateUser is for changing email for etukar user
		updateEmail(user.username, password, 'false', newEmail)
			.then((res) => {
				getRolesByUsername(user.nric)
					.then((rolesResponse) => {
						const userRoles = rolesResponse.roles.map((role) => role.name);

						updateBranchUser(
							branch.id,
							company.id,
							user.mobileno,
							newEmail,
							user.nric,
							currentUserWithID.id,
							user.name,
							userRoles
						)
							.then((response) => {
								message.success("Email changed successfully.");
								setShowChangeEmailModal(false);
								changeEmailForm.resetFields();

								logout()
									.then(() => {
										removeUserRole();
										window.location.pathname = "/login";
									})
									.catch((error) => message.error(error));
							})
							.catch((err) => err && message.error(err));
					})
					.catch((err) => err && message.error(err));
			})
			.catch((err) => err && message.error(err))
			.finally(() => setChangeEmailLoading(false));
	};

	const handleChangePassword = () => {
		setChangePassLoading(true);

		const { newPassword, currentPassword } = changePasswordForm.getFieldsValue();

		let currentPasswordCiphertext = CryptoJS.AES.encrypt(currentPassword, iv).toString();
		let newPasswordCiphertext = CryptoJS.AES.encrypt(newPassword, iv).toString();

		changeOwnPassword(currentPasswordCiphertext, 'false', newPasswordCiphertext, user.nric)
			.then((res) => {
				if (res.status !== true) {
					message.error(res.message);
					return;
				}
				message.success(res.message);
			})
			.catch((err) => err && message.error(err))
			.finally(() => {
				setChangePassLoading(false);
				setShowChangePassModal(false);
			});
	};

	const checkPasswordStrength = () => {
		const { newPassword } = changePasswordForm.getFieldsValue();
		// Minimum eight, maximum 12 characters, at least one uppercase letter, one lowercase letter and one number:
		const passwordNormalRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,12}$/;
		// Minimum eight, maximum 12 characters, at least one uppercase letter, one lowercase letter, one number and one special character:
		const passwordStrongRegex =
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+|~`])[A-Za-z\d!@#$%^&*()_+|~`]{8,12}$/;

		// re-render PasswordStrengthComponent everytime strength is changed
		if (newPassword) {
			setShowPasswordStrength(true);

			// if password matches normal strength regex, change strength state to normal
			passwordNormalRegex.test(newPassword) && setPasswordStrength(passwordStrengthMap["NORMAL"]);
			// if password matches strong strength regex, change stregth state to strong
			passwordStrongRegex.test(newPassword) && setPasswordStrength(passwordStrengthMap["STRONG"]);
			// otherwise, change strength to weak
			!passwordNormalRegex.test(newPassword) &&
				!passwordStrongRegex.test(newPassword) &&
				setPasswordStrength(passwordStrengthMap["WEAK"]);
		} else {
			setShowPasswordStrength(false);
		}
	};

	const PasswordStrengthComponent = () => {
		return showPasswordStrength ? (
			<>
				<Progress percent={passwordStrength.percent} strokeColor={passwordStrength.color} showInfo={false} />
				<p>
					<span style={{ fontWeight: "bold", color: passwordStrength.color }}>
						{passwordStrength.message}.{" "}
					</span>
					{passwordStrength.messageBody}
				</p>
			</>
		) : (
			<></>
		);
	};

	return (
		<>
			<div className="page-content">
				<PageHeader className="site-page-header" title="Profile" style={{ paddingLeft: 0, paddingTop: 60 }} />
				<Row gutter={[16, 16]} style={{ alignItems: "stretch", marginBottom: 35 }}>
					<Col span={12}>
						<Card className="form-background">
							<div
								style={{
									display: "flex",
									justifyContent: "flex-start",
									alignItems: "flex-start",
									flexDirection: "column",
								}}
							>
								<h2>Personal Details</h2>
								<div style={{ textAlign: "left" }}>
									<p style={styles.label}>Name</p>
									<p style={styles.value}>{user.name}</p>
									<p style={styles.label}>NRIC</p>
									<p style={styles.value}>{user.nric}</p>
									<p style={styles.label}>Date of Birth</p>
									<p style={styles.value}>{formattedDob}</p>
									<p style={styles.label}>Gender</p>
									<p style={styles.value}>{isMale ? 'MALE' : 'FEMALE'}</p>
									<p style={styles.label}>Address</p>
									<p style={styles.value}>{parseAddress(user.address)}</p>
								</div>
							</div>
						</Card>
					</Col>
					<Col span={12}>
						<Card className="form-background" style={{ textAlign: "left", height: "100%" }}>
							<h2>Account Detail</h2>
							<Row style={{ display: "flex", alignItems: "center" }}>
								<Col span={12}>
									<p style={styles.label}>Email</p>
									<p style={{ marginBottom: 0, fontWeight: "bold" }}>{branchUser.username}</p>
								</Col>
								{/* <Col span={12} style={{ textAlign: "right" }}>
									<Button type="link" onClick={() => setShowChangeEmailModal(true)}>
										CHANGE E-MAIL
									</Button>
								</Col> */}
							</Row>
							<Divider style={{ borderTop: "1px solid #00000050" }} />
							<Row style={{ display: "flex", alignItems: "center" }}>
								<Col span={12}>
									<p style={styles.label}>Password</p>
								</Col>
								<Col span={12} style={{ textAlign: "right" }}>
									<Button type="link" onClick={() => setShowChangePassModal(true)}>
										CHANGE PASSWORD
									</Button>
								</Col>
							</Row>
						</Card>
					</Col>
				</Row>
				<Button
					className="back-button buttons"
					shape="round"
					onClick={() => history.goBack()}
					icon={<Image src={arrow_back} preview={false} />}
				>
					BACK
				</Button>
			</div>
			<Modal
				visible={showChangeEmailModal}
				footer={null}
				onCancel={() => {
					setShowChangeEmailModal(false);
				}}
				destroyOnClose
				afterClose={() => changeEmailForm.resetFields()}
			>
				<h2 style={{ fontWeight: "bold", textAlign: "center", marginTop: 30 }}>Change E-mail</h2>
				<Form
					layout="vertical"
					style={{ padding: "0px 20px 0px 20px" }}
					width={200}
					requiredMark={"optional"}
					form={changeEmailForm}
					onFinish={onChangeEmail}
					initialValues={{
						currentEmail: user.username,
					}}
				>
					<Form.Item
						label="Current E-mail"
						style={{ color: "grey", fontStyle: "italic" }}
						name="currentEmail"
						rules={[
							{
								required: true,
								message: "Please input your current e-mail",
							},
						]}
					>
						<Input type={"email"} className="form-input-field" disabled />
					</Form.Item>
					<Form.Item
						label="New E-mail"
						style={{ color: "grey", fontStyle: "italic" }}
						name="newEmail"
						rules={[{ required: true, message: "Please input your new e-mail" }]}
					>
						<Input type={"email"} className="form-input-field" />
					</Form.Item>
					<Form.Item
						label="Password"
						style={{ color: "grey", fontStyle: "italic" }}
						name="password"
						rules={[{ required: true, message: "Please input your password" }]}
					>
						<Input.Password className="form-input-field" visibilityToggle />
					</Form.Item>
					<Form.Item>
						<Button
							className="add-new-user-button"
							type="primary"
							style={{ width: "100%" }}
							htmlType="submit"
							loading={changeEmailLoading}
						>
							Change E-mail
						</Button>
					</Form.Item>
				</Form>
			</Modal>
			<Modal
				visible={showChangePassModal}
				destroyOnClose
				afterClose={() => {
					changePasswordForm.resetFields();
					setPasswordStrength(passwordStrengthMap["WEAK"]);
					setShowPasswordStrength(false);
				}}
				footer={null}
				onCancel={() => setShowChangePassModal(false)}
			>
				<h2 style={{ fontWeight: "bold", textAlign: "center", marginTop: 30 }}>Change Password</h2>
				<Form
					form={changePasswordForm}
					onFinish={handleChangePassword}
					layout="vertical"
					style={{ padding: "0px 20px 0px 20px" }}
					width={200}
					requiredMark={"optional"}
				>
					<Form.Item
						label="Generate New Password"
						name="newPassword"
						style={{ color: "grey", fontStyle: "italic", marginBottom: 5 }}
						rules={[
							{
								required: true,
								message: "Please input your new password",
							},
							{
								max: 12,
								min: 8,
								message: "Password must have 8 - 12 characters",
							},
							{
								pattern: /^(?=.*[A-Z])[A-Za-z\d!@#$%^&*()_+|~`]{1,}$/,
								message: "New password must have at least one capital letter",
							},
							{
								pattern: /^(?=.*[a-z])[A-Za-z\d!@#$%^&*()_+|~`]{1,}$/,
								message: "New password must have at least one alphanumerical character",
							},
							{
								pattern: /^(?=.*\d)[A-Za-z\d!@#$%^&*()_+|~`]{1,}$/,
								message: "New password must have at least a number",
							},
						]}
					>
						<Input.Password
							type={"password"}
							className="form-input-field"
							visibilityToggle
							onChange={() => checkPasswordStrength()}
						/>
					</Form.Item>
					{PasswordStrengthComponent()}
					<Form.Item
						label="Current Password"
						name="currentPassword"
						style={{ color: "grey", fontStyle: "italic" }}
						rules={[
							{
								required: true,
								message: "Please input your current password",
							},
						]}
					>
						<Input.Password type={"password"} className="form-input-field" visibilityToggle />
					</Form.Item>
					<Form.Item>
						<Button
							className="add-new-user-button"
							type="primary"
							style={{ width: "100%" }}
							htmlType="submit"
							loading={changePassLoading}
						>
							Change password
						</Button>
					</Form.Item>
				</Form>
			</Modal>
			<style>
				{`
					.ant-modal-content { 
						border-radius: 8px;
					}
				`}
			</style>
		</>
	);
}

const styles = {
	card: {
		marginBottom: 20,
		textAlign: "left",
	},
	label: {
		font: "italic normal normal 16px Open Sans",
		color: "#333333",
		marginBottom: 5,
	},
	value: {
		color: "#333333",
		font: "normal normal 600 16px Open Sans",
	},
};
