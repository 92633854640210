export const LOCAL_STORAGE_KEYS = {
    USERNAME: 'etukar_username',
    BRANCH_ID: 'etukar_branch_id',
    COMPANY_ID: 'etukar_company_id',
    USER_ROLE: 'etukar_userRole',
    TOKEN: 'etukar_token',
    AUTHORIZED_NRIC: 'etukar_authorizedNRIC',
    CACHED_STATE: 'etukar_cachedState',
    CACHED_ADD_STAFF: 'etukar_cachedAddStaff',
    CACHED_BUYER_TYPE: 'etukar_cachedbuyerType',
    UNVERIFIED_ACCOUNT: 'unverified_account',
    IS_AUTH_USER_MANAGEMENT: 'is_authorize_access_user_management',
    LOGGEDIN_USERNAME: 'etukar_loggedin_username',
    REPORT_TYPE: 'etukar_report_type',
    SAGEM_FOR_KEY: 'sagem_for_key',
    UID: 'etukar_uid',
    ANNOUNCEMENT_SHOWN: 'announcement_shown',
    PERMISSIONS: 'etukar_permissions',
}

export const setLoggedInUsernaame = (username) => {
    return new Promise((res, rej) => {
        localStorage.setItem(LOCAL_STORAGE_KEYS.LOGGEDIN_USERNAME, username)
        res(username)
    })
}

export const removeLoggedInUsernaame = () => {
    return new Promise((res, rej) => {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.LOGGEDIN_USERNAME)
        res()
    })
}

export const getLoggedInUsernaame = () => {
    return new Promise((res, rej) => {
        const username = localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGEDIN_USERNAME)
        res(username)
    })
}
export const setBranchId = (branchId) => {
    return new Promise((res, rej) => {
        localStorage.setItem(LOCAL_STORAGE_KEYS.BRANCH_ID, branchId)
        res(branchId)
    })
}

export const removeBranchId = () => {
    return new Promise((res, rej) => {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.BRANCH_ID)
        res()
    })
}

export const getBranchId = () => {
    return new Promise((res, rej) => {
        const branchId = localStorage.getItem(LOCAL_STORAGE_KEYS.BRANCH_ID)
        // VERY IMPORTANT: do not use Number() to convert to integer, as null will be converted to integer value 0
        // resulting in HQ branch being unintentionally chosen
        res(parseInt(branchId))
    })
}

export const setUserRole = (role) => {
    return new Promise((res, rej) => {
        localStorage.setItem(LOCAL_STORAGE_KEYS.USER_ROLE, role)
        res(role)
    })
}

export const removeUserRole = () => {
    return new Promise((res, rej) => {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_ROLE)
        res()
    })
}

export const getUserRole = () => {
    return new Promise((res, rej) => {
        const role = localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ROLE)
        res(role)
    })
}


export const setToken = (token) => {
    return new Promise((res, rej) => {
        sessionStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, token)
        res(token)
    })
}

export const removeToken = () => {
    return new Promise((res, rej) => {
        sessionStorage.removeItem(LOCAL_STORAGE_KEYS.TOKEN)
        res()
    })
}

export const getToken = () => {
    return sessionStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN)
}

export const setAuthorizedNric = (nric) => {
    return new Promise((res, rej) => {
        localStorage.setItem(LOCAL_STORAGE_KEYS.AUTHORIZED_NRIC, nric)
        res(nric)
    })
}

export const removeAuthorizedNric = () => {
    return new Promise((res, rej) => {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.AUTHORIZED_NRIC)
        res()
    })
}

export const getAuthorizedNric = () => {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.AUTHORIZED_NRIC)
}


export const setCachedState = (state) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.CACHED_STATE, state)
    return state
}

export const removeCachedStates = () => {
    return new Promise((res, rej) => {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.CACHED_STATE)
        res()
    })
}

export const getCachedStates = () => {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.CACHED_STATE) || '{}'
}

export const setUnverifiedAccount = (unverifiedaccount) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.UNVERIFIED_ACCOUNT, unverifiedaccount)
    return unverifiedaccount
}

export const removeUnverifiedAccount = () => {
    return new Promise((res, rej) => {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.UNVERIFIED_ACCOUNT)
        res()
    })
}

export const getUnverifiedAccount = () => {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.UNVERIFIED_ACCOUNT) || '{}'
}

export const setIsAuthorizeToAccessUserManagement = (isAuthorize) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.IS_AUTH_USER_MANAGEMENT, isAuthorize)
    return isAuthorize
}

export const removeIsAuthorizeToAccessUserManagement = () => {
    return new Promise((res, rej) => {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.IS_AUTH_USER_MANAGEMENT)
        res()
    })
}

export const getIsAuthorizeToAccessUserManagement = () => {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.IS_AUTH_USER_MANAGEMENT) || '{}'
}

export const setCompanyId = (companyId) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.COMPANY_ID, companyId)
    return companyId
}

export const removeCompanyId = () => {
    return new Promise((res, rej) => {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.COMPANY_ID)
        res()
    })
}

export const getCompanyId = () => {
    const companyId = localStorage.getItem(LOCAL_STORAGE_KEYS.COMPANY_ID)
    return parseInt(companyId)
}

//this apply for update staff also
export const setCachedAddStaff = (addStaffData) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.CACHED_ADD_STAFF, addStaffData)
    return addStaffData
}

export const removeCachedAddStaff = () => {
    return new Promise((res, rej) => {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.CACHED_ADD_STAFF)
        res()
    })
}

export const getCachedAddStaff = () => {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.CACHED_ADD_STAFF) || '{}'
}

export const setCachedBuyerType = (buyerType) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.CACHED_BUYER_TYPE, buyerType)
    return buyerType
}

export const removeCachedBuyerType = () => {
    return new Promise((res, rej) => {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.CACHED_BUYER_TYPE)
        res()
    })
}

export const getCachedBuyerType = () => {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.CACHED_BUYER_TYPE) || ''
}

export const setCachedReportType = (reportType) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.REPORT_TYPE, reportType)
    return reportType
}

export const removeCachedReportType = () => {
    return new Promise((res, rej) => {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.REPORT_TYPE)
        res()
    })
}

export const getSagemUrlForKey = () => {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.SAGEM_FOR_KEY) || ''
}

export const setSagemUrlForKey = (sagemForKey) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.SAGEM_FOR_KEY, sagemForKey)
    return sagemForKey
}

export const removeSagemUrlForKey = () => {
    return new Promise((res, rej) => {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.SAGEM_FOR_KEY)
        res()
    })
}

export const getCachedReportType = () => {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.REPORT_TYPE) || ''
}

export const getAuthUid = () => {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.UID) || ''
}

export const setAuthUid = (uid) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.UID, uid)
    return uid
}

export const removeAuthUid = () => {
    return new Promise((res, rej) => {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.UID)
        res()
    })
}

export const getAnnouncementShown = () => {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.ANNOUNCEMENT_SHOWN) || ''
}

export const setAnnouncementShown = (announcementShown) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.ANNOUNCEMENT_SHOWN, announcementShown)
    return announcementShown
}

export const removeAnnouncementShown = () => {
    return new Promise((res, rej) => {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.ANNOUNCEMENT_SHOWN)
        res()
    })
}

export const setPermissions = (permissions) => {
    return new Promise((res, rej) => {
        localStorage.setItem(LOCAL_STORAGE_KEYS.PERMISSIONS, JSON.stringify(permissions));
        res(permissions)
    })
}

export const removePermissions = () => {
    return new Promise((res, rej) => {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.PERMISSIONS)
        res()
    })
}

export const getPermissions = () => {
    return new Promise((res, rej) => {
        const role = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.PERMISSIONS))
        res(role)
    })
}
